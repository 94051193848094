import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import MaterialTable from "material-table";
import Radio from "@material-ui/core/Radio";
import React from "react";
import Search from "@material-ui/icons/Search";
import { forwardRef } from "react";

import { strings } from "../localization";

const NO_RIGHT = "";
const READ_RIGHT = "r";
export default class CustomPermissionSelector extends React.PureComponent {
  constructor(props) {
    super(props);

    this.permission = null;
    this.selectRightsHandler = this.selectRightsHandler.bind(this);
    this.isChecked = this.isChecked.bind(this);

    this.state = {
      updatedCustomPermissions: this.props.customPermissions,
    };
  }

  // find the object in the custom permission arrays by id
  findObjectInArrayById(id, objArray) {
    for (const obj of objArray) {
      if (obj.resource === id) {
        return obj;
      }
    }
    return null;
  }

  // Manage state for RadioButtonGroup
  selectRightsHandler(e) {
    const dataRow = e.target.getAttribute("data-row");
    const resourceCat = e.target.getAttribute("data-resource-category");
    const value = e.target.value;

    const updatedCustomPermissions = this.state.updatedCustomPermissions;
    const previousPerm = this.findObjectInArrayById(
      dataRow,
      updatedCustomPermissions
    );
    const previousValue = previousPerm ? previousPerm.right : NO_RIGHT;

    const newObj = {
      resource: dataRow,
      right: value,
      resource_category: resourceCat,
    };

    if (!previousPerm) {
      this.setState(
        {
          updatedCustomPermissions: updatedCustomPermissions.concat(newObj),
        },
        (_) => this.props.callback(this.state.updatedCustomPermissions)
      );
    } else if (previousValue !== value) {
      this.setState(
        {
          updatedCustomPermissions: updatedCustomPermissions.map((obj) =>
            obj.resource === dataRow ? { ...obj, ...newObj } : obj
          ),
        },
        (_) => this.props.callback(this.state.updatedCustomPermissions)
      );
    }
  }

  // Define if a RadioButton is checked
  isChecked(rowId, value) {
    const rowPerm = this.findObjectInArrayById(
      rowId,
      this.state.updatedCustomPermissions
    );
    const rowRight = rowPerm ? rowPerm.right : NO_RIGHT;

    if (rowRight === value) {
      return true;
    }
    return false;
  }

  render() {
    return (
      <MaterialTable
        title={strings.user_management_custom_permissions}
        columns={[
          {
            field: "resourceCategory",
            title: "Category",
            lookup: {
              analysis: "analysis",
              annotation: "annotation",
            },
          },
          {
            field: "name",
            title: "Name",
          },
          {
            field: "no",
            title: "No custom right",
            filtering: false,
            sorting: false,
            render: (rowData) => (
              <Radio
                checked={this.isChecked(rowData.id, NO_RIGHT)}
                value={NO_RIGHT}
                name={`${rowData.name}-no-right`}
                inputProps={{
                  "aria-label": `${rowData.name}-no-right`,
                  "data-row": rowData.id,
                  "data-resource-category": rowData.resourceCategory,
                }}
                onClick={this.selectRightsHandler}
              />
            ),
          },
          {
            field: "r",
            title: "Read only",
            filtering: false,
            sorting: false,
            render: (rowData) => (
              <Radio
                checked={this.isChecked(rowData.id, READ_RIGHT)}
                value={READ_RIGHT}
                name={`${rowData.name}-read`}
                inputProps={{
                  "aria-label": `${rowData.name}-read`,
                  "data-row": rowData.id,
                  "data-resource-category": rowData.resourceCategory,
                }}
                onClick={this.selectRightsHandler}
              />
            ),
          },
        ]}
        data={this.props.availableRows}
        options={{
          filtering: true,
        }}
        icons={{
          Filter: forwardRef((props, ref) => (
            <FilterList {...props} ref={ref} />
          )),
          FirstPage: forwardRef((props, ref) => (
            <FirstPage {...props} ref={ref} />
          )),
          LastPage: forwardRef((props, ref) => (
            <LastPage {...props} ref={ref} />
          )),
          NextPage: forwardRef((props, ref) => (
            <ChevronRight {...props} ref={ref} />
          )),
          PreviousPage: forwardRef((props, ref) => (
            <ChevronLeft {...props} ref={ref} />
          )),
          ResetSearch: forwardRef((props, ref) => (
            <Clear {...props} ref={ref} />
          )),
          Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        }}
      ></MaterialTable>
    );
  }
}

import React from "react";
import { Fab, Icon, Grow } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { setSnackbar, clearSnackbar } from "./simpleSnackbarSlice";
import FileSaver from "file-saver";

import { strings } from "../localization";
import getApiEndpoint from "../endpoint_configuration";
import {
  INTERNET_ERROR_MSG,
  SERVER_FAILED_MSG,
  EXPORT_FROM_SERVER_MSG,
} from "../ErrorMessages";
import { DownloadFABProperties } from "./types";

const DownloadFAB: React.FC<DownloadFABProperties> = (props) => {
  const { resourceType, selection, refreshData } = props;
  const dispatch = useDispatch();
  const showErrorSnackbar = (message: string) =>
    dispatch(setSnackbar({ message, mode: "error" }));
  const showInfoSnackbar = (message: string) =>
    dispatch(setSnackbar({ message, mode: "info" }));
  const hideSnackbar = () => dispatch(clearSnackbar());
  function onClick() {
    showInfoSnackbar(EXPORT_FROM_SERVER_MSG);
    if (selection.length === 1) {
      getApiEndpoint(`export_${resourceType}`)
        .bindUrlParameter(`${resourceType}_hash`, selection[0])
        .getFetchPromise()
        .catch(() => showErrorSnackbar(INTERNET_ERROR_MSG))
        .then((response) => {
          refreshData();
          if (response.ok) {
            hideSnackbar();
            return response.blob();
          }
          showErrorSnackbar(SERVER_FAILED_MSG);
        })
        .then((blob) => FileSaver.saveAs(blob, selection[0]))
        .catch(console.debug);
    } else {
      showInfoSnackbar(EXPORT_FROM_SERVER_MSG);
      getApiEndpoint(`export_multiple_${resourceType}`)
        .setBodyData(JSON.stringify(selection))
        .getFetchPromise()
        .catch(() => showErrorSnackbar(INTERNET_ERROR_MSG))
        .then((response) => {
          refreshData();
          if (response.ok) {
            hideSnackbar();
            return response.blob();
          }
          showErrorSnackbar(SERVER_FAILED_MSG);
        })
        .then((blob) => FileSaver.saveAs(blob, "export.zip"))
        .catch(console.debug);
    }
  }

  return (
    <Grow in={selection.length > 0}>
      <Fab
        color="primary"
        aria-label={strings.toxDB_projectOverview_download}
        onClick={onClick}
      >
        <Icon className="fa fa-download" />
      </Fab>
    </Grow>
  );
};

export default DownloadFAB;

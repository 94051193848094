import React from "react";
import ThemedBackground from "./common/ThemedBackground";


export default class NotFound extends ThemedBackground {

    renderBody() {
        return (
            <div className="col-12 flex-center fill-height">
                <div className="background-item row text-center">
                    <div className="col-12">
                        <h1>This page is not available...</h1>
                    </div>
                    <div className="col-12">
                        <small>{ this.props.message ? this.props.message : "Please use the back button to get back to your original destination" }</small>
                    </div>
                </div>
            </div>
        );
    }

}

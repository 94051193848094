import { PERMISSION_HIERARCHY } from "../users/Permission";


export const KEY_ACCESS_TOKEN = "ACCESS_TOKEN";
export const KEY_SECONDS_LEFT = "SECONDS_LEFT";
export const KEY_DATETIME = "DATETIME";
export const KEY_STATE = "STATE";
export const KEY_ORIGINATE_FROM_NAV = "NAV_MODE";
export const KEY_PERMISSIONS = "PERMISSIONS";

const MESSAGE_AUTH_SUCCESSFUL =
  "Authentication succeeded! You can continue in other windows if those are still open.";

export function hasPermission(permission, permissions) {
  if (permissions && permissions.includes(permission)) {
    return true;
  }

  const storedPermissions = window.localStorage.getItem(KEY_PERMISSIONS);

  if (storedPermissions && storedPermissions.includes(permissions)) {
    return true;
  }

  return false;
}

export function getChildrenPermissions(currentPerm) {
  if (PERMISSION_HIERARCHY[currentPerm].length === 0) {
    return currentPerm;
  }

  return [currentPerm].concat(
    ...PERMISSION_HIERARCHY[currentPerm].map((node) =>
      getChildrenPermissions(node)
    )
  );
}

import React from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

import FileEntry from "./FileEntry";
import PlateChecker from "./PlateChecker";
import { strings } from "../../localization";

import "../../resources/css/annotation/single/Experiment.css";

const Plate = SortableElement(
  ({
    fileName,
    experimentNumber,
    setHijackComponent,
    editing,
    fileEntryHandler,
    removePlate,
    draggable,
  }) => (
    <li className="col-12 mt-1 d-block">
      <FileEntry
        editing={editing}
        fileEntryHandler={fileEntryHandler}
        fileName={fileName}
        removePlate={removePlate}
        draggable={draggable}
      />
    </li>
  )
);

const SortablePlates = SortableContainer(
  ({
    order,
    experimentNumber,
    setHijackComponent,
    editing,
    fileEntryHandlers,
    removePlate,
    draggable,
  }) => (
    <ul className="full-width pl-0 mb-0">
      {order.map((fileName, idx) => (
        <Plate
          index={idx}
          experimentNumber={experimentNumber}
          key={fileName}
          fileName={fileName}
          setHijackComponent={setHijackComponent}
          editing={editing}
          fileEntryHandler={fileEntryHandlers[fileName]}
          removePlate={() => removePlate(fileName)}
          draggable={draggable}
        />
      ))}
    </ul>
  )
);

export default class Experiment extends React.Component {
  state = {
    emptyButton: true,
  };

  constructor(props) {
    super(props);
    this.uploadFile = this.uploadFile.bind(this);
  }

  uploadFile(evt) {
    let files = evt.target.files;
    if (evt.type === "drop") {
      evt.preventDefault();
      files = evt.dataTransfer.files;
    }

    const input = evt.target;
    this.setState(
      {
        emptyButton: true,
      },
      () => {
        files = Array.from(files);
        this.props.setHijackComponent(
          <PlateChecker
            wellConfig={this.props.wellConfig}
            files={files}
            close={() => this.props.setHijackComponent(null)}
            addPlates={this.props.addPlates}
            getEndpoint={this.props.getEndpoint}
          />
        );
        // Reset input
        input.value = "";
      }
    );
  }

  render() {
    const order =
      this.props.plateOrder ||
      Array.from(new Array(this.props.plates), (_, idx) => idx + 1);

    return (
      <div
        className={
          "experiment offset-sm-1 offset-md-2 col-12 col-sm-10 col-md-8 mt-2 py-1 " +
          (this.props.className ? this.props.className : "") +
          (this.props.lastItem ? " last-item" : "")
        }
      >
        <div className="row">
          <div className="col-12 header">
            <h4 className="pb-2">
              {strings.toxDB_create_annotation_experiment}{" "}
              {this.props.experimentNumber}
              {!this.props.editing && (
                <span className="float-right icon-container">
                  <i className="fa fa-download icon-circle pointer" />
                </span>
              )}
            </h4>
          </div>
          <SortablePlates
            order={order}
            experimentNumber={this.props.experimentNumber}
            editing={this.props.editing}
            fileEntryHandlers={this.props.fileEntryHandlers}
            useDragHandle={true}
            lockToContainerEdges={true}
            lockAxis="y"
            onSortEnd={this.props.orderHandler}
            removePlate={this.props.removePlate}
            draggable={this.props.draggable}
          />
          {this.props.editing && (
            <i
              className="fa fa-times-circle pointer position-absolute close-icon"
              onClick={this.props.deleteExperiment}
            />
          )}
        </div>
        {this.props.editing && (
          <div
            className="row"
            onDrop={this.uploadFile}
            onDragEnter={() => this.setState({ emptyButton: false })}
            onDragLeave={() => this.setState({ emptyButton: true })}
            onDragOver={(evt) => evt.preventDefault()}
          >
            <div className="col-12 flex-h-center">
              {this.props.maximumPlates === order.length ? (
                <span>
                  {
                    strings.toxDB_create_annotation_experiment_upload_deleteFirst
                  }
                </span>
              ) : (
                <label
                  className={
                    "btn btn-primary " +
                    (this.state.emptyButton ? "empty-button" : "")
                  }
                >
                  {strings.toxDB_create_annotation_experiment_upload}
                  <input
                    type="file"
                    hidden
                    onChange={this.uploadFile}
                    multiple
                  />
                </label>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

import React from "react";
import XLSX from "xlsx";
import csvParse from "csv-parse/lib/es5";

import {readBlobAsText} from "../../utils";
import Overlay from "../../common/Overlay";


export default class CsvViewer extends Overlay {

    updateState() {
        if (this.props.blob && !this.state.content) {
            const _this = this;
            readBlobAsText(this.props.blob).then(text => {
                csvParse(text, {skip_empty_lines: true, relax_column_count: true}, (_, data) => {
                    if (data !== null) {
                        _this.setState({content: data});
                    }
                });
            });
        }
    }

    componentDidMount() {
        this.updateState();
    }

    componentDidUpdate() {
        this.updateState();
    }

    renderContent(themeData, content) {
        return (
            <table className="table table-striped table-striped-column">
                <tbody>
                {
                    content.map((row, idx) =>
                        <tr key={idx}>
                            {
                                row.map((item, itemIdx) =>
                                    <td key={itemIdx}>{item}</td>
                                )
                            }
                        </tr>
                    )
                }
                </tbody>
            </table>
        );
    }
}

function handleWorkbook(file, workbook, newFileCallback) {
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const csvData = XLSX.utils.sheet_to_csv(worksheet);
    const newFile = new File([csvData], file.name);
    newFileCallback(newFile);
}

export function converToCsv(file, newFileCallback) {
    const reader = new FileReader();
    reader.onload = function(e) {
        try {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, {type: 'array'});
            handleWorkbook(file, workbook, newFileCallback);
        } catch(Error) {
            newFileCallback(file);
        }
    };
    reader.readAsArrayBuffer(file);
}

import {
  all,
  call,
  put,
  fork,
  delay,
  cancelled,
  take,
  cancel,
} from "redux-saga/effects";

import { setSnackbar } from "./common/simpleSnackbarSlice";
import { set } from "./common/permissionSlice";
import getApiEndpoint from "./endpoint_configuration";
import { logout, login } from "./auth/authSlice";
import { INTERNET_ERROR_MSG } from "./ErrorMessages";
import { KEY_PERMISSIONS } from "./common/Authenticate";

export function* fetchPermissions() {
  let request = null;
  try {
    request = yield call(() =>
      getApiEndpoint("user_permissions").getFetchPromise()
    );
  } catch (error) {
    yield put(setSnackbar({ mode: "error", message: INTERNET_ERROR_MSG }));
  }

  let response = null;
  try {
    response = yield request.json();
  } catch {
    yield put(setSnackbar({ mode: "error", message: INTERNET_ERROR_MSG }));
  }

  if (!response || response.ok) {
    put(setSnackbar({ mode: "error", message: INTERNET_ERROR_MSG }));
  } else if (request) {
    window.localStorage.setItem(
      KEY_PERMISSIONS,
      JSON.stringify(response.permissions)
    );
    yield put(set(response.permissions));
  }
}

function* bgSync() {
  try {
    yield fork(fetchPermissions);

    while (true) {
      const refreshRate = 600000;
      yield delay(refreshRate);

      yield fork(fetchPermissions);
    }
  } finally {
    if (yield cancelled()) yield put(set([]));
  }
}

function* main() {
  while (yield take(login().type)) {
    const bgSyncTask = yield fork(bgSync);

    yield take(logout().type);
    yield cancel(bgSyncTask);
  }
}

export default function* rootSaga() {
  yield all([main()]);
}

import "../../resources/css/annotation/single/ProjectView.css";

import { BatchRequest, defaultJsonResponse } from "../../ApiEndpoint";
import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
import { connect } from "react-redux";
import {
  EXPORT_FROM_SERVER_MSG,
  INTERNET_ERROR_MSG,
  SERVER_FAILED_MSG,
} from "../../ErrorMessages";
import {
  LocalFileEntryHandler,
  RemoteFileEntryHandler,
} from "./FileEntryHandler";
import { Prompt } from "react-router-dom";

import { ALPHABETS } from "../Alphabet";
import AnnotationGenerator from "./annotation-generator/AnnotationGenerator";
import EditableHeader from "./EditableHeader";
import Experiment from "./Experiment";
import Fab from "@material-ui/core/Fab";
import FileEntry from "./FileEntry";
import FileSaver from "file-saver";
import Icon from "@material-ui/core/Icon";
import Loading from "../../common/Loading";
import { ROW_REGEX } from "./annotation-generator/PlateConfiguration";
import React from "react";
import { TOXPLOT_CREATE_PROJECT } from "../../users/Permission";
import ThemedBackground from "../../common/ThemedBackground";
import ToggleButton from "../../common/ToggleButton";
import { arrayMove } from "react-sortable-hoc";
import getApiEndpoint from "../../endpoint_configuration";
import { readBlobAsText } from "../../utils";
import { strings } from "../../localization";

import { clearSnackbar, setSnackbar } from "../../common/simpleSnackbarSlice";
import { RootState, AppDispatch } from "../../store";
const TIME_FOR_EDITING = 30;

const mapStateToProps = (store: RootState) => {
  return {
    permissions: store.permissions,
  };
};

function mapDispatchToProps(dispatch: AppDispatch) {
  return {
    showErrorSnackbar: (message: string) =>
      dispatch(setSnackbar({ message, mode: "error" })),
    showWarningSnackbar: (message: string) =>
      dispatch(setSnackbar({ message, mode: "warning" })),
    showInfoSnackbar: (message: string) =>
      dispatch(setSnackbar({ message, mode: "info" })),
    clearSnackbar: () => dispatch(clearSnackbar()),
  };
}
class ProjectView extends ThemedBackground {
  constructor(props) {
    super(props);

    this.genericInputHandler = this.genericInputHandler.bind(this);
    this.annotConfigInputHandler = this.annotConfigInputHandler.bind(this);

    this.getOrderHandler = this.getOrderHandler.bind(this);
    this.onTagKey = this.onTagKey.bind(this);
    this.removeTag = this.removeTag.bind(this);
    this.downloadExperiment = this.downloadExperiment.bind(this);
    this.onCreateAnalyze = this.onCreateAnalyze.bind(this);

    this.hasChanges = this.hasChanges.bind(this);
    this.startEditing = this.startEditing.bind(this);
    this.resetOriginalData = this.resetOriginalData.bind(this);

    this.addPlates = this.addPlates.bind(this);
    this.removePlate = this.removePlate.bind(this);
    this.addExperiment = this.addExperiment.bind(this);
    this.deleteExperiment = this.deleteExperiment.bind(this);
    this.afCorrectionInputHandler = this.afCorrectionInputHandler.bind(this);

    this.processAnnotationFile = this.processAnnotationFile.bind(this);
    this.addAnnotationFile = this.addAnnotationFile.bind(this);
    this.removeAnnotation = this.removeAnnotation.bind(this);

    this.cancel = this.cancel.bind(this);
    this.save = this.save.bind(this);

    this.fullDownload = this.fullDownload.bind(this);

    this.lockLeft = TIME_FOR_EDITING;
    this.lockTimeout = null;

    this.setHijackComponent = this.setHijackComponent.bind(this);

    this.originalData = null;
    this.originalPlateOrder = null;
    this.experimentFileEntryHandlers = [];

    this.fileEntryHandlers = {
      annotation: null,
      experiments: [],
    };

    this.form = React.createRef();
    this.validate = this.validate.bind(this);

    this.availableReporters = {};

    const lockedItems = JSON.parse(
      window.localStorage.getItem("LOCKED_ITEMS") || "{}"
    );
    let editing = false;

    if (this.props.projectId && this.props.projectId in lockedItems) {
      const dateOfLocking = new Date(lockedItems[this.projectId]);
      if (
        Math.ceil((new Date() - dateOfLocking) / 1000) / 60 <
        TIME_FOR_EDITING
      ) {
        editing = true;
      }
    }
    const projectId = this.props.match.params.id;
    this.state = {
      rowCompounds: {},
      selectedRowCompounds: [],
      hijackComponent: null,
      editing: !projectId || editing,
      existingEditing: editing,
      header: null,
      wellConfig: "96",
      tags: [],
      experiments: [],
      experimentPlateOrder: {},
      maximumPlates: null,
      projectId: projectId || null,
      annotConfigurationMode: "generate",
      // AF correction
      afCorrectionRow: "none",
      afCompounds: [],
      plateHeadersWell: "",
      plateHeadersGfpMean: "",
      plateHeadersCellConc: "",
      plate_header_set: false,
      permissions: [],
      originalAnnotationFile: null,
      annotationTemplateUrl: null,
    };
  }

  componentWillMount() {
    super.componentWillMount();
    this.resetOriginalData(null, false);
  }

  componentDidMount() {
    this.form = React.createRef();
    const lockedItems = JSON.parse(
      window.localStorage.getItem("LOCKED_ITEMS") || "{}"
    );
    if (this.state.projectId && this.state.projectId in lockedItems) {
      const dateOfLocking = new Date(lockedItems[this.state.projectId]);
      if (
        Math.ceil((new Date() - dateOfLocking) / 1000) / 60 <
        TIME_FOR_EDITING
      ) {
        this.setState({ editing: true });
      }
    }

    // TODO: get permissions
  }

  validate() {
    this.form.current.reportValidity();
  }

  componentWillUnmount() {
    if (this.state.editing) {
      this.unlock();
    }
  }

  genericInputHandler(name) {
    return function (event) {
      this.setState({ [name]: event.target.value });
    }.bind(this);
  }

  genericPlateHeaderInputHandler(name) {
    return function (event) {
      this.setState({ [name]: event.target.value, plate_header_set: false });
    }.bind(this);
  }

  annotConfigInputHandler(event) {
    this.setState({
      annotConfigurationMode: event.target.value,
      selectedRowCompounds: [],
      rowCompounds: {},
    });
  }

  afCorrectionInputHandler(event) {
    // Change the selectedRow at the same.
    const newValue = event.target.value;
    this.setState(
      {
        afCorrectionRow: newValue,
      },
      () => {
        if (this.state.afCorrectionRow !== "none") {
          this.setState({
            selectedRowCompounds: this.state.rowCompounds[
              this.state.afCorrectionRow
            ],
          });
        } else {
          this.setState({ selectedRowCompounds: [] });
        }
      }
    );
  }

  genericAfCompoundHandler(compound) {
    return function (event) {
      const arrayIndex = this.state.afCompounds.indexOf(compound);
      let copy = [...this.state.afCompounds];
      if (event.target.checked && arrayIndex === -1) {
        copy.push(compound);
        this.setState({ afCompounds: copy });
      } else if (!event.target.checked && arrayIndex !== -1) {
        copy.splice(arrayIndex, 1);
        this.setState({ afCompounds: copy });
      }
    }.bind(this);
  }

  resetOriginalData(callback, force) {
    if (!this.state.projectId) {
      this.originalData = {
        name: null,
        experiments: [],
        tags: [],
      };
      return;
    }

    if (this.originalData === null || force) {
      this.fileEntryHandlers.annotation = new RemoteFileEntryHandler(
        this.setHijackComponent,
        "get_annotation",
        { annotation_hash: this.state.projectId }
      );
      this.fileEntryHandlers.annotation.iconConfig.delete = false;
      getApiEndpoint("get_meta")
        .bindUrlParameter("annotation_hash", this.state.projectId)
        .getFetchPromise()
        .catch(() => this.props.showErrorSnackbar(INTERNET_ERROR_MSG))
        .then(defaultJsonResponse(this.props.showErrorSnackbar))
        .then((jsonData) => {
          this.originalData = jsonData;
          Object.values(this.originalData.experiments).forEach((obj) => {
            obj.original = true;
            obj.originalExperiment = obj.experiment;
          });

          const batchObject = new BatchRequest().requiresAuthentication();
          batchObject
            .addEndpoint(
              this.fileEntryHandlers.annotation.getFilledEndpoint(
                this.fileEntryHandlers.annotation.fileHeadEndpoint
              )
            )
            .then((data) =>
              this.fileEntryHandlers.annotation.setFileSize(
                data["Content-Length"]
              )
            );

          this.originalPlateOrder = {};
          jsonData.experiments.forEach(({ experiment, plates }) => {
            const fileHandlerObject = {};
            plates.forEach((plateNumber) => {
              const plateName = `plate${plateNumber}.csv`;
              fileHandlerObject[plateName] = new RemoteFileEntryHandler(
                this.setHijackComponent,
                "get_plate",
                {
                  annotation_hash: this.state.projectId,
                  experiment: experiment,
                  plate: plateNumber,
                }
              );

              if (!this.originalPlateOrder[experiment]) {
                this.originalPlateOrder[experiment] = [];
              }
              this.originalPlateOrder[experiment].push(plateName);

              const instance = fileHandlerObject[plateName];
              batchObject
                .addEndpoint(
                  instance.getFilledEndpoint(instance.fileHeadEndpoint)
                )
                .then((data) => instance.setFileSize(data["Content-Length"]));
            });
            this.experimentFileEntryHandlers.push(fileHandlerObject);
          });
          batchObject.fetch((success, response) => {
            if (!success) {
              this.props.showErrorSnackbar(INTERNET_ERROR_MSG);
            }
          });

          this.resetOriginalData(callback, false);
        })
        .catch(console.debug);
    } else {
      this.fileEntryHandlers.experiments = [];
      this.experimentFileEntryHandlers.forEach((obj) =>
        this.fileEntryHandlers.experiments.push(Object.assign({}, obj))
      );
      const plateHeaders = {};
      if (this.originalData.plate_headers) {
        plateHeaders.plateHeadersWell = this.originalData.plate_headers.well;
        plateHeaders.plateHeadersGfpMean = this.originalData.plate_headers.gfp_mean;
        plateHeaders.plateHeadersCellConc = this.originalData.plate_headers.cell_conc;
      }
      this.setState(
        {
          header: this.originalData.name,
          wellConfig: this.originalData.well_config,
          s9: this.originalData.s9,
          tags: this.originalData.tags,
          afCorrectionRow: this.originalData.af_correction_row || "none",
          afCompounds: this.originalData.af_correction_compounds || [],
          experiments: JSON.parse(
            JSON.stringify(this.originalData.experiments)
          ),
          experimentPlateOrder: JSON.parse(
            JSON.stringify(this.originalPlateOrder)
          ),
          hijackComponent: null,
          maximumPlates: this.originalData.maximum_plates,
          annotationTemplateUrl: this.originalData.annotation_template,
          ...plateHeaders,
        },
        callback
      );
      if (this.state.originalAnnotationFile) {
        this.fileEntryHandlers.annotation = this.state.originalAnnotationFile;
        this.setState({ originalAnnotationFile: null });
      }
    }
  }

  onTagKey(event) {
    if (event.key.toLowerCase() === "enter") {
      const value = event.target.value.trim();
      if (value.length > 0) {
        event.target.value = "";
        const tags = [...this.state.tags];
        tags.push(value);
        this.setState({ tags: tags });
      }
    }
  }

  onCreateAnalyze() {
    if (this.state.projectId) {
      this.props.history.push("/analysis/create", {
        projects: [this.state.projectId],
      });
    }
  }

  removeTag(idx) {
    return () => {
      const tags = [...this.state.tags];
      tags.splice(idx, 1);
      this.setState({ tags: tags });
    };
  }

  downloadExperiment(experiment) {
    return () => {
      getApiEndpoint("get_experiment")
        .bindUrlParameter("annotation_hash", this.state.projectId)
        .bindUrlParameter("experiment", experiment)
        .getFetchPromise()
        .catch(() => this.props.showErrorSnackbar(INTERNET_ERROR_MSG))
        .then((response) => {
          if (!response.ok) {
            this.props.showErrorSnackbar(SERVER_FAILED_MSG);
            return;
          }
          return response.blob();
        })
        .then((blob) => FileSaver.saveAs(blob, `Experiment${experiment}.zip`))
        .catch(console.debug);
    };
  }

  setHijackComponent(component, callback) {
    this.setState(
      {
        hijackComponent: component,
      },
      callback
    );
  }

  getOrderHandler(experiment) {
    return ({ oldIndex, newIndex }) => {
      const sortableExperiments = JSON.parse(
        JSON.stringify(this.state.experimentPlateOrder)
      );
      sortableExperiments[experiment] = arrayMove(
        sortableExperiments[experiment],
        oldIndex,
        newIndex
      );
      this.setState({ experimentPlateOrder: sortableExperiments });
    };
  }

  hasChanges() {
    if (!this.state.projectId) return true;
    if (!this.originalData) return false;

    const conditions = [
      !!this.state.originalAnnotationFile && this.fileEntryHandlers.annotation,
      // Tags equal
      this.state.tags.length !== this.originalData.tags.length ||
        !this.state.tags.every(
          (tag, idx) => this.originalData.tags[idx] === tag
        ),
      // Whether sort has been interrupted
      this.state.experiments.length !== this.originalData.experiments.length ||
        !Object.values(this.state.experimentPlateOrder).every(
          (order, idx) =>
            this.originalPlateOrder[idx + 1].length === order.length
        ) ||
        !Object.keys(this.state.experimentPlateOrder).every((experiment) =>
          this.state.experimentPlateOrder[experiment].every(
            (value, idx) => value === this.originalPlateOrder[experiment][idx]
          )
        ),
      // Whether experiments have been added
      !this.state.experiments.every((experiment) => experiment.original),
      // Data points
      this.state.header !== this.originalData.name,
      this.state.wellConfig !== this.originalData.well_config,
      this.state.s9 !== this.originalData.s9,
    ];
    return conditions.some((bool) => bool);
  }

  countdownLocktime(minutes, ...nextMinutes) {
    this.lockTimeout = setTimeout(() => {
      this.lockLeft -= minutes;
      if (this.lockLeft === 0) {
        this.cancel();
      } else {
        // this.props.notificationManager.current.show(
        //   <div
        //     onClick={() => {
        //       this.lock();
        //       this.props.notificationManager.current.hide();
        //     }}
        //     style={{
        //       cursor: "pointer",
        //     }}
        //   >
        //     <span>
        //       Click this message to extend your editing time, which is running
        //       out in {this.lockLeft}
        //       minutes
        //     </span>
        //   </div>,
        //   "warning"
        // );
        // TODO: add continue editing
      }

      if (nextMinutes.length > 1) {
        this.countdownLocktime(nextMinutes.shift(), ...nextMinutes);
      }
    }, minutes * 60 * 1000);
  }

  lock(successfulLockCallback) {
    getApiEndpoint("lock_project")
      .bindUrlParameter("annotation_hash", this.state.projectId)
      .getFetchPromise()
      .catch(() => this.props.showErrorSnackbar(INTERNET_ERROR_MSG))
      .then(defaultJsonResponse(this.props.showErrorSnackbar))
      .then((jsonData) => {
        if (jsonData.locked) {
          this.lockLeft = TIME_FOR_EDITING;
          if (this.lockTimeout != null) clearTimeout(this.lockTimeout);
          this.countdownLocktime(20, 6, 4);

          const lockedItems = JSON.parse(
            window.localStorage.getItem("LOCKED_ITEMS") || "{}"
          );
          lockedItems[this.state.projectId] = new Date().toISOString();
          window.localStorage.setItem(
            "LOCKED_ITEMS",
            JSON.stringify(lockedItems)
          );

          (successfulLockCallback || (() => 1))();
        } else {
          this.props.showWarningSnackbar(
            "Somebody is already editing this project!",
            "warning"
          );
        }
      });
  }

  unlock() {
    if (this.state.projectId) {
      getApiEndpoint("unlock_project")
        .bindUrlParameter("annotation_hash", this.state.projectId)
        .getFetchPromise()
        .catch(() => this.props.showErrorSnackbar(INTERNET_ERROR_MSG))
        .then((_) => {
          let lockedItems = JSON.parse(
            window.localStorage.getItem("LOCKED_ITEMS") || "{}"
          );
          delete lockedItems[this.state.projectId];
          window.localStorage.setItem(
            "LOCKED_ITEMS",
            JSON.stringify(lockedItems)
          );
        });
    }
    if (this.lockTimeout != null) clearTimeout(this.lockTimeout);
  }

  startEditing() {
    this.lock(() => this.setState({ editing: true, existingEditing: true }));
  }

  removePlate(experiment) {
    return (name) => {
      const plateOrder = Object.assign({}, this.state.experimentPlateOrder);
      const idx = plateOrder[experiment + 1].indexOf(name);
      if (idx !== -1) {
        plateOrder[experiment + 1].splice(idx, 1);
      }
      delete this.fileEntryHandlers.experiments[experiment][name];
      this.setState({ experimentPlateOrder: plateOrder });
    };
  }

  removeAnnotation(evt) {
    this.setState({
      originalAnnotationFile: this.fileEntryHandlers.annotation,
    });
    this.fileEntryHandlers.annotation = null;
    this.setState({ maximumPlates: null });
  }

  processAnnotationFile(annotationFile) {
    this.setHijackComponent(<Loading />, () => {
      readBlobAsText(annotationFile).then((text) => {
        getApiEndpoint("validate_annotation")
          .bindQueryParameter("well_config", this.state.wellConfig)
          .setBodyData(text)
          .getFetchPromise()
          .catch(() => this.props.showErrorSnackbar(INTERNET_ERROR_MSG))
          .then(defaultJsonResponse(this.props.showErrorSnackbar))
          .then((jsonData) => {
            if (jsonData.status === "error") {
              this.setHijackComponent(null, () =>
                this.props.showErrorSnackbar("annotation " + jsonData.error)
              );
            } else {
              const splitAnnotationFile = text
                .split("\n")
                .map((line) => line.split(/[,;\t]/));

              const maximumPlates = splitAnnotationFile[0].length / 2 - 1;
              const experimentPlateOrderCopy = JSON.parse(
                JSON.stringify(this.state.experimentPlateOrder)
              );
              Object.entries(experimentPlateOrderCopy).forEach(
                ([key, array]) => {
                  experimentPlateOrderCopy[key] = array.slice(0, maximumPlates);
                  const entryHandlerKey = parseInt(key, 10) - 1;
                  Object.keys(
                    this.fileEntryHandlers.experiments[entryHandlerKey]
                  )
                    .filter((fileName) => experimentPlateOrderCopy[key] === -1)
                    .forEach(
                      (fileName) =>
                        delete this.fileEntryHandlers.experiments[
                          entryHandlerKey
                        ][fileName]
                    );
                }
              );

              // Determine the available treatments on each row, regardless of row
              this.setState({ rowCompounds: {} });
              this.availableReporters = {};

              const handledHeader = splitAnnotationFile[1].map((header) =>
                header.trim()
              );
              const wellIndex = handledHeader.indexOf("Well");
              const reporterIndex = handledHeader.indexOf("Reporter");

              const nextIndex = (index) =>
                handledHeader.indexOf("Treatment", index);
              for (
                let treatmentIndex = nextIndex(0);
                treatmentIndex >= 0;
                treatmentIndex = nextIndex(treatmentIndex)
              ) {
                for (
                  let rowIndex = 2;
                  rowIndex < splitAnnotationFile.length;
                  rowIndex++
                ) {
                  const rowData = splitAnnotationFile[rowIndex];
                  if (rowData[wellIndex].length === 0) continue;

                  const plateRow = ROW_REGEX.exec(rowData[wellIndex])[1];
                  if (!(plateRow in this.state.rowCompounds)) {
                    this.setState({
                      rowCompounds: {
                        ...this.state.rowCompounds,
                        [plateRow]: new Set(),
                      },
                    });
                  }

                  // Add it to the data
                  const treatment = rowData[treatmentIndex].trim();
                  if (treatment.length !== 0) {
                    const rowToUpdate = this.state.rowCompounds[plateRow];
                    rowToUpdate.add(treatment);
                    this.setState({
                      rowCompounds: {
                        ...this.state.rowCompounds,
                        [plateRow]: rowToUpdate,
                      },
                    });
                  }

                  const reporter = rowData[reporterIndex].trim();
                  if (
                    reporter.length !== 0 &&
                    !(plateRow in this.availableReporters)
                  ) {
                    this.availableReporters[plateRow] = reporter;
                  }
                }
                treatmentIndex += 1;
              }

              this.fileEntryHandlers.annotation = new LocalFileEntryHandler(
                this.setHijackComponent,
                annotationFile
              );
              this.setState({
                hijackComponent: null,
                maximumPlates: maximumPlates,
                experimentPlateOrder: experimentPlateOrderCopy,
                // reset AF correction
                afCompounds: [],
                selectedRowCompounds: this.state.rowCompounds[
                  this.state.afCorrectionRow
                ],
              });

              if (this.state.annotConfigurationMode !== "generate") {
                this.setState({ afCorrectionRow: "none" });
              }
            }
          })
          .catch(console.debug);
      });
    });
  }

  addAnnotationFile(evt) {
    this.processAnnotationFile(evt.target.files[0]);
  }

  addPlates(experiment) {
    return (files, callback) => {
      const plateOrder = Object.assign({}, this.state.experimentPlateOrder);
      if (this.state.maximumPlates) {
        files = files.slice(
          0,
          Math.min(
            files.length,
            this.state.maximumPlates - plateOrder[experiment + 1].length
          )
        );
      }

      files.forEach((file) => {
        const fileComponents = file.name.split(".");

        if (plateOrder[experiment + 1].indexOf(file.name) !== -1) {
          let counter = 1;
          const newFileName = () =>
            `${fileComponents[0]} (${counter})${
              fileComponents.length > 1 ? "." : ""
            }${fileComponents.slice(1).join(".")}`;

          let lastFileName;
          while (
            plateOrder[experiment + 1].indexOf(
              (lastFileName = newFileName())
            ) !== -1
          ) {
            counter += 1;
          }
          file = new File([file], lastFileName);
        }
        this.fileEntryHandlers.experiments[experiment][
          file.name
        ] = new LocalFileEntryHandler(this.setHijackComponent, file);
        plateOrder[experiment + 1].push(file.name);
      });
      this.setState(
        {
          experimentPlateOrder: plateOrder,
        },
        callback
      );
    };
  }

  addExperiment() {
    this.fileEntryHandlers.experiments.push({});
    const plateOrder = JSON.parse(
      JSON.stringify(this.state.experimentPlateOrder)
    );
    const experiments = JSON.parse(JSON.stringify(this.state.experiments));

    const maxKey = Math.max(
      ...Object.keys(plateOrder).map((value) => parseInt(value, 10)),
      0
    );
    plateOrder[maxKey + 1] = [];
    experiments.push({
      experiment: maxKey + 1,
      plates: 0,
      original: false,
    });
    this.setState({
      experimentPlateOrder: plateOrder,
      experiments: experiments,
    });
  }

  deleteExperiment(experiment) {
    return () => {
      const experimentsCopy = JSON.parse(
        JSON.stringify(this.state.experiments)
      );
      experimentsCopy.splice(experiment - 1, 1);
      Object.keys(experimentsCopy).forEach((key) => {
        if (experimentsCopy[key].experiment > experiment) {
          experimentsCopy[key].experiment -= 1;
        }
      });

      const experimentPlateOrder = JSON.parse(
        JSON.stringify(this.state.experimentPlateOrder)
      );
      delete experimentPlateOrder[experiment];
      Object.keys(experimentPlateOrder)
        .map((key) => parseInt(key, 10))
        .forEach((key) => {
          if (key > experiment) {
            experimentPlateOrder[key - 1] = experimentPlateOrder[key];
            delete experimentPlateOrder[key];
          }
        });

      this.fileEntryHandlers.experiments.splice(experiment - 1, 1);
      this.setState({
        experiments: experimentsCopy,
        experimentPlateOrder: experimentPlateOrder,
      });
    };
  }

  getPlateEndpoint(validation, direct) {
    const baseEndpointName = validation ? "validate_plate" : "create_plate";
    const retriever = function () {
      const endpoint = getApiEndpoint(`${baseEndpointName}_parameterized`);
      return endpoint
        .bindQueryParameter("well_header", this.state.plateHeadersWell)
        .bindQueryParameter(
          "gfp_mean_header",
          this.state.plateHeadersGfpMean.trim()
        )
        .bindQueryParameter(
          "cell_conc_header",
          this.state.plateHeadersCellConc.trim()
        );
    }.bind(this);

    if (direct) return retriever();
    return retriever;
  }

  saveObject(callback) {
    const actions = {};

    const originalExperiments = this.state.experiments
      .filter((data) => data.original)
      .map((data) => data.originalExperiment);
    const newExperiments = this.state.experiments
      .filter((data) => !data.original)
      .map((data) => data.experiment);
    const deletedExperiments = [];

    // Find deleted files
    this.originalData.experiments.forEach(({ experiment, plates }) => {
      actions[experiment] = {
        delete: [],
        create: [],
        moves: {},
      };
      // Find deleted experiments
      if (originalExperiments.indexOf(experiment) === -1) {
        deletedExperiments.splice(0, 1, experiment);
        return;
      }

      for (
        let i = 0;
        i < deletedExperiments.length && deletedExperiments[i] < experiment;
        i++
      ) {
        experiment -= 1;
      }

      // Find deleted files
      const fileEntryHandlers = this.fileEntryHandlers.experiments[
        experiment - 1
      ];
      plates.forEach((plate) => {
        const plateName = `plate${plate}.csv`;
        if (
          !fileEntryHandlers[plateName] ||
          !(fileEntryHandlers[plateName] instanceof RemoteFileEntryHandler)
        ) {
          actions[experiment].delete.push(plate);
        }
      });
      // Find new files
      Object.entries(fileEntryHandlers)
        .filter(
          ([fileName, handler]) => handler instanceof LocalFileEntryHandler
        )
        .forEach(([fileName, handler]) => {
          const plateIndex =
            this.state.experimentPlateOrder[experiment].indexOf(fileName) + 1;
          actions[experiment].create.push({
            plate: plateIndex,
            fileEntryHandler: handler,
          });
        });
    });

    // Find reordered files which exist on the remote
    Object.entries(this.state.experimentPlateOrder).forEach(
      ([experiment, plateOrder]) => {
        const remoteFiles = plateOrder.filter((plateName) => {
          const entryHandler = this.fileEntryHandlers.experiments[
            experiment - 1
          ][plateName];
          return entryHandler instanceof RemoteFileEntryHandler;
        });
        remoteFiles.forEach((remoteFile) => {
          const currentIdx = this.state.experimentPlateOrder[
            experiment
          ].indexOf(remoteFile);
          const originalIdx = this.originalPlateOrder[experiment].indexOf(
            remoteFile
          );
          if (currentIdx !== originalIdx) {
            actions[experiment].moves[originalIdx + 1] = currentIdx + 1;
          }
        });
      }
    );

    // Create the batchRequest
    const batchRequest = new BatchRequest().requiresAuthentication();
    const metaEdit = {};

    // Order: DELETE (cascading = false), REORDER, CREATE
    Object.entries(actions).forEach(([experiment, object]) => {
      // delete plate
      object.delete.forEach((plate) => {
        const endpoint = getApiEndpoint("delete_plate")
          .bindUrlParameter("annotation_hash", this.state.projectId)
          .bindUrlParameter("experiment", experiment)
          .bindUrlParameter("plate_number", plate)
          .bindQueryParameter("cascade", 0);
        batchRequest
          .addEndpoint(endpoint)
          .catch(() => this.props.showErrorSnackbar(SERVER_FAILED_MSG));
      });

      // ordering
      if (Object.keys(object.moves).length !== 0) {
        if (!metaEdit.ordering) metaEdit.ordering = {};
        metaEdit.ordering[parseInt(experiment, 10)] = object.moves;
      }
    });

    // Delete experiments
    deletedExperiments.forEach((experiment) => {
      const endpoint = getApiEndpoint("delete_experiment")
        .bindUrlParameter("annotation_hash", this.state.projectId)
        .bindUrlParameter("experiment", experiment);
      batchRequest
        .addEndpoint(endpoint)
        .catch(() => this.props.showErrorSnackbar(SERVER_FAILED_MSG));
    });

    // Update tags and visibility en possible the ordering
    if (
      this.state.tags.length !== this.originalData.tags.length ||
      !this.state.tags.every((tag, idx) => this.originalData.tags[idx] === tag)
    ) {
      metaEdit.tags = this.state.tags;
    }
    if (this.state.header !== this.originalData.name) {
      metaEdit.name = this.state.header;
    }
    if (Object.keys(metaEdit).length !== 0) {
      const endpoint = getApiEndpoint("edit_project")
        .bindUrlParameter("annotation_hash", this.state.projectId)
        .setBodyData(JSON.stringify(metaEdit));
      batchRequest
        .addEndpoint(endpoint)
        .catch(() => this.props.showErrorSnackbar(SERVER_FAILED_MSG));
    }

    // Add the new experiments
    newExperiments.forEach((experiment) => {
      const endpoint = getApiEndpoint("create_experiment").bindUrlParameter(
        "annotation_hash",
        this.state.projectId
      );
      batchRequest
        .addEndpoint(endpoint)
        .catch(() => this.props.showErrorSnackbar(SERVER_FAILED_MSG));

      actions[experiment] = {
        create: Object.entries(
          this.fileEntryHandlers.experiments[experiment - 1]
        ).map(([fileName, handler]) => {
          return {
            plate:
              this.state.experimentPlateOrder[experiment].indexOf(fileName) + 1,
            fileEntryHandler: handler,
          };
        }),
      };
    });

    const resetCallback = () =>
      this.setState(
        {
          editing: false,
        },
        callback
      );
    // Finally, add the new files
    let amountNewFiles = 0;
    let amountRequestReady = 0;
    Object.entries(actions).forEach(([experiment, object]) => {
      object.create.forEach(({ plate, fileEntryHandler }) => {
        amountNewFiles += 1;
        fileEntryHandler.requestFileContents((blob) => {
          readBlobAsText(blob).then((text) => {
            const endpoint = this.getPlateEndpoint(false, true)
              .bindUrlParameter("annotation_hash", this.state.projectId)
              .bindUrlParameter("experiment", experiment)
              .bindUrlParameter("plate_number", plate)
              .setBodyData(text);
            batchRequest
              .addEndpoint(endpoint)
              .catch(() => this.props.showErrorSnackbar(SERVER_FAILED_MSG));

            amountRequestReady += 1;
            if (amountRequestReady === amountNewFiles) {
              batchRequest.fetch(() => {
                this.resetOriginalData(resetCallback, true);
                this.unlock();
              });
            }
          });
        });
      });
    });

    if (!batchRequest.isEmpty()) {
      if (amountNewFiles === 0) {
        batchRequest.fetch((success, response) => {
          if (!success) {
            this.props.showErrorSnackbar(INTERNET_ERROR_MSG);

            return;
          }
          this.resetOriginalData(resetCallback, true);
          this.unlock();
        });
      }
    } else {
      this.unlock();
      this.setHijackComponent(null);
    }
  }

  save() {
    // Need an annotation when uploading
    if (!this.fileEntryHandlers.annotation) {
      this.props.showErrorSnackbar("Need to set the annotation file");
      return;
    }

    // Need at least 1 experiment
    if (this.state.experiments.length === 0) {
      this.props.showErrorSnackbar(
        "Need at least 1 experiment to be available"
      );
      return;
    }

    // Find empty experiments & notify the user about it
    const emptyExperiments = [];
    Object.values(this.state.experimentPlateOrder).forEach((order, idx) => {
      if (order.length === 0) {
        emptyExperiments.push(idx + 1);
      }
    });

    if (emptyExperiments.length > 0) {
      this.props.showErrorSnackbar(
        `Experiment${
          emptyExperiments.length > 1 ? "s" : ""
        } ${emptyExperiments.join(", ")} cannot be empty`
      );
      return;
    }
    if (this.state.header === "") {
      this.props.showErrorSnackbar("This project requires a name");
      return;
    }

    // TODO: Add waiting
    if (this.state.header !== this.originalData.name) {
      getApiEndpoint("validate_project_name")
        .bindUrlParameter("project_name", this.state.header)
        .getFetchPromise()
        .catch(() => this.props.showErrorSnackbar(INTERNET_ERROR_MSG))
        .then((response) => response.json())
        .then((jsonData) => {
          const available = jsonData.project === "available";
          if (available && this.state.projectId) {
            this.saveObject();
          } else if (available && !this.state.projectId) {
            this.fileEntryHandlers.annotation.requestFileContents((blob) => {
              readBlobAsText(blob).then((text) => {
                getApiEndpoint("create_project")
                  .bindUrlParameter("name", this.state.header)
                  .bindQueryParameter("plate_config", this.state.wellConfig)
                  .bindQueryParameter("s9", this.state.s9 === "+")
                  .bindQueryParameter(
                    "af_correction_row",
                    this.state.afCorrectionRow
                  )
                  .bindQueryParameter(
                    "af_correction_compounds",
                    this.state.afCompounds.join(",")
                  )
                  .setBodyData(text)
                  .getFetchPromise()
                  .catch(() => this.props.showErrorSnackbar(INTERNET_ERROR_MSG))
                  .then(defaultJsonResponse(this.props.showErrorSnackbar))
                  .then((jsonData) => {
                    this.state.projectId = jsonData.id;
                    this.saveObject(() =>
                      this.props.history.push("/project/show/" + jsonData.id)
                    );
                  })
                  .catch(console.debug);
              });
            });
          } else {
            // TODO: Hide backdrop
            this.props.showErrorSnackbar(
              `The name ${this.state.header} is already in use`
            );
          }
        })
        .catch(console.debug);
    } else if (this.state.originalAnnotationFile && this.state.projectId) {
      const setState = this.setState.bind(this);
      this.fileEntryHandlers.annotation.requestFileContents((blob) => {
        readBlobAsText(blob).then((text) => {
          getApiEndpoint("edit_project_content")
            .bindUrlParameter("annotation_hash", this.state.projectId)
            .setBodyData(text)
            .getFetchPromise()
            .then((results) => {
              if (results.status === 200) {
                setState({ originalAnnotationFile: null }, () =>
                  this.saveObject()
                );
              }
            })
            .catch(console.debug);
        });
      });
    } else {
      this.saveObject();
    }
  }

  cancel() {
    this.resetOriginalData(() => {
      this.setState(
        {
          editing: false,
        },
        () => {
          this.props.clearSnackbar();
        }
      );
      this.unlock();
    }, false);
  }

  fullDownload() {
    this.props.showInfoSnackbar(EXPORT_FROM_SERVER_MSG);
    getApiEndpoint("export_annotation")
      .bindUrlParameter("annotation_hash", this.state.projectId)
      .getFetchPromise()
      .catch(() => this.props.showErrorSnackbar(INTERNET_ERROR_MSG))
      .then((response) => {
        if (!response.ok) {
          this.props.showErrorSnackbar(SERVER_FAILED_MSG);
          return;
        }
        return response.blob();
      })
      .then((blob) => {
        FileSaver.saveAs(blob, this.state.projectId + ".zip");
        this.props.clearSnackbar();
      })
      .catch(console.debug);
  }

  renderBody(themeData) {
    const disabled = Boolean(this.state.projectId);

    const canEdit =
      !this.state.editing &&
      this.props.permissions &&
      this.props.permissions.indexOf(TOXPLOT_CREATE_PROJECT) > -1;

    const selectedWell = +(this.state.wellConfig === "384");

    const needsAnnotationUpload = this.fileEntryHandlers.annotation === null;
    const hasChanges = this.hasChanges();

    return (
      <React.Fragment>
        <React.Fragment>
          <form
            className="w-100"
            ref={this.form}
            onSubmit={(e) => e.preventDefault()}
          >
            <div className="background-item offset-0 offset-sm-1 offset-md-2 col-12 col-sm-10 col-md-8 mt-2 py-1">
              <div className="row justify-content-between">
                <div className="col-12 col-xl-9">
                  <EditableHeader
                    value={this.state.header}
                    valueChanged={(value) => this.setState({ header: value })}
                    showEditor={this.state.editing}
                  />
                </div>
                <div className="offset-4 offset-xl-0 col-12 col-xl-3 mt-2 mt-sm-0 align-middle">
                  <ToggleButton
                    options={[
                      strings.toxDB_create_wells96,
                      strings.toxDB_create_wells384,
                    ]}
                    values={Object.keys(ALPHABETS)}
                    title={strings.toxDB_create_wellConfig}
                    selected={selectedWell}
                    disabled={disabled}
                    valueChange={(value) =>
                      this.setState({ wellConfig: value })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="background-item project-view-item offset-sm-1 offset-md-2 col-12 col-sm-10 col-md-8 mt-2 py-1">
              <div className="row from-group">
                <div className="col-12">
                  <h4>{strings.toxDB_create_tags}</h4>
                </div>
                <div className="col-12">
                  <label>
                    <input
                      type="text"
                      placeholder={strings.toxDB_create_taginput}
                      className="form-control"
                      onKeyPress={this.onTagKey}
                    />
                  </label>
                </div>
                <div className="col-12">
                  <ul className="list-inline tag-container mb-0">
                    {this.state.tags &&
                      this.state.tags.map((tag, idx) => (
                        <li
                          className="list-inline-item badge badge-primary"
                          key={tag}
                        >
                          {tag}
                          <i
                            className="fa fa-times-circle pl-2 pointer"
                            onClick={this.removeTag(idx)}
                          />
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="background-item project-view-item offset-sm-1 offset-md-2 col-12 col-sm-10 col-md-8 mt-2 py-1">
              <div className="row">
                <div className="col-12">
                  <h4>
                    {strings.toxDB_create_annotation}
                    {canEdit && (
                      <i
                        className="fa fa-pencil-alt icon-circle pointer float-right"
                        onClick={this.startEditing}
                      />
                    )}
                  </h4>
                </div>
                {!needsAnnotationUpload && (
                  <FileEntry
                    renderAsCols={true}
                    fileEntryHandler={this.fileEntryHandlers.annotation}
                    fileName="annot.csv"
                    setHijackComponent={this.setHijackComponent}
                    editing={this.state.editing}
                    removePlate={this.removeAnnotation}
                  />
                )}
                {needsAnnotationUpload && (
                  <div className="col-12">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="annot-file-upload"
                        value="file"
                        checked={this.state.annotConfigurationMode === "file"}
                        onChange={this.annotConfigInputHandler}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="annot-file-upload"
                      >
                        {strings.toxDB_create_annotation_upload}
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="annot-generate-file"
                        value="generate"
                        checked={
                          this.state.annotConfigurationMode === "generate"
                        }
                        onChange={this.annotConfigInputHandler}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="annot-generate-file"
                      >
                        {strings.toxDB_create_annotation_generate}
                      </label>
                    </div>
                  </div>
                )}
                {needsAnnotationUpload &&
                  this.state.annotConfigurationMode === "file" && (
                    <div className="col-12 mt-2">
                      <div class="row">
                        <div className="offset-0 offset-sm-1 offset-lg-2 offset-xl-3 col-12 col-sm-5 col-lg-4 col-xl-3 p-0 pr-sm-1 mb-1 mb-sm-0">
                          <a
                            href={
                              process.env.PUBLIC_URL +
                              "/annotation_template.csv"
                            }
                            className="btn btn-secondary full-width"
                            style={{ color: "#fff" }}
                            download
                          >
                            {
                              strings.toxDB_create_annotation_downloadTemplateButton
                            }
                          </a>
                        </div>
                        <div className="col-12 col-sm-5 col-lg-4 col-xl-3 p-0 pr-sm-1 mb-1 mb-sm-0">
                          <label className="btn btn-primary full-width">
                            {strings.toxDB_create_annotation_uploadButton}
                            <input
                              type="file"
                              hidden="hidden"
                              onChange={this.addAnnotationFile}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                {!this.state.projectId &&
                  this.state.annotConfigurationMode === "file" && (
                    <React.Fragment>
                      <div className="col-12 flex-h-center mt-1">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <label
                              className="input-group-text"
                              htmlFor="af-correction-row"
                            >
                              {strings.toxDB_create_annotation_autoFluor}
                            </label>
                          </div>
                          <select
                            className="custom-select"
                            name="af-correction-row"
                            id="af-correction-row"
                            onChange={this.afCorrectionInputHandler}
                            value={this.state.afCorrectionRow}
                          >
                            <option value="none">
                              {strings.toxDB_create_annotation_autoFluorChooser}
                            </option>
                            {Object.entries(this.availableReporters).map(
                              (row) => (
                                <option key={row[0]} value={row[0]}>
                                  {row[1]}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      </div>
                      <div className="col-12 mt-1">
                        <small>
                          {strings.toxDB_create_annotation_autoFluorInfo}
                        </small>
                      </div>
                    </React.Fragment>
                  )}
                {needsAnnotationUpload &&
                  this.state.annotConfigurationMode === "generate" && (
                    <div className="col-12 mb-3">
                      <AnnotationGenerator
                        wellConfig={this.state.wellConfig}
                        hijackComponent={this.setHijackComponent}
                        setAnnotationFile={this.processAnnotationFile}
                        setAfCorrectionRow={this.afCorrectionInputHandler}
                      />
                    </div>
                  )}

                {!this.state.projectId &&
                  this.state.selectedRowCompounds &&
                  this.state.selectedRowCompounds.length !== 0 && (
                    <React.Fragment>
                      <div className="col-12">
                        <h5>
                          Please select which compounds are autofluorescent:
                        </h5>
                      </div>
                      <div className="col-12 mt-1">
                        <FormGroup row="row">
                          {[...this.state.selectedRowCompounds].map(
                            (compound) => (
                              <FormControlLabel
                                key={compound}
                                control={
                                  <Checkbox
                                    checked={
                                      this.state.afCompounds.indexOf(
                                        compound
                                      ) !== -1
                                    }
                                    onChange={this.genericAfCompoundHandler(
                                      compound
                                    )}
                                    value={compound}
                                    id={compound + "-af-checkbox"}
                                    color="primary"
                                  />
                                }
                                label={compound}
                              />
                            )
                          )}
                        </FormGroup>
                      </div>
                    </React.Fragment>
                  )}
                {this.state.projectId && this.state.afCorrectionRow !== "none" && (
                  <div className="col-12">
                    <span>
                      Auto fluorescence row: {this.state.afCorrectionRow}
                    </span>
                    <br />{" "}
                    {this.state.afCompounds.length !== 0 && (
                      <React.Fragment>
                        <span>Selected autofluorescence compounds:</span>
                        <ul>
                          {this.state.afCompounds.map((compound) => (
                            <li key={compound}>{compound}</li>
                          ))}
                        </ul>
                      </React.Fragment>
                    )}
                  </div>
                )}
              </div>
            </div>
            {this.state.editing && !this.state.projectId && (
              <React.Fragment>
                <div className="background-item project-view-item offset-sm-1 offset-md-2 col-12 col-sm-10 col-md-8 mt-2 py-1 pb-2">
                  <h3>{strings.toxDB_create_annotation_plate}</h3>
                  <div className="pb-1 mt-4 col-12 pr-0">
                    <div className="form-group row">
                      <label
                        htmlFor="well_header"
                        className="col-form-label col-sm-3"
                      >
                        {strings.toxDB_create_annotation_plate_well}
                      </label>
                      <input
                        className={
                          "col-sm-8 form-control" +
                          (this.state.plateHeadersWell.trim().length === 0
                            ? " is-invalid"
                            : "")
                        }
                        required="required"
                        type="text"
                        name="well_header"
                        id="well_header"
                        value={this.state.plateHeadersWell}
                        autoComplete="on"
                        onChange={this.genericPlateHeaderInputHandler(
                          "plateHeadersWell"
                        )}
                      />
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="gfp_mean_header"
                        className="col-form-label col-sm-3"
                      >
                        {strings.toxDB_create_annotation_plate_GFPMean}
                      </label>
                      <input
                        className={
                          "col-sm-8 form-control" +
                          (this.state.plateHeadersGfpMean.trim().length === 0
                            ? " is-invalid"
                            : "")
                        }
                        required="required"
                        type="text"
                        id="gfp_mean_header"
                        value={this.state.plateHeadersGfpMean}
                        onChange={this.genericPlateHeaderInputHandler(
                          "plateHeadersGfpMean"
                        )}
                        autoComplete="on"
                      />
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="cell_conc_header"
                        className="col-form-label col-sm-3 text-overflow"
                        title="Cell concentration"
                      >
                        {strings.toxDB_create_annotation_plate_cellConc}
                      </label>
                      <input
                        className={
                          "col-sm-8 form-control" +
                          (this.state.plateHeadersCellConc.trim().length === 0
                            ? " is-invalid"
                            : "")
                        }
                        required="required"
                        type="text"
                        id="cell_conc_header"
                        value={this.state.plateHeadersCellConc}
                        onChange={this.genericPlateHeaderInputHandler(
                          "plateHeadersCellConc"
                        )}
                        autoComplete="on"
                      />
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
            {this.state.experiments &&
              this.state.experiments.map((experimentObject, expIdx) => (
                <React.Fragment key={experimentObject.experiment}>
                  <div className="col-0 offset-sm-1 offset-md-2 col-sm-1 col-md-2" />
                  <Experiment
                    className="background-item project-view-item"
                    lastItem={expIdx === this.state.experiments.length - 1}
                    fileEntryHandlers={
                      this.fileEntryHandlers.experiments[expIdx]
                    }
                    experimentNumber={experimentObject.experiment}
                    plates={experimentObject.plates}
                    plateOrder={
                      this.state.experimentPlateOrder[
                        experimentObject.experiment
                      ]
                    }
                    orderHandler={this.getOrderHandler(
                      experimentObject.experiment
                    )}
                    setHijackComponent={this.setHijackComponent}
                    wellConfig={this.state.wellConfig}
                    addPlates={this.addPlates(expIdx)}
                    removePlate={this.removePlate(expIdx)}
                    maximumPlates={
                      this.originalData
                        ? this.state.maximumPlates
                        : Number.MAX_VALUE
                    }
                    deleteExperiment={this.deleteExperiment(
                      experimentObject.experiment
                    )}
                    editing={this.state.editing}
                    draggable={!this.state.existingEditing}
                    getEndpoint={this.getPlateEndpoint(true)}
                  />
                  <div className="col-0 col-sm-1 col-md-2" />
                </React.Fragment>
              ))}
            {this.state.editing && (
              <React.Fragment>
                <div className="offset-0 offset-sm-1 offset-md-4 col-12 col-sm-10 col-md-8 mt-2 py-1">
                  <div className="row">
                    <div className="col-md-6">
                      <button
                        className="btn btn-primary full-width"
                        onClick={this.addExperiment}
                      >
                        {strings.toxDB_create_annotation_experiment_add}
                      </button>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
            <div className="offset-0 offset-sm-1 offset-md-2 project-view-item col-12 col-sm-10 col-md-8 mt-2 py-1 pb-2 mb-2">
              {hasChanges && (
                <div className="row">
                  <div className="col-12">
                    <button
                      title={strings.toxDB_create_annotation_save}
                      className="btn btn-success p-2 mb-1 full-width"
                      onClick={this.save}
                    >
                      {strings.toxDB_create_annotation_save}
                    </button>
                  </div>
                </div>
              )}
              {this.state.projectId && (this.state.editing || hasChanges) && (
                <div className="row">
                  <div className="col-12">
                    <button
                      title={strings.toxDB_create_cancel}
                      className="btn btn-danger p-2 mb-1 full-width"
                      onClick={this.cancel}
                    >
                      {strings.toxDB_create_cancel}
                    </button>
                  </div>
                </div>
              )}
              {this.state.projectId && !hasChanges && !this.state.editing && (
                <div className="row">
                  <div className="col-12">
                    <button
                      title={strings.toxDB_projectOverview_download}
                      className="btn btn-info p-2 full-width"
                      onClick={this.fullDownload}
                    >
                      {strings.toxDB_projectOverview_download}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </form>
        </React.Fragment>
        {!!this.state.projectId && (
          <div className="fab-container">
            <Fab
              className="fab"
              aria-label={strings.toxDB_analysis_create}
              onClick={this.onCreateAnalyze}
            >
              <Icon className="fa fa-chart-bar" />
            </Fab>
          </div>
        )}
        {this.state.hijackComponent}
        <Prompt
          when={this.hasChanges()}
          message={() => strings.toxDB_create_warning}
        />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectView);

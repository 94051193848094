import React from "react";

import { Modes } from "../common/FilterPanel";
import FilterPanel from "../common/FilterPanel";

const FILTER_MODES: Modes = {
  title: [
    {
      type: "select",
      options: [
        "contains",
        "contains word",
        "does not contain",
        "does not contain word",
      ],
      values: [
        { word_mode: false, negated: false },
        { word_mode: true, negated: false },
        { word_mode: false, negated: true },
        { word_mode: true, negated: true },
      ].map((value) =>
        typeof value === "object" ? JSON.stringify(value) : value
      ),
    },
    {
      type: "text",
    },
  ],
  status: [
    {
      type: "select",
      options: ["ok", "error", "analyzing"],
    },
  ],
};

export const AnalysisFilterPanel = ({ ...props }) => (
  <FilterPanel {...props} modes={FILTER_MODES} />
);

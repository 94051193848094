import { Button, Card, CardActions, CardContent, CircularProgress, Link, Slide, TextField} from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { RouteComponentProps } from "react-router-dom"
import { FormWithLoadingSlider } from "../common/FormWithLoadingSlider"
import { setSnackbar } from "../common/simpleSnackbarSlice"
import getApiEndpoint from "../endpoint_configuration"
import { SERVER_FAILED_MSG } from "../ErrorMessages"
import { firebaseFunc, getFirebaseTenantAuth } from "./firebaseConfig"


  
export const AuthMgmt: React.FC<RouteComponentProps<{}>> = (props) => {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const [errorAlert, setErrorAlert] = React.useState("");
  const [successAlert, setSuccessAlert] = React.useState("");
  const [email, setEmail] = useState("");
  const [emailSet, isEmailSet] = useState(false);
  const [validPassword, isValidPassword] = useState(false);
  const [signUpSuccess, isSignUpSuccess] = useState(false);
  const [password, setPassword] = React.useState("");
  const [passwordConfirm, setPasswordConfirm] = React.useState("");
  const [loading, isLoading] = React.useState(false);
  const firebaseAuth = getFirebaseTenantAuth();
  const showErrorSnackbar = (message: string) =>
    dispatch(setSnackbar({ message, mode: "error" }));
  
    
  const validatePassword = () => {
    if (password.length < 6 && password.length !== 0){
      setSuccessAlert("");
      setErrorAlert("Password needs to be at least 6 characters long");
    }
    else if (passwordConfirm !== "" && password !== passwordConfirm) {
      setSuccessAlert("");
      setErrorAlert("Passwords don't match");
    } 
    else if (password.length !== 0 && passwordConfirm.length !== 0) {
      setSuccessAlert("Password valid");
      isValidPassword(true)
      setErrorAlert("");
    }
  };

  function signUpUser(){
    const url = new URL(window.location.href)
    const body = {
      email: email, 
      password: password, 
      tenantId: firebaseAuth.tenantId || "undefined", 
      tokenId: url.searchParams.get("token")
    }
    // Get user or sign in anonymously
    const getUser = () => {
      if (firebaseAuth.currentUser) {
        return Promise.resolve();
      } else {
        return firebaseAuth.signInAnonymously();
      }
    };
    
    getUser()
    .then(() => getApiEndpoint("user_signup_with_token")
      .setBodyData(JSON.stringify(body))
      .getFetchPromise()
      .then((response) => {
        if (response.ok) {
          setSuccessAlert("Sign up successful");
          isSignUpSuccess(true)
        } else {
          return response.json();
        }
        isLoading(false);
      })
      .then((response) => {
        if (response) {
          setErrorMessage(response.message);
        }
        isLoading(false);
      })
      .catch((error) => {
        console.error(error);
        showErrorSnackbar(SERVER_FAILED_MSG);
        isLoading(false);
      })
    );
  }
  
  useEffect(() => validatePassword(), [password, passwordConfirm]);

  useEffect(() => {
    const url = new URL(window.location.href)

    if (emailSet && validPassword){
      signUpUser()
    }
  }, [emailSet])

    return (
        <Card className={"mw-360 mx-auto mt-25vh"}>
         
        {!!emailSet && !errorMessage && (<Slide in={!!emailSet && !errorMessage && !signUpSuccess} direction="left">
            <div className="mx-auto my-5 mw-40px">
              <CircularProgress />
            </div>
          </Slide>)
        }
        {!errorMessage && !signUpSuccess && <Slide in={!errorMessage && !emailSet } direction="right" appear={false}>
          <div>
            <CardContent>
            <TextField
              type="email"
              name="email"
              label="Repeat email"
              fullWidth
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)} />
          
          </CardContent>
          {/* TODO: Make this into a component */}
          <CardContent>
            <TextField
              type="password"
              name="password"
              label="password"
              fullWidth
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <TextField
              label="confirm password"
              type="password"
              name="passwordConfirm"
              fullWidth
              required
              className="mt-4"
              value={passwordConfirm}
              helperText={errorMessage}
              error={!!errorMessage}
              onChange={(e) => setPasswordConfirm(e.target.value)}
            />
          </CardContent>
          <Card>
            <FormWithLoadingSlider
              loading={loading}
              errorMessage={errorAlert}
              successMessage={successAlert} 
              children={undefined}                
            />
          </Card>
          <CardActions>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={"ml-auto"}
              disabled={!email || !password || !passwordConfirm || !validPassword}
              onClick={() => isEmailSet(true)}
            >
              Sign up
            </Button>
          </CardActions>
          </div>
          </Slide>
        }
        {!!errorMessage &&  <Slide in={!!errorMessage} direction="left">
          <Alert severity="error">{errorMessage}</Alert>
        </Slide>}
        {!!signUpSuccess &&  <Slide in={!!signUpSuccess} direction="left">
          <Alert severity="success">Sign up successful. <Link href="/login">Click here to login.</Link></Alert>
        </Slide>}
        </Card>
  )
}
import React from "react";

import ToxPlotDataProvider from "./ToxPlotDataProvider";
import { strings } from "../localization";

import { InfiniteScroll } from "../common/InfiniteScroll";
import ThemedBackground from "../common/ThemedBackground";
import { setSnackbar, clearSnackbar } from "../common/simpleSnackbarSlice";
import { connect } from "react-redux";
import DeleteFAB from "../common/DeleteFAB";
import DownloadFAB from "../common/DownloadFAB";
import { clear } from "./analysisSlice";
import { AnalysisFilterPanel } from "./AnalysisFilterPanel";

const mapState = (store) => {
  return {
    analysisSelection: store.analyses.selection,
  };
};
function mapDispatch(dispatch) {
  return {
    showErrorSnackbar: (message: string) =>
      dispatch(setSnackbar({ message, mode: "error" })),

    showInfoSnackbar: (message: string) =>
      dispatch(setSnackbar({ message, mode: "info" })),

    clearSnackbar: () => dispatch(clearSnackbar()),
    clearAnalysisSelection: () => dispatch(clear()),
  };
}
class ToxPlotRenderer extends ThemedBackground {
  dataProvider: ToxPlotDataProvider;
  constructor(props) {
    super(props);

    this.dataProvider = new ToxPlotDataProvider(
      this.props.showErrorSnackbar,
      this
    );
    this.renderEmpty = this.renderEmpty.bind(this);
    this.refreshData = this.refreshData.bind(this);
  }

  renderEmpty() {
    if (!this.dataProvider.hasFetched) {
      return <div />;
    }

    return (
      <div className="col-12 text-center">
        <div className="alert alert-primary w-100" role="alert">
          {strings.toxDB_analysisOverview_unavailable}
        </div>
      </div>
    );
  }

  componentWillMount() {
    this.props.clearAnalysisSelection();
  }

  static compareDates(dateA: Date, dateB: Date) {
    if (dateA.getTime() === dateB.getTime()) return 0;
    return dateA > dateB ? -1 : 1;
  }

  static sortFunction(
    objectA: { enqueued: Date },
    objectB: { enqueued: Date }
  ) {
    // Return vals:
    // -1: a, b
    //  0: b, a || a, b
    //  1: b, a
    return ToxPlotRenderer.compareDates(
      new Date(objectA.enqueued),
      new Date(objectB.enqueued)
    );
  }
  refreshData(removedObjects) {
    const infiniteScroll = this.dataProvider.parentInfiniteScroll;
    const loadedData = infiniteScroll.getLoadedData();
    infiniteScroll.setLoadedData(
      loadedData.filter((data) => removedObjects.indexOf(data.id) === -1)
    );
    this.props.clearAnalysisSelection();
  }

  renderBody(themeData) {
    return (
      <React.Fragment>
        <div className="fab-container-top">
          <DownloadFAB
            resourceType={"analysis"}
            selection={this.props.analysisSelection}
            refreshData={this.props.clearAnalysisSelection}
          />
          <DeleteFAB
            resourceType={"analysis"}
            selection={this.props.analysisSelection}
            refreshData={this.refreshData}
          />
        </div>
        <AnalysisFilterPanel
          registerFilters={this.dataProvider.applyFilters.bind(
            this.dataProvider
          )}
        />

        <InfiniteScroll
          autorefresh={true}
          dataProvider={this.dataProvider}
          renderEmpty={this.renderEmpty}
          sortFunction={ToxPlotRenderer.sortFunction}
        />
      </React.Fragment>
    );
  }
}

export default connect(mapState, mapDispatch)(ToxPlotRenderer);

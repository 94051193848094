import React from "react";
import { Fab, Icon, Grow } from "@material-ui/core";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { strings } from "../localization";

interface AnalyzeFABProps extends RouteComponentProps<any> {
  selection: string[];
}

const AnalyzeFAB = (props: AnalyzeFABProps) => {
  const onClick = () => props.history.push("/analysis/create");

  return (
    <Grow in={props.selection.length > 0} unmountOnExit>
      <Fab
        color="primary"
        aria-label={strings.toxDB_projectOverview_analyze}
        onClick={onClick}
      >
        <Icon className="fa fa-chart-bar" />
      </Fab>
    </Grow>
  );
};

export default withRouter(AnalyzeFAB);

import base64 from "base64-js";
import swal from 'sweetalert2'
import platform from 'platform'
import getApiEndpoint from "../src/endpoint_configuration";
import { strings } from "./localization"


export function readBlobAsText(blob) {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = () => resolve(fileReader.result);
        fileReader.onerror = reject;
        fileReader.readAsText(blob);
    });
}

export function getPixels(px) {
    return parseFloat(px.substring(0, px.length - 2));
}

const CRYPT = window.crypto || window.msCrypto;
const UNSAFE_ALPHABET = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';


export function generateState() {
    if (!CRYPT) {
        console.warn("Crypto not available, generating crypto unsafe state");
        let state = '';
        for (let i = 0; i < 20; i++) {
            state += UNSAFE_ALPHABET[Math.floor(Math.random() * UNSAFE_ALPHABET.length)];
        }
        return state;
    }
    const data = new Uint8Array(20);
    CRYPT.getRandomValues(data);
    return encodeURIComponent(base64.fromByteArray(data));
}

export function hashCode(string) {
    let hashCode = 0;
    if (string.length === 0)
        return hashCode;
    string.split("").forEach(character => {
        hashCode = ((hashCode << 5) - hashCode) + character.charCodeAt(0);
        hashCode = hashCode & hashCode;
    });
    return hashCode;
}

export function masterHashCode(prime, ...values) {
    return masterRawHashCode(prime, ...values.map(hashCode));
}

export function masterRawHashCode(prime, ...values) {
    let hashCode = 1;
    values.forEach(value => {
        hashCode = hashCode + prime * value;
    });
    return hashCode;
}

/**
 * Only checks whether the arrays are equal on the first level
 * @param  {...any} arrays
 */
export function arrayEquals(...arrays) {
    if (arrays.length === 0)
        return false;
    // First check the lengths
    const lengths = arrays.map(array => array.length);
    if (!lengths.every(length => length === lengths[0])) {
        return false;
    }
    // Check the actual items
    const indices = arrays[0].map((item, index) => index);
    return indices.every(index => {
        const compareValue = arrays[index];
        return arrays.every(array => array[index] === compareValue);
    });
}

export function feedback() {
    swal.fire({
        'type': 'info',
        'title': strings.user_feedback,
        'text': strings.user_feedback_sub,
        'input': 'text',
        'showCancelButton': true,
        'confirmButtonText': strings.user_feedback_send,
        'showLoaderOnConfirm': true,
        'preConfirm': (text) => {
            getApiEndpoint("send_user_feedback")
            .setBodyData(JSON.stringify("Feedback\n" + text + '\n\nSystem Information:\n' + platform))
            .getFetchPromise()
        }
    })
}

export function bug() {
    swal.fire({
        'type': 'error',
        'title': strings.user_bug,
        'text': strings.user_bug_sub,
        'input': 'text',
        'showCancelButton': true,
        'confirmButtonText': strings.user_feedback_send,
        'preConfirm': (text) => {
            getApiEndpoint("send_user_feedback")
            .setBodyData(JSON.stringify("Bug\n" + text + '\n\nSystem Information:\n' + platform))
            .getFetchPromise()
        }
    })
}

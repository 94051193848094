import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: [],

  reducers: {
    set: (state, action: PayloadAction<any[]>) => (state = action.payload),
    clear: (state) => (state = []),
    add: (state, action: PayloadAction<any>) => {
      state.push(action.payload);
    },
    remove: (state, action: PayloadAction<string>) => {
      return state.filter((item) => item.uid != action.payload);
    },
  },
});

export const { set, add, clear, remove } = userSlice.actions;
export default userSlice.reducer;

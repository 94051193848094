import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type LoginProperties = {
  registered: boolean;
  migrated: boolean;
};
const authSlice = createSlice({
  name: "authStatus",
  initialState: {
    status: { loggedIn: false, waiting: false },
  },

  reducers: {
    login: (state) => {
      state.status = { loggedIn: true, waiting: false };
      return state;
    },
    logout: (state) => {
      state.status = { loggedIn: false, waiting: false };
      return state;
    },
    wait: (state) => {
      state.status = { loggedIn: state.status.loggedIn, waiting: true };
      return state;
    },
  },
});

export const { login, logout, wait } = authSlice.actions;
export default authSlice.reducer;

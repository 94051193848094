import { SERVER_FAILED_MSG } from "./ErrorMessages";

import ConfigurationError from "./common/ConfigurationError";
import NullPointerError from "./common/NullPointerError";
import firebase from "firebase/app";

const AVAILABLE_DATA_TYPES = ["int", "float", "string", "boolean"];

const HTTP_METHODS = ["GET", "POST", "PUT", "DELETE", "HEAD"];

// Empty means current domain
export const BASE_ENDPOINT =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_TOXYS_SERVER
    : "http://localhost:8000";
const PUBLIC_BASE_URL =
  process.env.NODE_ENV === "production"
    ? process.env.PUBLIC_URL
    : "http://localhost:3000";
export const PUBLIC_URL = `${PUBLIC_BASE_URL}${
  PUBLIC_BASE_URL.endsWith("/") ? "" : "/"
}`;

let PARENT_COMPONENT: null = null;

export function bindComponent(component) {
  PARENT_COMPONENT = component;
}

/**
 * A class which parses a configuration for a query parameter, validates
 * the associated value and converts the value to an actual URI query parameter
 */
class QueryParameter {
  config: {
    type: string;
    required: boolean;
    // In case of float/integer
    min: number;
    max: number;
  };
  name: string;
  /**
   * Creates the QueryInput with a configuration. Configuration components are:
   * - required: just this string indicates the parameter is required
   * - type: the type of the query parameter (int, float, string or boolean)
   * - min: the minimum value when the type is int or float
   * - max: the maximum value when the type is int or float
   *
   * @param name The name of query parameter
   * @param configurationString A string containing configuration components
   * concatenated by a semicolon.
   */
  constructor(name: string, configurationString: string) {
    this.name = name;
    this.config = QueryParameter.parseConfiguration(configurationString);
  }

  /**
   * Parses a configuration string to an actual configuration object
   * @param configString The configuration string
   * @returns {{type: string, required: boolean, min: number, max: number}}
   */
  static parseConfiguration(configString: string) {
    const dataPoints = configString.toLowerCase().split(";");
    const baseConfiguration = {
      type: "string",
      required: false,
      // In case of float/integer
      min: Number.MIN_SAFE_INTEGER,
      max: Number.MAX_SAFE_INTEGER,
    };

    dataPoints.forEach((dataPoint) => {
      if (AVAILABLE_DATA_TYPES.indexOf(dataPoint) !== -1) {
        baseConfiguration.type = dataPoint;
      } else if (dataPoint === "required") {
        baseConfiguration.required = true;
      } else {
        // Check for min or max
        const minResult = /^min=(\d+\.?\d*)$/.exec(dataPoint);
        if (minResult) {
          baseConfiguration.min = Number.parseFloat(minResult[1]);
          return;
        }
        const maxResult = /^max=(\d+\.?\d*)$/.exec(dataPoint);
        if (maxResult) {
          baseConfiguration.max = Number.parseFloat(minResult[1]);
        }
      }
    });
    return baseConfiguration;
  }

  /**
   * Whether this QueryParameter is required
   * @returns {boolean} A boolean indicating whether this QueryInput is
   * required.
   */
  isRequired() {
    return this.config.required;
  }

  /**
   * Retrieves the name of this query parameter
   * @returns {string} A string representing the name of this QueryParameter
   */
  getName() {
    return this.name;
  }

  /**
   * Retrieves a copy of the current configuration
   * @returns {Object} An object containing the type, required, min and max keys.
   */
  getConfiguration() {
    return Object.assign({}, this.config);
  }

  /**
   * Validates the configuration with the value
   * @param value The value to configure
   * @returns {boolean} Whether the validation had an issue (false) or not (true)
   */
  validate(value) {
    if (value === null && !this.isRequired()) return true;
    if (typeof value === this.config.type) {
      return true;
    } else {
      // Remaining type is either float or int
      if (
        typeof value === "number" &&
        ((this.config.type === "int") === Number.isInteger(value) ||
          (this.config.type === "float") !== Number.isInteger(value))
      ) {
        return value >= this.config.min && value <= this.config.max;
      }
    }
    return false;
  }

  /**
   * Converts the value to a full blown query parameter
   * @param value The value to set for this QueryParameter
   * @returns {string} The URL ready query parameter
   */
  toURIParameter(value: string | number | boolean) {
    return `${this.name}=${encodeURIComponent(value)}`;
  }

  getValue(value: string | number | boolean) {
    return encodeURIComponent(value);
  }
}

export default class ApiEndpoint {
  urlParameters: { [x: string]: string | null } = {};
  endpoint: string;
  httpMethod: string;
  emptyAfterFetch: boolean = true;
  needsAuthentication: boolean;
  requiresBody: boolean;
  headers: { [x: string]: string } = {};
  queryParameterObjects: QueryParameter[] = [];
  queryParameters: { [x: string]: string | number | boolean | null } = {};
  body: string | null = null;

  /**
   * Creates a new ApiEndpoint which can be used to communicate with specific data
   *
   * @param endpoint The endpoint URI (in a string)
   * @param httpMethod The associated http method
   * @param queryParameters A key - value pair of query parameters and configuration
   * string as defined in the QueryParameter class.
   * @param requiresBody Whether this request should require a body
   * @param requiresAuthentication Whether this request requires authentication
   */
  constructor(
    endpoint: string,
    httpMethod: string,
    queryParameters: ArrayLike<string | number | boolean | null>,
    requiresBody: any,
    requiresAuthentication: any
  ) {
    this.endpoint = endpoint;
    this.httpMethod = httpMethod.toUpperCase();
    this.requiresBody = !!requiresBody;
    this.needsAuthentication = Boolean(requiresAuthentication);

    // Apply configuration validation
    if (HTTP_METHODS.indexOf(this.httpMethod) === -1) {
      throw new ConfigurationError(
        "HTTP Method " + this.httpMethod + " not implemented"
      );
    }

    // Prepare "required" parameters

    // Convert the query parameters to actual QueryParameter objects
    Object.entries(queryParameters).forEach((keyValuePair) => {
      this.queryParameterObjects.push(
        new QueryParameter(keyValuePair[0], keyValuePair[1])
      );
      this.queryParameters[keyValuePair[0]] = null;
    });

    this.endpoint
      .split("/")
      .filter(
        (urlComponent) =>
          urlComponent.startsWith("{") && urlComponent.endsWith("}")
      )
      .forEach(
        (urlComponent) =>
          (this.urlParameters[
            urlComponent.substr(1, urlComponent.length - 2)
          ] = null)
      );
  }

  /**
   * Retrieves the URL parameters fetched from the URL. This array is mutable,
   * but will not affect the actual configuration of this object.
   */
  getUrlParameters() {
    return Object.keys(this.urlParameters);
  }

  /**
   * Retrieves the QueryParameter objects associated with this endpoint. This array
   * is mutable, but will not affect the actual configuration of this object.
   */
  getQueryParameters() {
    return this.queryParameterObjects.map((object) => object.getName());
  }

  /**
   * Sets whether the data should be emptied after the endpoint is fetched
   * @param newValue A boolean indicating whether this ApiEndpoint should be
   * emptied.
   */
  setEmptyOnExecute(newValue: any) {
    this.emptyAfterFetch = Boolean(newValue);
  }

  /**
   * Sets the body data of the request
   * @param data The data to set as body
   */
  setBodyData(data: string | null) {
    if (this.httpMethod === "GET") {
      throw new ConfigurationError("A GET request cannot have a body");
    }
    if (data != null) {
      this.body = data;
    } else {
      console.debug("Body data was null or undefined");
    }
    return this;
  }

  /**
   * Binds an URL parameter to this endpoint. When the name has not been
   * retrieved from the URL, this will raise a ConfigurationError. When the
   * value is null or undefined a NullPointerError will be thrown.
   *
   * @param name The name of the parameter
   * @param value The value of the parameter
   * @returns {ApiEndpoint} The current ApiEndpoint object which can be used
   * for chaining.
   */
  bindUrlParameter(name: string, value: string | null) {
    if (Object.keys(this.urlParameters).indexOf(name) === -1) {
      throw new ConfigurationError(
        "The URL parameter " + name + " is unavailable"
      );
    }
    if (value == null) {
      throw new NullPointerError();
    }
    this.urlParameters[name] = value;
    return this;
  }

  /**
   * Binds a query parameter to this endpoint. When the name has not been
   * retrieved from the constructor, this will throw a ConfigurationError.
   * When the value is empty (null or undefined), the query parameter will
   * not be set. This is printed to console using the debug function.
   *
   * @param name The name of the query parameter
   * @param value The value of the parameter
   * @returns {ApiEndpoint} The current ApiEndpoint object which can be used
   * for chaining.
   */
  bindQueryParameter(name: string, value: string | number | boolean | null) {
    if (
      !this.queryParameterObjects.some(
        (parameter) => parameter.getName() === name
      )
    ) {
      throw new ConfigurationError(
        `The query parameter ${name} is unavailable`
      );
    }
    if (value == null) {
      console.debug(`Query parameter ${name} is empty, omitting value`);
      return this;
    }
    this.queryParameters[name] = value;
    return this;
  }

  /**
   * Validates the configuration for this API object. This validates whether
   * the named URL parameters are set. This throws errors when the configuration
   * is not properly configured by the values.
   */
  validateConfiguration() {
    if (this.requiresBody && this.body === null) {
      throw new ConfigurationError("Missing body");
    }

    // Check whether all URL components are available
    const unfilledUrlParameters = [];
    this.getUrlParameters().forEach((urlParameter) => {
      if (this.urlParameters[urlParameter] == null) {
        unfilledUrlParameters.push(urlParameter);
      }
    });

    if (unfilledUrlParameters.length > 0) {
      throw new ConfigurationError(
        "Missing URL parameters: " + unfilledUrlParameters.join(", ")
      );
    }

    // Check whether all query parameter are available and valid
    const parameterMapping = {};

    const invalidParameters = [];
    const unfilledRequiredParameters = [];
    this.queryParameterObjects.forEach((queryParameter) => {
      const name = queryParameter.getName();
      parameterMapping[name] = queryParameter;
      if (this.queryParameters[name] === null && queryParameter.isRequired()) {
        unfilledRequiredParameters.push(name);
        return;
      }
      if (!queryParameter.validate(this.queryParameters[name])) {
        invalidParameters.push(name);
      } else {
      }
    });

    if (unfilledRequiredParameters.length > 0) {
      throw new ConfigurationError(
        "Missing required query parameters: " +
          unfilledRequiredParameters.join(", ")
      );
    }
    if (invalidParameters.length > 0) {
      throw new ConfigurationError(
        "Invalidated parameters: " + invalidParameters.join(", ")
      );
    }
    return parameterMapping;
  }

  /**
   * Empties the query parameters and body set on this endpoint
   */
  emptyEndpoint() {
    Object.keys(this.urlParameters).forEach((key) => {
      this.urlParameters[key] = null;
    });
    this.body = null;
  }

  generateUrl(withoutDomain) {
    let url = withoutDomain ? "" : BASE_ENDPOINT;
    if (!url.endsWith("/")) url += "/";
    if (this.endpoint.startsWith("/")) {
      url += this.endpoint.substring(1);
    } else {
      url += this.endpoint;
    }
    Object.entries(this.urlParameters).forEach((keyValuePair) => {
      url = url.replace(`{${keyValuePair[0]}}`, keyValuePair[1]);
    });
    return url;
  }

  generateQueryObject() {
    const queryParameterMapping = this.validateConfiguration();

    const queryObject = {};
    Object.entries(this.queryParameters).forEach(([name, value]) => {
      if (value !== null) {
        queryObject[name] = queryParameterMapping[name].getValue(value);
      }
    });
    return queryObject;
  }

  /**
   * Get the promise which contains the fetch request
   * @param overrideAttributes An object to override the fetch object
   * @returns {Promise<Response>}
   */
  // TODO: error -like 4xx codes - should be available in catch, no then
  getFetchPromise(overrideAttributes) {
    // Build the url
    let url = this.generateUrl();

    // Add the query parameters
    Object.entries(this.generateQueryObject()).forEach((keyValuePair, idx) => {
      url += idx === 0 ? "?" : "&";
      url += `${keyValuePair[0]}=${encodeURIComponent(keyValuePair[1])}`;
    });

    // Create the promise
    let fetchObject = {
      method: this.httpMethod,
      headers: this.headers,
    };
    if (this.body !== null) fetchObject.body = this.body;
    fetchObject = Object.assign(fetchObject, overrideAttributes || {});

    // Empty this endpoint if configured to do so
    if (this.emptyAfterFetch) this.emptyEndpoint();
    if (this.needsAuthentication) {
      return firebase
        .auth()
        .currentUser.getIdToken()
        .then((idToken) => {
          fetchObject["headers"]["Authorization"] = idToken;
          return fetch(url, fetchObject);
        });
    }
    return fetch(url, fetchObject);
  }
}

export class BatchRequest {
  authenticatedRequest: boolean = false;
  silenceErrors: boolean;
  rejects: Promise[];
  resolves: Promise[];
  endpoints: ApiEndpoint[];
  constructor(silenceErrors: boolean) {
    this.endpoints = [];
    this.resolves = [];
    this.rejects = [];
    this.silenceErrors = !!silenceErrors;
  }

  addEndpoint(endpoint) {
    this.endpoints.push(endpoint);
    const _this = this;
    return new Promise((resolve, reject) => {
      _this.resolves.push(resolve);
      _this.rejects.push(reject);
    });
  }

  requiresAuthentication() {
    this.authenticatedRequest = true;
    return this;
  }

  isEmpty() {
    return this.endpoints.length === 0;
  }

  fetch(callback: CallableFunction) {
    const body = JSON.stringify(
      this.endpoints.map((endpoint) => {
        const batchObject = {
          url: endpoint.generateUrl(true),
          type: endpoint.httpMethod,
        };

        const queryObject = endpoint.generateQueryObject();
        if (Object.keys(queryObject).length > 0) {
          batchObject.GET = queryObject;
        }
        if (endpoint.body) {
          batchObject.body = endpoint.body;
        } else if (endpoint.requiresBody) {
          throw new ConfigurationError("Body is required!");
        }
        if (endpoint.emptyAfterFetch) endpoint.emptyEndpoint();
        return batchObject;
      })
    );

    callback = callback || ((a, b) => 1);
    const fetchObject = {
      method: "POST",
      body: body,
      headers: {},
    };

    if (this.authenticatedRequest) {
      firebase
        .auth()
        .currentUser.getIdToken()
        .then((idToken) => {
          fetchObject["headers"]["Authorization"] = idToken;
          this.doRequest(fetchObject, callback);
        });
    } else {
      this.doRequest(fetchObject, callback);
    }
  }

  doRequest(fetchObject: RequestInit | undefined, callback: CallableFunction) {
    return fetch(`${BASE_ENDPOINT}/api/batch/`, fetchObject)
      .then((response) => {
        if (!response.ok && response.status === 401) {
          // TODO: Redirect to login
          return window.history.pushState("", "Login", "/login");
        }
        return new Promise((resolve, reject) => resolve(response));
      })
      .then((...parameters) => {
        if (parameters.length === 0) {
          return fetch(`${BASE_ENDPOINT}/batch/`, fetchObject);
        }
        return new Promise((resolve, reject) => resolve(parameters[0]));
      })
      .then((response) => {
        if (!response.ok) {
          return Promise.reject(response);
        }
        return response.json();
      })
      .then((jsonData) => {
        jsonData.results.forEach((data, idx) => {
          if (data.status === "ok" || this.silenceErrors) {
            this.resolves[idx](data);
          } else {
            this.rejects[idx](data);
          }
        });
        return callback(true, jsonData);
      })
      .catch((...args) => callback(false, ...args));
  }
}

export function defaultJsonResponse(
  snackbarShow,
  withOk = true,
  errorCallback = null
) {
  return function (response) {
    if (!response.ok) {
      if (!(errorCallback && errorCallback(response))) {
        snackbarShow(SERVER_FAILED_MSG);
      }
      return;
    }

    const promise = response.json();
    if (withOk) {
      promise.then((jsonData) => {
        if (jsonData.status === "error") {
          snackbarShow(SERVER_FAILED_MSG);
          return Promise.reject("Invalid JSON");
        }
        return new Promise((resolve, reject) => resolve(jsonData));
      });
    }
    return promise;
  };
}

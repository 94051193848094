import { CircularProgress, Collapse, Slide } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";

export const FormWithLoadingSlider: React.FC<{
  loading: boolean;
  children: any;
  errorMessage: string;
  successMessage: string;
}> = (props) => {
  return (
    <>
      {!!props.loading && (
        <Slide in={!!props.loading} direction="left">
          <div className="mx-auto my-5 mw-40px">
            <CircularProgress />
          </div>
        </Slide>
      )}
      {!props.errorMessage && !props.successMessage && (
        <Slide
          in={!props.errorMessage && !props.loading}
          direction="right"
          appear={false}
        >
          <Collapse in={!props.loading && !props.errorMessage}>
            <div>{props.children}</div>
          </Collapse>
        </Slide>
      )}
      {!!props.errorMessage && (
        <Slide in={!!props.errorMessage} direction="left">
          <Alert severity="error">{props.errorMessage}</Alert>
        </Slide>
      )}
      {!props.errorMessage && !!props.successMessage && (
        <Slide in={!!props.successMessage} direction="left">
          <Alert severity="success">{props.successMessage}</Alert>
        </Slide>
      )}
    </>
  );
};

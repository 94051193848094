import React from "react";

import Annotation from "./Annotation";

import { defaultJsonResponse } from "../ApiEndpoint";
import getApiEndpoint from "../endpoint_configuration";

import { DataProvider } from "../common/InfiniteScroll";

const MAX_ITEMS = 25;

export default class AnnotationDataProvider extends DataProvider {
  constructor(snackbarShow, afterRetrieve, annotationSelection) {
    super();

    this.snackbarShow = snackbarShow;

    this.dataBuffer = [];

    this.fetchingData = false;
    this.hasFetched = false;

    this.afterRetrieve = afterRetrieve;

    this.annotationSelection = annotationSelection;
  }

  bindInfiniteScroll(infiniteScroll) {
    super.bindInfiniteScroll(infiniteScroll);
  }

  fetchAsyncData() {
    this.fetchingData = true;
    this.dataBuffer = [];

    const apiEndpoint = getApiEndpoint(
      this.filters.length === 0 ? "get_projects" : "get_filtered_projects"
    )
      .bindQueryParameter("offset", this.offset)
      .bindQueryParameter("max_items", MAX_ITEMS);

    if (this.filters.length !== 0)
      apiEndpoint.setBodyData(JSON.stringify(this.filters));

    const snackbarShow = this.snackbarShow;
    apiEndpoint
      .getFetchPromise()
      .catch(() => {
        this.hasFetched = true;
        this.fetchingData = false;
        // TODO: replace error this.snackbarShow(INTERNET_ERROR_MSG);
      })
      .then(defaultJsonResponse(snackbarShow))
      .then((jsonData) => {
        this.hasFetched = true;
        this.fetchingData = false;
        this.offset += MAX_ITEMS;

        this.dataBuffer = jsonData.data;
        if (typeof this.afterRetrieve === "function") {
          this.afterRetrieve();
        }
      })
      .catch(console.debug);
  }

  poll() {
    return this.fetchingData;
  }

  getData() {
    return this.dataBuffer;
  }

  renderComponent(dataObject) {
    return (
      <Annotation
        data={dataObject}
        key={dataObject.id}
        checkboxCollection={this.annotationSelection}
      />
    );
  }
}

import authReducer from "./auth/authSlice";
import simpleSnackbarSlice from "./common/simpleSnackbarSlice";
import filterSlice from "./common/filterSlice";
import permissionSlice from "./common/permissionSlice";
import annotationSlice from "./annotation/annotationSlice";
import analysisSlice from "./toxplot_analysis/analysisSlice";
import userSlice from "./users/userSlice";

import { combineReducers } from "@reduxjs/toolkit";

const rootReducer = combineReducers({
  auth: authReducer,
  snackbar: simpleSnackbarSlice,
  permissions: permissionSlice,
  annotations: annotationSlice,
  analyses: analysisSlice,
  users: userSlice,
  filters: filterSlice,
});
export default rootReducer;

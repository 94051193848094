import Abstract from "../../common/Abstract";
import getApiEndpoint from "../../endpoint_configuration";

class FileEntryHandler {
  iconConfig = {
    view: true,
    download: true,
  };

  constructor(hijackComponent) {
    this.hijackComponent = hijackComponent;
  }

  requestFileSize(callback) {
    Abstract.method();
  }

  requestFileContents(callback) {
    Abstract.method();
  }

  viewFile(getComponent) {
    const _this = this;
    const close = () => _this.hijackComponent(null);

    this.hijackComponent(getComponent(null, close), () => {
      _this.requestFileContents((blob) => {
        _this.hijackComponent(getComponent(blob, close));
      });
    });
  }
}

export class LocalFileEntryHandler extends FileEntryHandler {
  constructor(hijackComponent, fileObject) {
    super(hijackComponent);
    this.fileObject = fileObject;
  }

  requestFileSize(callback) {
    callback(this.fileObject.size);
  }

  requestFileContents(callback) {
    callback(this.fileObject);
  }
}

export class RemoteFileEntryHandler extends FileEntryHandler {
  constructor(hijackComponent, endpoint, urlParameters, queryParameters, body) {
    super(hijackComponent);

    this.urlParameters = urlParameters || {};
    this.queryParameters = queryParameters || {};
    this.body = body || null;
    this.callbacks = [];

    this.fileEndpoint = getApiEndpoint(endpoint);
    this.fileHeadEndpoint = getApiEndpoint(endpoint + "_head");

    this.cachedFileSize = null;
    this.cachedFileContents = null;
  }

  getFilledEndpoint(endpoint) {
    Object.keys(this.urlParameters).forEach((key) => {
      endpoint = endpoint.bindUrlParameter(key, this.urlParameters[key]);
    });
    Object.keys(this.queryParameters).forEach((key) => {
      endpoint = endpoint.bindQueryParameter(key, this.queryParameters[key]);
    });
    if (this.body) {
      endpoint = endpoint.setBodyData(this.body);
    }
    return endpoint;
  }

  setFileSize(fileSize) {
    this.cachedFileSize = fileSize;
    this.callbacks.forEach((callback) => callback(fileSize));
    this.callbacks = [];
  }

  requestFileSize(callback) {
    if (this.cachedFileSize !== null) {
      callback(this.cachedFileSize);
    } else {
      this.callbacks.push(callback);
    }
  }

  requestFileContents(callback) {
    if (this.cachedFileContents) {
      callback(this.cachedFileContents);
    } else {
      const _this = this;
      this.getFilledEndpoint(this.fileEndpoint)
        .getFetchPromise()
        // TODO: add display error
        //.catch(() => this.notificationManager.current.show(INTERNET_ERROR_MSG))
        .then((response) => {
          if (!response.ok) {
            // TODO: add display error

            // this.notificationManager.current.show(SERVER_FAILED_MSG);
            return;
          }
          return response.blob();
        })
        .then((blob) => {
          _this.cachedFileContents = blob;
          callback(blob);
        });
    }
  }
}

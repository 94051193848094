import { masterHashCode } from "../../../utils";
import { strings } from "../../../localization"

export const ROW_REGEX = /^([A-Z]+)(\d+)$/;


export default class PlateConfiguration {

    constructor(alphabet) {
        this._alphabet = alphabet;
        this._concentrations = [];
        this._treatments = [];
        this._units = [];

        // Fill the arrays with empty values
        for (let i = 0; i < alphabet.getWellCount(); i++) {
            this._concentrations.push("");
            this._treatments.push("");
        }
    }

    setConcentrations(concentrations) {
        this._concentrations = concentrations.slice(0, this._alphabet.getWellCount());
    }

    setTreatments(treatments) {
        this._treatments = treatments.slice(0, this._alphabet.getWellCount());
    }

    setUnits(units) {
        this._units = units.slice(0, this._alphabet.getWellCount());
    }

    getDistinctTreatments() {
        const treatments = new Set();
        for (let treatment of this._treatments) {
            if (treatment.length !== 0)
                treatments.add(treatment);
        }
        return treatments;
    }

    validate() {
        const bothEmpty = [];
        for (let i = 0; i < this._alphabet.getWellCount(); i++) {
            const concentrationEmpty = this._concentrations[i].length === 0;
            const treatmentEmpty = this._treatments[i].length === 0;

            if (concentrationEmpty !== treatmentEmpty) {
                throw new Error(strings.toxDB_create_annotation_emptyPlateFields);
            }
            bothEmpty.push(concentrationEmpty);
        }

        if (bothEmpty.every(value => value)) {
            throw new Error(strings.toxDB_create_annotation_emptyPlate);
        }
    }

    buildForWell(well) {
        const index = (this._alphabet.wellToIndex(well) - 1) % this._alphabet.getWellCount();
        return `${this._treatments[index]},${this._concentrations[index]},${this._units[index]}`;
    }

    hashCode() {
        return masterHashCode(31, ...this._concentrations, ...this._treatments, ...this._units);
    }
}

import React from "react";
import { Link } from "react-router-dom";

import CardView from "../common/CardView";
import { strings } from "../localization";
import { setSnackbar, clearSnackbar } from "../common/simpleSnackbarSlice";
import { remove, add } from "./analysisSlice";
import { connect } from "react-redux";
import { RootState, AppDispatch } from "../store";

export const FILTER_PARAMETERS = {
  clustering: true,
};

export function parameterFilter(key) {
  return !FILTER_PARAMETERS[key];
}

export function formatTimeDate(timeDate) {
  return new Date(timeDate).toLocaleString();
}

export function getValueRepresentation(value) {
  if (value === null) return "null";
  if (Array.isArray(value)) {
    return value.join(", ");
  }
  return value.toString();
}
const mapState = (store: RootState) => {
  return {
    analysisSelection: store.analyses.selection,
  };
};
function mapDispatch(dispatch: AppDispatch) {
  return {
    showInfoSnackbar: (message: string) =>
      dispatch(setSnackbar({ payload: { message, mode: "info" } })),
    showErrorSnackbar: (message: string) =>
      dispatch(setSnackbar({ payload: { message, mode: "error" } })),
    hideSnackbar: () => dispatch(clearSnackbar()),
    removeFromSelection: (object) => dispatch(remove(object)),
    addToSelection: (object) => dispatch(add(object)),
  };
}
class ToxPlotAnalysis extends CardView {
  constructor(props) {
    super(props);

    this.state.showProjects = true;
    this.state.analysis_status = this.props.data.analysis_status;

    if (this.props.analysisSelection.indexOf(this.props.data.id) > -1) {
      this.state.checked = true;
      // TODO: Add FAB
    }

    this.toggleProjects = this.toggleProjects.bind(this);
  }

  calcEstimatedHeight() {
    return 15 + Object.entries(this.props.data.analysis_parameters).length;
  }

  onToggleCheckbox(event) {
    const value = event.target.checked;
    this.setState({ checked: value, showDropdown: false });
    if (value) {
      this.props.addToSelection(this.props.data.id);
    } else {
      this.props.removeFromSelection(this.props.data.id);
    }
  }

  renderCardHeader(themeData) {
    const badgeType =
      this.state.analysis_status === "ok"
        ? "success"
        : this.state.analysis_status === "error"
        ? "danger"
        : "warning";

    return (
      <div className="col-9 text-truncate">
        <div className="row" title={this.props.data.name || this.props.data.id}>
          <div className="col-12">
            <h4 className="text-truncate">
              {this.props.data.name || this.props.data.id}
            </h4>
          </div>
        </div>
        <div className="row small">
          <div className="col-12">
            <span>
              {strings.toxDB_analysisOverview_enqueued}{" "}
              {formatTimeDate(this.props.data.enqueued)}
            </span>
          </div>
          {this.props.data.result_received && (
            <div className="col-12">
              <span>
                {strings.toxDB_analysisOverview_received}{" "}
                {formatTimeDate(this.props.data.result_received)}
              </span>
            </div>
          )}
        </div>
        <div className="row">
          <div className="col">
            <ul className="list-inline mb-0 hidden">
              <li className={"list-inline-item badge badge-" + badgeType}>
                {this.state.analysis_status}
              </li>
              <li
                className="list-inline-item badge badge-secondary pointer"
                onClick={this.toggleProjects}
              >
                {strings.toxDB_analysisOverview_show}{" "}
                {this.state.showProjects
                  ? strings.toxDB_analysisOverview_showParameters
                  : strings.toxDB_analysisOverview_showProjects}
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }

  renderCardBody(themeData) {
    if (this.state.showProjects) {
      const keys = Object.keys(this.props.data.projects);
      return keys.map((project, idx) => (
        <div
          className="col-6 col-md-4 text-truncate"
          title={project}
          key={idx + (this.state.showProjects ? keys.length : 0)}
        >
          <span>{project}</span>
        </div>
      ));
    }

    const borderStyle = { borderTop: "1px solid bg-dark" };
    return Object.entries(this.props.data.analysis_parameters)
      .filter(([key, value]) => parameterFilter(key) && value !== null)
      .map(([key, value], idx) => (
        <React.Fragment key={key}>
          <div
            className="col-6"
            title={key}
            style={idx !== 0 ? borderStyle : {}}
          >
            <span>{key}</span>
          </div>
          <div
            className="col-6"
            title={getValueRepresentation(value)}
            style={idx !== 0 ? borderStyle : {}}
          >
            <span>{getValueRepresentation(value)}</span>
          </div>
        </React.Fragment>
      ));
  }

  toggleProjects() {
    this.setState(
      { showProjects: !this.state.showProjects },
      this.setShowHoverBadge
    );
  }

  renderDropdownMenuItems() {
    return (
      <Link
        className="dropdown-item"
        to={`/analysis/show/${this.props.data.id}`}
      >
        {strings.toxDB_analysisOverview_viewAnalysis}
      </Link>
    );
  }
}

export default connect(mapState, mapDispatch)(ToxPlotAnalysis);

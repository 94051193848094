import React, { Fragment } from "react";
import { Box, TextField } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { Modes } from "./FilterPanel";
import { remove, replace } from "./filterSlice";
import { Select } from "./Select";

export type FilterValue = {
  mode: string;
  values?: string[];
};

export const getFilterDefaultValue = (mode: string, modes: Modes) => {
  return modes[mode].map((item) => {
    if (item.type === "text") {
      return "";
    } else if (item.values && item.values[0]) {
      return item.values[0];
    } else if (item.options.length >= 1) {
      return item.options[0];
    } else {
      return "";
    }
  });
};
// TODO: Check why autofocus is needed
const Filter: React.FC<{
  modes: Modes;
  index: number;
}> = ({ index, modes }) => {
  const filter = useSelector((state: RootState) => state.filters.data[index]);
  const lastIndex = useSelector((state: RootState) => state.filters.lastIndex);
  const dispatch = useDispatch();

  const replaceMode = (newMode: string) => {
    // Generate new default values
    const values = getFilterDefaultValue(newMode, modes);
    dispatch(
      replace({ id: index, data: { ...filter, values, mode: newMode } })
    );
  };
  const replaceValue = (newValue: string, idx: number) => {
    const values = [...filter.values];
    values.splice(idx, 1, newValue);
    dispatch(replace({ id: index, data: { ...filter, values } }));
  };
  return (
    <>
      {filter && (
        <div className="col-12">
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <Box sx={{ m: 0.5 }}>
              <Select
                options={Object.keys(modes)}
                value={filter.mode}
                onChange={(newValue) => replaceMode(newValue)}
              />
            </Box>
            {modes[filter.mode].map((item, idx) => (
              <Box sx={{ m: 0.5 }} key={idx}>
                {item.type === "select" && (
                  <Select
                    values={item.values}
                    options={item.options}
                    value={filter.values[idx]}
                    onChange={(newValue) => {
                      replaceValue(newValue, idx);
                    }}
                  />
                )}
                {item.type === "text" && (
                  <TextField
                    autoFocus={lastIndex === index}
                    value={filter.values[idx]}
                    onChange={(e) => {
                      replaceValue(e.target.value, idx);
                    }}
                    variant="outlined"
                  />
                )}
              </Box>
            ))}
          </Box>
          <button
            className="btn btn-danger icon-remove transform-center-v"
            onClick={() => dispatch(remove(index))}
          >
            <i className="fa fa-trash" />
          </button>
        </div>
      )}
    </>
  );
};

export default Filter;

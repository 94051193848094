import React from "react";
import { Card, Container } from "@material-ui/core";
import PasswordForm from "./UpdatePasswordForm";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { Alert } from "@material-ui/lab";

const UpdatePassword: React.FC<{}> = () => {
  const loggedIn = useSelector(
    (state: RootState) => state.auth.status.loggedIn
  );  

  return (
    <>
      {!loggedIn && (
        <Container style={{marginTop: "2rem"}}> <Alert severity="error">Shouldn't reach this page without passwordless login</Alert></Container>
      )}
      {loggedIn && <Card className={"mw-360 mx-auto mt-25vh"}>
          <PasswordForm></PasswordForm>
        </Card>}
    </>
  );
};

export default UpdatePassword;

import React from "react";
import { connect } from "react-redux";
import FileSaver from "file-saver";
import { Link } from "react-router-dom";

import getApiEndpoint from "../endpoint_configuration";
import {
  EXPORT_FROM_SERVER_MSG,
  INTERNET_ERROR_MSG,
  SERVER_FAILED_MSG,
} from "../ErrorMessages";
import { strings } from "../localization";

import CardView from "../common/CardView";

import { setSnackbar, clearSnackbar } from "../common/simpleSnackbarSlice";
import { add, remove } from "./annotationSlice";

const mapState = (store) => {
  return {
    annotationSelection: store.annotations.selection,
  };
};
function mapDispatch(dispatch) {
  return {
    showInfoSnackbar: (message) =>
      dispatch(setSnackbar({ payload: { message, mode: "info" } })),
    showErrorSnackbar: (message) =>
      dispatch(setSnackbar({ payload: { message, mode: "error" } })),
    hideSnackbar: () => dispatch(clearSnackbar()),
    removeFromSelection: (object) => dispatch(remove(object)),
    addToSelection: (object) => dispatch(add(object)),
  };
}
class Annotation extends CardView {
  constructor(props) {
    super(props);

    this.state.showTags = false;

    this.toggleTags = this.toggleTags.bind(this);
    this.exportProast = this.exportProast.bind(this);
  }

  calcEstimatedHeight() {
    return (
      10 +
      Math.max(this.props.data.compounds.length, this.props.data.tags.length)
    );
  }

  onToggleCheckbox(event) {
    const value = event.target.checked;
    this.setState({ checked: value, showDropdown: false });
    if (value) {
      this.props.addToSelection(this.props.data.id);
    } else {
      this.props.removeFromSelection(this.props.data.id);
    }
  }

  renderCardHeader(themeData) {
    return (
      <div className="col-9 text-truncate" title={this.props.data.name}>
        <div className="row">
          <div className="col-12">
            <h4 className="text-truncate">{this.props.data.name}</h4>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <ul className="list-inline mb-0 hidden">
              {this.props.data.s9 === "+" && (
                <li className="list-inline-item badge badge-primary">S9</li>
              )}
              <li
                className="list-inline-item badge badge-secondary pointer"
                onClick={this.toggleTags}
              >
                {strings.toxDB_show}{" "}
                {this.state.showTags
                  ? strings.toxDB_showCompounds
                  : strings.toxDB_showTags}
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }

  renderCardBody(themeData) {
    const dataSource = this.state.showTags
      ? this.props.data.tags
      : this.props.data.compounds;
    return dataSource.map((item, idx) => (
      <div
        className="col-6 col-md-4 text-truncate"
        title={item}
        key={idx + (this.state.showTags ? this.props.data.tags.length : 0)}
      >
        <span>{item}</span>
      </div>
    ));
  }

  renderDropdownMenuItems() {
    return (
      <React.Fragment>
        <Link
          className="dropdown-item"
          to={"/project/show/" + this.props.data.id}
        >
          {strings.toxDB_projectOverview_goTo}
        </Link>
        <span className="dropdown-item pointer" onClick={this.exportProast}>
          {strings.toxDB_projectOverview_PROAST}
        </span>
      </React.Fragment>
    );
  }

  toggleTags() {
    this.setState({ showTags: !this.state.showTags }, this.setShowHoverBadge);
  }

  exportProast() {
    this.setState({ showDropdown: false }, () => {
      showInfoSnackbar(EXPORT_FROM_SERVER_MSG, "info");
      getApiEndpoint("get_proast_project")
        .bindUrlParameter("annotation_hash", this.props.data.id)
        .getFetchPromise()
        .catch(() => showErrorSnackbar(INTERNET_ERROR_MSG))
        .then((response) => {
          if (!response.ok) {
            showErrorSnackbar(SERVER_FAILED_MSG);
            return Promise.reject(new Error("Failed request"));
          }
          return response.blob();
        })
        .then((blob) => {
          hideSnackbar();
          FileSaver.saveAs(blob, `${this.props.data.id}.proast.tsv`);
          this.setState({ showDropdown: false });
        })
        .catch(console.debug);
    });
  }
}

export default connect(mapState, mapDispatch)(Annotation);

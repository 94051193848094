import { ROW_REGEX } from "./single/annotation-generator/PlateConfiguration";


function getCodes(characters) {
    return [...characters].map(character => character.charCodeAt(0));
}


export class Alphabet {

    constructor(startingLetter, endingLetter, wellCount) {
        this._startingCodes = getCodes(startingLetter);
        this._endingCodes = getCodes(endingLetter);

        this._wellCount = wellCount;
    }

    *[Symbol.iterator]() {
        const toCharLength = this._wellCount.toString().length;
        const charIterator = this.iterAlphabet();

        while (true) {
            const result = charIterator.next();
            if (result.done) {
                break;
            }
            for (let wellIndex = 1; wellIndex <= this._wellCount; wellIndex++) {
                const missingChars = toCharLength - wellIndex.toString().length;
                yield `${result.value}${"0".repeat(missingChars)}${wellIndex}`;
            }
        }
    }

    *iterAlphabet() {
        const currentCodes = [...this._startingCodes];

        while (!currentCodes.every((value, index) => this._endingCodes[index] === value)) {
            yield currentCodes.map(code => String.fromCharCode(code)).join("");
            for (let i = currentCodes.length - 1; i >= 0; i--) {
                if (currentCodes[i] + 1 <= this._endingCodes[i]) {
                    currentCodes[i] += 1;
                    break;
                } else {
                    currentCodes[i] = this._startingCodes[i];
                }
            }
        }
        yield currentCodes.map(code => String.fromCharCode(code)).join("");
    }

    getTotalWells() {
        return (this._endingLetter - this._startingLetter + 1) * this._wellCount;
    }

    rowToRowIndex(row) {
        const availableCodes = this._startingCodes.map((value, index) => this._endingCodes[index] - value + 1);
        return [...row].map(character => character.charCodeAt(0)).reduce(
            (total, code, index) => {
                let normalizedCode = code - this._startingCodes[index];
                if (normalizedCode !== 0) {
                    let permutations = null;
                    for (let availableIndex = index + 1; availableIndex < availableCodes.length; availableIndex++) {
                        if (permutations === null) {
                            permutations = availableCodes[availableIndex];
                        } else {
                            permutations *= availableCodes[availableIndex];
                        }
                    }
                    if (permutations !== null) {
                        normalizedCode *= permutations;
                    }
                }
                return total + normalizedCode;
            }, 0
        );
    }

    wellToIndex(well) {
        const wellMatch = ROW_REGEX.exec(well);
        return this.rowToRowIndex(wellMatch[1]) * this._wellCount + parseInt(wellMatch[2], 10);
    }

    getWellCount() {
        return this._wellCount;
    }

    getTotalRows() {
        const finalRow = this._endingCodes.map(code => String.fromCharCode(code)).join("");
        return this.rowToRowIndex(finalRow) + 1;
    }
}

export const ALPHABETS = {
    "96": new Alphabet("A", "H", 12),
    "384": new Alphabet("A", "P", 24)
};

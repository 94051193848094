export const INTERNET_ERROR_MSG =
  "It looks like no connection could be made to the server. Are you still connected to the internet?";
export const EXPORT_FROM_SERVER_MSG = "Server is exporting..";
export const PLATE_HEADER_GET_FAILED_MSG =
  "Cannot get the plate header configuration";
export const SERVER_FAILED_MSG =
  "The server responded with an error, please contact the administrator";
export const AUTHORIZATION_FAILED_MSG = "Authorization failed";
export const TOO_FEW_PERMISSIONS_MSG =
  "You don't have the permissions to do this";

import React from "react";

import { Modes } from "../common/FilterPanel";
import FilterPanel from "../common/FilterPanel";

const FILTER_MODES: Modes = {
  title: [
    {
      type: "select",
      options: [
        "contains",
        "contains word",
        "does not contain",
        "does not contain word",
      ],
      values: [
        { word_mode: false, negated: false },
        { word_mode: true, negated: false },
        { word_mode: false, negated: true },
        { word_mode: true, negated: true },
      ].map((value) =>
        typeof value === "object" ? JSON.stringify(value) : value
      ),
    },
    {
      type: "text",
    },
  ],
  compound: [
    {
      type: "select",
      options: [],
    },
    {
      type: "select",
      options: ["must be present", "must not be present"],
      values: ["in", "not_in"],
    },
  ],
  tag: [
    {
      type: "select",
      options: [],
    },
    {
      type: "select",
      options: ["must be present", "must not be present"],
      values: ["in", "not_in"],
    },
  ],
};

export const AnnotationFilterPanel = ({ ...props }) => (
  <FilterPanel
    {...props}
    fetchers={[
      { key: "compound", index: 0, endpoint: "get_compounds" },
      { key: "tag", index: 0, endpoint: "get_tags" },
    ]}
    modes={FILTER_MODES}
  />
);

import React from "react";
import { useDispatch } from "react-redux";
import { wait, login, logout } from "../auth/authSlice";
import { getFirebaseTenantAuth } from "./firebaseConfig";

const AuthListener = (props: { children: any }) => {
  // env var failback to 60 min
  const sessionDuration = process.env.REACT_APP_SESSION_DURATION_MSEC
    ? +process.env.REACT_APP_SESSION_DURATION_MSEC
    : 60 * 60 * 1000;
  let userSessionTimeout: NodeJS.Timeout | null = null;
  const dispatch = useDispatch();

  dispatch(wait());
  getFirebaseTenantAuth().onAuthStateChanged((user) => {
    if (user && !user.isAnonymous) {
      user.getIdTokenResult().then((idTokenResult) => {
        const authTime = idTokenResult.claims.auth_time * 1000;

        const msSinceLogin = Date.now() - authTime;
        // Workaround to deal with login greater than the current expiration
        const expirationInMilliseconds =
          sessionDuration > msSinceLogin
            ? sessionDuration - msSinceLogin
            : sessionDuration;
        userSessionTimeout = setTimeout(
          () => getFirebaseTenantAuth().signOut(),
          expirationInMilliseconds
        );
      });
      dispatch(login());
    } else {
      if (userSessionTimeout) {
        clearTimeout(userSessionTimeout);
      }
      userSessionTimeout = null;
      dispatch(logout());
    }
  });
  return props.children;
};

export default AuthListener;

import React from "react";

import ToxysLogo from "./resources/images/toxys-logo.png";
import NextNovateLogo from "./resources/images/nextnovate-logo-2023-default.svg";
import { strings } from "./localization"

import "./resources/css/Footer.css";


export default class Footer extends React.Component {

    render() {
        const render = (
            <footer className={"footer row text-secondary" + (this.props.colMode ? "" : this.props.className || "") }>
                <div className="col-12">
                    <div className="row image-container py-2">
                        <div className="col-12 flex-h-center">
                            <span>{strings.footer}</span>
                        </div>


                        <div className="col-10 offset-1 divider pb-3"/>

                        <div className="col-6 flex-center">
                            <a href="http://www.nextnovate.com" target="_blank" rel="noopener noreferrer"><img className="fixed-height" src={NextNovateLogo} alt="NextNovate"/></a>
                        </div>
                        <div className="col-6 flex-center">
                            <a href="https://toxys.com" target="_blank" rel="noopener noreferrer"><img className="fixed-height" src={ToxysLogo} alt="Toxys"/></a>
                        </div>
                    </div>
                </div>
            </footer>
        );
        if (this.props.colMode) {
            return (
                <div className={"col-12 " + (this.props.className || "")}>
                    { render }
                </div>
            );
        }
        return render;
    }
}

import React from "react";

import ToxPlotAnalysis from "./ToxPlotAnalysis";

import { DataProvider } from "../common/InfiniteScroll";
import getApiEndpoint from "../endpoint_configuration";
import { defaultJsonResponse } from "../ApiEndpoint";

const MAX_ITEMS = 25;
const checkboxCollection = {};

export default class ToxPlotDataProvider extends DataProvider {
  constructor(showErrorSnackbar, afterRetrieve) {
    super();

    this.afterRetrieve = afterRetrieve;

    this.offset = 1;
    this.dataBuffer = [];
    this.fetchingData = false;
    this.showErrorSnackbar = showErrorSnackbar;

    this.hasFetched = false;
  }

  bindInfiniteScroll(infiniteScroll) {
    super.bindInfiniteScroll(infiniteScroll);
  }

  fetchAsyncData() {
    this.fetchingData = true;
    this.dataBuffer = [];

    const apiEndpoint = getApiEndpoint(
      this.filters.length === 0
        ? "fetch_analysis_all"
        : "fetch_filtered_analysis_all"
    )
      .bindQueryParameter("offset", this.offset)
      .bindQueryParameter("max_items", MAX_ITEMS);

    if (this.filters.length > 0) {
      apiEndpoint.setBodyData(JSON.stringify(this.filters));
    }
    const showErrorSnackbar = this.showErrorSnackbar;
    apiEndpoint
      .getFetchPromise()
      .catch(() => {
        this.hasFetched = true;
        this.fetchingData = false;
        // TODO: replace error this.snackbarShow(INTERNET_ERROR_MSG);
      })
      .then(defaultJsonResponse(showErrorSnackbar))
      .then((jsonData) => {
        this.hasFetched = true;
        this.fetchingData = false;
        this.offset += MAX_ITEMS;

        this.dataBuffer = jsonData.data;
        if (typeof this.afterRetrieve === "function") {
          this.afterRetrieve();
        }
      })
      .catch(console.debug);
  }

  poll() {
    return this.fetchingData;
  }

  getData() {
    return this.dataBuffer;
  }

  renderComponent(dataObject) {
    return (
      <ToxPlotAnalysis
        data={dataObject}
        key={dataObject.id}
        checkboxCollection={checkboxCollection}
        maxHeight={13}
      />
    );
  }
}

import "../../resources/css/toxplot_analysis/AnalysisView.css";

import {
  EXPORT_FROM_SERVER_MSG,
  INTERNET_ERROR_MSG,
  SERVER_FAILED_MSG,
} from "../../ErrorMessages";
import {
  FILTER_PARAMETERS,
  formatTimeDate,
  parameterFilter,
} from "../ToxPlotAnalysis";
import { bindResizeListener, removeResizeListener } from "../../Router";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import InputLabel from "@material-ui/core/InputLabel";
import Fab from "@material-ui/core/Fab";
import FormControl from "@material-ui/core/FormControl";
import GetAppIcon from "@material-ui/icons/GetApp";
import FormHelperText from "@material-ui/core/FormHelperText";
import Icon from "@material-ui/core/Icon";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { Link } from "react-router-dom";
import { blue } from "@material-ui/core/colors";

import FileSaver from "file-saver";
import Overlay from "../../common/Overlay";
import React from "react";
import ThemedBackground from "../../common/ThemedBackground";
import Tooltip from "@material-ui/core/Tooltip";
import base64 from "base64-js";
import { defaultJsonResponse } from "../../ApiEndpoint";
import getApiEndpoint from "../../endpoint_configuration";
import { getPixels } from "../../utils";
import { strings } from "../../localization";
import { connect } from "react-redux";
import { setSnackbar, clearSnackbar } from "../../common/simpleSnackbarSlice";

const mapState = (store) => {
  return {
    permissions: store.permissions,
  };
};

function mapDispatch(dispatch) {
  return {
    showErrorSnackbar: (message) =>
      dispatch(setSnackbar({ message, mode: "error" })),
    showWarningSnackbar: (message) =>
      dispatch(setSnackbar({ message, mode: "warning" })),
    showInfoSnackbar: (message) =>
      dispatch(setSnackbar({ message, mode: "info" })),
    clearSnackbar: () => dispatch(clearSnackbar()),
  };
}
class AnalysisView extends ThemedBackground {
  refresher: CallableFunction | null;
  constructor(props) {
    super(props);

    this.state = {
      notFound: false,
      analysisName: "",
      data: null,
      images: null,
      currentImage: "null",
      currentSessionDataKey: null,
    };

    this.image = React.createRef();

    this.downloadError = this.downloadError.bind(this);
    this.fetchImages = this.fetchImages.bind(this);
    this.changeImage = this.changeImage.bind(this);
    this.downloadImage = this.downloadImage.bind(this);
    this.fullDownload = this.fullDownload.bind(this);
    this.reportDownload = this.reportDownload.bind(this);
    this.persistAnalysis = this.persistAnalysis.bind(this);
    this.onImageLoad = this.onImageLoad.bind(this);
    this.onImageClick = this.onImageClick.bind(this);
    this.refresher = null;
  }

  fetchImages() {
    const analysisId = this.props.match.params.id;
    getApiEndpoint("fetch_analysis_images")
      .bindUrlParameter("analysis_hash", analysisId)
      .bindQueryParameter("image_data", false)
      .getFetchPromise()
      .catch(() => this.props.showErrorSnackbar(INTERNET_ERROR_MSG))
      .then(defaultJsonResponse(this.props.showErrorSnackbar))
      .then((jsonData) => {
        const changeImage = () => {
          if (jsonData.images.length !== 0) {
            this.changeImage({
              target: {
                value: jsonData.images[0],
              },
            });
          }
        };

        this.setState(
          {
            images: jsonData.images,
          },
          changeImage
        );
      })
      .catch(console.debug);
  }

  componentDidMount() {
    const errorCallback = (response) => {
      if (response.status === 404) {
        this.setState({ notFound: true });
        return true;
      }
      return false;
    };

    const analysisId = this.props.match.params.id;

    getApiEndpoint("fetch_analysis")
      .bindUrlParameter("analysis_hash", analysisId)
      .getFetchPromise()
      .catch(() => this.props.showErrorSnackbar(INTERNET_ERROR_MSG))
      .then(
        defaultJsonResponse(this.props.showErrorSnackbar, true, errorCallback)
      )
      .then((jsonData) =>
        this.setState(
          {
            data: jsonData,
            hijackComponent: null,
          },
          () => {
            if (this.state.data && this.state.data.analysis_status === "ok") {
              this.fetchImages();
            }
            if (
              this.state.data &&
              this.state.data.analysis_status === "analyzing"
            ) {
              this.refresher = setInterval((_) => {
                window.location.reload();
              }, 30000);
            } else {
              clearInterval(this.refresher);
            }
          }
        )
      )
      .catch(console.debug);
    // bindResizeListener(this.onImageLoad);
  }

  componentWillUnmount() {
    removeResizeListener(this.onImageLoad);
    if (this.refresher) {
      clearInterval(this.refresher);
    }
  }

  downloadError() {
    const file = new File(this.state.data.error.split("\n"), {
      type: "text/plain;charset=utf-8",
    });
    FileSaver.saveAs(file, "error.txt");
  }

  fullDownload() {
    if (!this.state.data) {
      this.props.showWarningSnackbar(
        "Server is still exporting project, try again later",
        "warning"
      );
      return;
    }

    this.props.showInfoSnackbar(EXPORT_FROM_SERVER_MSG);
    getApiEndpoint("export_analysis")
      .bindUrlParameter("analysis_hash", this.state.data.id)
      .getFetchPromise()
      .catch(() => this.props.showErrorSnackbar(INTERNET_ERROR_MSG))
      .then((response) => {
        if (!response.ok) {
          this.props.showErrorSnackbar(SERVER_FAILED_MSG);
          return;
        }
        return response.blob();
      })
      .then((blob) => {
        FileSaver.saveAs(blob, this.state.data.id + ".zip");
        this.props.clearSnackbar();
      })
      .catch(console.debug);
  }

  reportDownload() {
    if (!this.state.data) {
      this.props.showWarningSnackbar(
        "Server is still exporting report, try again later"
      );
      return;
    }

    this.props.showInfoSnackbar(EXPORT_FROM_SERVER_MSG);
    getApiEndpoint("export_analysis_report")
      .bindUrlParameter("analysis_hash", this.state.data.id)
      .getFetchPromise()
      .catch(() => this.props.showErrorSnackbar(INTERNET_ERROR_MSG))
      .then((response) => {
        if (!response.ok) {
          this.props.showErrorSnackbar(SERVER_FAILED_MSG);
          return;
        }
        return response.blob();
      })
      .then((blob) => {
        FileSaver.saveAs(blob, this.state.data.id + ".pdf");
        this.props.clearSnackbar();
      })
      .catch(console.debug);
  }

  changeImage(evt) {
    const analysisId = this.props.match.params.id;
    const imageIndex = this.state.images.indexOf(evt.target.value);
    const key = `${analysisId}-image-${imageIndex}`;

    if (imageIndex < 0) return;
    if (window.sessionStorage.getItem(key)) {
      this.setState({
        currentImage: evt.target.value,
        currentSessionDataKey: key,
      });
      return;
    }
    // Only persist the event when persist is available
    // (We use this function also to fire our own events)
    if (evt.persist) evt.persist();

    getApiEndpoint("fetch_analysis_images")
      .bindUrlParameter("analysis_hash", analysisId)
      .bindQueryParameter("image_data", true)
      .bindQueryParameter("item", imageIndex)
      .getFetchPromise()
      .catch(() => this.props.showErrorSnackbar(INTERNET_ERROR_MSG))
      .then((response) => {
        if (!response.ok) {
          this.props.showErrorSnackbar(SERVER_FAILED_MSG);
          return;
        }
        return response.blob();
      })
      .then((blob) => {
        const fileReader = new FileReader();
        fileReader.onload = (event) => {
          window.sessionStorage.setItem(
            key,
            base64.fromByteArray(new Uint8Array(event.target.result))
          );
          this.setState({
            currentImage: evt.target.value,
            currentSessionDataKey: key,
          });
        };
        fileReader.readAsArrayBuffer(blob);
      })
      .catch(console.debug);
  }

  downloadImage() {
    const index = this.state.images.indexOf(this.state.currentImage);
    const name = this.state.currentImage;
    const analysisId = this.props.match.params.id;

    const blob = new Blob(
      [
        base64.toByteArray(
          window.sessionStorage.getItem(`${analysisId}-image-${index}`)
        ),
      ],
      { type: "application/octet-stream" }
    );
    FileSaver.saveAs(blob, name);
  }

  persistAnalysis() {
    const analysisId = this.props.match.params.id;
    const hasAnalysisName =
      this.state.analysisName && this.state.analysisName.length > 0;
    const promise = getApiEndpoint("persist_analysis").bindUrlParameter(
      "analysis_hash",
      analysisId
    );
    if (hasAnalysisName)
      promise.bindQueryParameter("name", this.state.analysisName);

    promise
      .getFetchPromise()
      .catch(() => this.props.showErrorSnackbar(INTERNET_ERROR_MSG))
      .then((response) => {
        const data = {
          ...this.state.data,
        };
        data.persisted = true;
        if (hasAnalysisName) {
          data.name = this.state.analysisName;
        }
        this.setState({ data: data });
      })
      .catch(console.debug);
  }

  onImageLoad() {
    if (this.image.current) {
      const originalRatio =
        this.image.current.naturalWidth / this.image.current.naturalHeight;

      const parentStyle = getComputedStyle(this.image.current.parentElement);
      const availableWidth =
        getPixels(parentStyle.width) -
        getPixels(parentStyle.paddingLeft) -
        getPixels(parentStyle.paddingRight);
      const width = Math.min(availableWidth, this.image.current.naturalWidth);

      this.image.current.width = width;
      this.image.current.height = width / originalRatio;
    }
  }

  onImageClick() {
    const image = new Image();
    image.src = `data:image/png;base64, ${window.sessionStorage.getItem(
      this.state.currentSessionDataKey
    )}`;

    window.open("", "_blank").document.write(image.outerHTML);
  }

  renderBody(themeData) {
    if (this.state.notFound) {
      return (
        <div className="alert alert-primary w-100 text-center" role="alert">
          {strings.toxDB_analysis_notfound}
        </div>
      );
    }

    const parameters = {};
    if (this.state.data) {
      const filteredParameters = Object.keys(
        this.state.data.analysis_parameters
      ).filter((key) => !FILTER_PARAMETERS[key]);
      filteredParameters.sort();
      filteredParameters.forEach((key) => {
        parameters[key.replace("_", " ")] = this.state.data.analysis_parameters[
          key
        ];
        if (Array.isArray(parameters[key])) {
          parameters[key] = parameters[key].join(", ");
        }
      });
    }

    let persistDeadline =
      this.state.data && this.state.data.result_received
        ? new Date(this.state.data.result_received)
        : null;
    if (persistDeadline) {
      persistDeadline.setMinutes(persistDeadline.getMinutes() + 20);
      persistDeadline = `${
        strings.toxDB_analysis_deleteWarning
      } ${persistDeadline.toLocaleString()}`;
    } else {
      persistDeadline = strings.toxDB_analysis_deleteWarningAnalyzing;
    }

    return (
      <React.Fragment>
        {this.state.data && (
          <React.Fragment>
            <div className="col-0 col-sm-1 col-md-2" />
            <div className="background-item project-view-item analysis-view col-12 col-sm-10 col-md-8 mt-2 py-1">
              <div className="row">
                <div className="col-12">
                  <h2 className="text-center text-truncate col-12">
                    {this.state.data.name || this.state.data.id}
                  </h2>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-6">
                  <div className="float-sm-left">
                    <span className="d-block float-left float-sm-none">
                      {strings.toxDB_analysis_enqueued}
                    </span>
                    <span className="d-block float-right float-sm-none">
                      {formatTimeDate(this.state.data.enqueued)}
                    </span>
                  </div>
                </div>
                {this.state.data.result_received && (
                  <div className="col-12 col-sm-6">
                    <div className="float-sm-right float-none">
                      <span className="d-block float-sm-none float-left">
                        <span className="float-sm-right">
                          {strings.toxDB_analysis_received}
                        </span>
                      </span>
                      <span className="d-block float-right float-sm-left">
                        {formatTimeDate(this.state.data.result_received)}
                      </span>
                    </div>
                  </div>
                )}
              </div>
              {this.state.data.status !== "analyzing" &&
                !this.state.data.persisted && (
                  <div className="row">
                    <div className="col-12">
                      <div className="row alert alert-danger m-2">
                        <div className="col-12 col-sm-8 col-md-9">
                          <span>{persistDeadline}</span>
                        </div>
                        <div className="col-12 col-sm-4 col-md-3">
                          <button
                            className="btn btn-info full-width"
                            onClick={this.persistAnalysis}
                          >
                            {strings.toxDB_analysis_deleteWarning_save}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
            </div>
            <div className="col-0 col-sm-1 col-md-2" />
            <div className="col-0 col-sm-1 col-md-2" />
            <div className="background-item project-view-item analysis-view col-12 col-sm-10 col-md-8 mt-2 py-1">
              <div className="row">
                <div className="col-12">
                  <h4>{strings.toxDB_analysis_usedProjects}</h4>
                  <small className="font-italic text-muted">
                    {strings.toxDB_analysis_usedProjects_select}
                  </small>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12">
                  <ul className="list-inline mb-0 hidden">
                    {Object.entries(this.state.data.projects).map((project) => (
                      <li className="list-inline-item" key={project[1]}>
                        <Link
                          className="download-button"
                          to={`/project/show/${project[1].id}`}
                          target="_blank"
                        >
                          <div className="container p-2 mt-1">
                            <span>{project[0]}</span>
                          </div>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-0 col-sm-1 col-md-2" />
            <div className="col-0 col-sm-1 col-md-2" />
            <div className="background-item project-view-item analysis-view col-12 col-sm-10 col-md-8 mt-2 py-1">
              {Object.keys(parameters).length > 0 && (
                <React.Fragment>
                  <div className="row">
                    <div className="col-12">
                      <h4>{strings.toxDB_analysis_parameters}</h4>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>{strings.toxDB_analysis_parameter}</th>
                            <th>{strings.toxDB_analysis_parameters_value}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.entries(parameters)
                            .filter(
                              ([key, value]) =>
                                parameterFilter(key) && value !== null
                            )
                            .map(([key, value]) => (
                              <tr key={key}>
                                <td>{key}</td>
                                <td>{value.toString()}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
            <div className="col-0 col-sm-1 col-md-2" />
            <div className="col-0 col-sm-1 col-md-2" />
            <div className="background-item project-view-item analysis-view col-12 col-sm-10 col-md-8 my-2 py-1">
              <div className="row">
                <div className="col-12">
                  <h4>
                    <span>
                      {strings.toxDB_analysis_status}
                      {this.state.data.analysis_status}
                    </span>
                    {this.state.data.analysis_status === "error" && (
                      <i
                        className="fa fa-download icon-circle float-right pointer"
                        title="Download error"
                        onClick={this.downloadError}
                      />
                    )}
                  </h4>
                </div>
                {this.state.data.analysis_status === "error" && (
                  <pre className="error-container col-12 mx-1">
                    {this.state.data.error}
                  </pre>
                )}
                {this.state.data.analysis_status === "analyzing" && (
                  <div className="col-12">
                    <span>
                      Still waiting on results for this analysis (refresh this
                      page to update)
                    </span>
                  </div>
                )}
                {this.state.images && (
                  <React.Fragment>
                    <Grid
                      container
                      direction="row"
                      justify="space-around"
                      spacing={3}
                      style={{ padding: 20 }}
                    >
                      <Grid item xs={9} sm={10}>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel id="graph-select-label">Graph</InputLabel>
                          <Select
                            labelId="graph-select-label"
                            id="graph-select"
                            value={this.state.currentImage}
                            onChange={this.changeImage}
                            labelWidth={45}
                          >
                            <MenuItem value="null">
                              --------------------------
                            </MenuItem>
                            {this.state.images.map((image, idx) => (
                              <MenuItem value={image} key={idx}>
                                {image}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText id="graph-select-help">
                            {strings.toxDB_analysis_image_dropdown}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={3} sm={2}>
                        {this.state.currentImage !== "null" && (
                          <Tooltip
                            title={strings.toxDB_analysis_image_download_help}
                          >
                            <Button
                              variant="contained"
                              style={{
                                backgroundColor: blue[500],
                                color: "#fff",
                              }}
                              className="btn btn-primary"
                              onClick={this.downloadImage}
                            >
                              <GetAppIcon fontSize="large" />
                            </Button>
                          </Tooltip>
                        )}
                      </Grid>

                      <Grid item>
                        {this.state.currentImage !== "null" && (
                          <img
                            ref={this.image}
                            className="image-preview pointer"
                            onLoad={this.onImageLoad}
                            src={`data:image/png;base64, ${window.sessionStorage.getItem(
                              this.state.currentSessionDataKey
                            )}`}
                            onClick={this.onImageClick}
                            alt={this.state.currentImage}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )}
              </div>
            </div>
            <div className="col-0 col-sm-1 col-md-2" />
          </React.Fragment>
        )}
        {this.state.data && this.state.data.analysis_status === "ok" && (
          <div className="fab-container">
            <Fab
              className="fab"
              aria-label={strings.toxDB_report_download}
              onClick={this.reportDownload}
            >
              <Icon className="fa fa-file-pdf" />
            </Fab>
            <Fab
              className="fab"
              aria-label={strings.toxDB_analysis_download}
              onClick={this.fullDownload}
            >
              <Icon className="fa fa-download" />
            </Fab>
          </div>
        )}
        {this.state.hijackComponent}
      </React.Fragment>
    );
  }
}

export default connect(mapState, mapDispatch)(AnalysisView);

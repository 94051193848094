import "../resources/css/navigation/Navigation.css";

import {
  GROUP_LIST,
  TOXPLOT_CREATE_ANALYSIS,
  TOXPLOT_CREATE_PROJECT,
  TOXPLOT_VIEW_ANALYSIS,
  TOXPLOT_VIEW_PROJECT,
  USER_LIST,
} from "../users/Permission";
import { NavLink } from "react-router-dom";
import { bug, feedback } from "../utils";
import { setLanguage, strings } from "../localization";

import React from "react";
import { connect } from "react-redux";
import ThemedComponent from "../common/Theme";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import { getFirebaseTenantAuth } from "../auth/firebaseConfig";
/**
 * A navigation which by default renders a logo along with links to the pages
 * To render an overlay (not actually on overlay, as it gets replaced), the
 * alternativeRender property can be used.
 */

const mapState = (store) => {
  return {
    permissions: store.permissions,
  };
};
class Navigation extends ThemedComponent {
  constructor(props) {
    super(props);
    this.state = {
      adminMenuAnchor: null,
    };

    this.collapseButton = React.createRef();

    this.logout = this.logout.bind(this);
    this.collapse = this.collapse.bind(this);
    this.setLang = this.setLang.bind(this);
  }

  setLang(e) {
    setLanguage(e.target.dataset.message);
  }

  hasPermission(permission) {
    return (
      !!this.props.permissions &&
      this.props.permissions.indexOf(permission) !== -1
    );
  }

  handleCloseAdminMenu = () => {
    this.setState({ adminMenuAnchor: null });
  };

  bugReport = () => {
    this.setState({ adminMenuAnchor: null });
    bug();
  };

  feedbackReport = () => {
    this.setState({ adminMenuAnchor: null });
    feedback();
  };

  handleOpenAdminMenu = (event) => {
    this.setState({ adminMenuAnchor: event.currentTarget });
  };

  logout() {
    // TODO: call firebase logout and redux logout
    /* Close the admin dropdown */
    this.setState({ adminMenuAnchor: null });
    getFirebaseTenantAuth().signOut();
  }

  collapse() {
    const style = getComputedStyle(this.collapseButton.current);
    if (style.display !== "none") {
      this.collapseButton.current.click();
    }
  }

  themedRender(themeData) {
    const navData = themeData.navigation;
    const navStyle = {
      backgroundColor: navData.background,
      color: navData.text,
      zIndex: 500,
    };
    const userManagement = this.hasPermission(USER_LIST);
    const groupManagement = this.hasPermission(GROUP_LIST);
    const viewProjects = this.hasPermission(TOXPLOT_VIEW_PROJECT);
    const viewAnalysis = this.hasPermission(TOXPLOT_VIEW_ANALYSIS);
    const createProject = this.hasPermission(TOXPLOT_CREATE_PROJECT);
    const createAnalysis = this.hasPermission(TOXPLOT_CREATE_ANALYSIS);

    return (
      <nav
        className="navbar navbar-expand-lg shadow bg-white align-items-end"
        style={navStyle}
      >
        <div className="navbar-brand">
          <img
            src={navData.logo}
            className="full-width"
            alt=""
            style={{ maxWidth: "210px", maxHeight: "70px", width: "auto" }}
          />
        </div>

        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#nav-toggler"
          aria-controls="nav-toggler"
          aria-expanded="false"
          aria-label="Toggle navigation"
          ref={this.collapseButton}
        >
          <span className="fa fa-bars" />
        </button>

        <div className="collapse navbar-collapse" id="nav-toggler">
          <ul className="navbar-nav mx-5 ml-lg-auto text-center text-lg-left">
            {viewProjects && (
              <li onClick={this.collapse} className="nav-item">
                <NavLink to="/project-overview" className="nav-link">
                  {strings.nav_projectOverview}
                </NavLink>
              </li>
            )}
            {createProject && (
              <li onClick={this.collapse} className="nav-item">
                <NavLink to="/project/create" className="nav-link">
                  {strings.nav_createProject}
                </NavLink>
              </li>
            )}
            {viewAnalysis && (
              <li onClick={this.collapse} className="nav-item">
                <NavLink to="/analysis-overview" className="nav-link">
                  {strings.nav_analysisOverview}
                </NavLink>
              </li>
            )}
            {createAnalysis && (
              <li onClick={this.collapse} className="nav-item">
                <NavLink to="/analysis/create" className="nav-link">
                  {strings.nav_createAnalysis}
                </NavLink>
              </li>
            )}

          
            <li onClick={this.collapse} className="nav-item">
              <NavLink to="/support" className="nav-link">
                {strings.nav_support}
              </NavLink>
            </li>

            <Box
              component={IconButton}
              display={{ xs: "none", md: "block" }}
              aria-label="admin-menu-button"
              onClick={this.handleOpenAdminMenu}
            >
              <span
                style={{ fontSize: 15 }}
                className="fa fa-user-check"
              ></span>
            </Box>
            <Menu
              id="admin-menu"
              anchorEl={this.state.adminMenuAnchor}
              keepMounted
              open={Boolean(this.state.adminMenuAnchor)}
              onClose={this.handleCloseAdminMenu}
            >
                 <MenuItem
                  onClick={this.handleCloseAdminMenu}
                  to="/auth/update-password"
                  component={NavLink}
                >
                  {strings.nav_user_password}
                </MenuItem>
              {userManagement && (
                <MenuItem
                  onClick={this.handleCloseAdminMenu}
                  to="/users"
                  component={NavLink}
                >
                  {strings.nav_user_management}
                </MenuItem>
              )}
              {groupManagement && (
                <MenuItem
                  onClick={this.handleCloseAdminMenu}
                  to="/group-client"
                  component={NavLink}
                >
                  {strings.nav_group_management}
                </MenuItem>
              )}
              <MenuItem onClick={this.feedbackReport}>
                {strings.nav_user_sendFB}
              </MenuItem>
              <MenuItem onClick={this.bugReport}>
                {strings.nav_user_sendBug}
              </MenuItem>
              <MenuItem onClick={this.logout}>
                {strings.nav_user_logout}
              </MenuItem>
            </Menu>

            <li className="nav-group-header d-lg-none">
              <button
                onClick={this.collapse}
                className="anchor header full-width full-height d-block pointer"
              >
                <h4 className="mb-0">{strings.nav_user_profile}</h4>
              </button>
            </li>

            <div className="nav-group d-lg-none">
              {userManagement && (
                <li onClick={this.collapse} className="nav-item">
                  <NavLink
                    to="/users"
                    className="full-width full-height d-block"
                  >
                    {strings.nav_user_management}
                  </NavLink>
                </li>
              )}
              {groupManagement && (
                <li onClick={this.collapse} className="nav-item">
                  <NavLink
                    to="/group-client"
                    className="full-width full-height d-block"
                  >
                    {strings.nav_user_management}
                  </NavLink>
                </li>
              )}
              <li onClick={this.collapse} className="nav-item">
                <button
                  onClick={feedback}
                  className="anchor dropdown-item pointer"
                >
                  <span>{strings.nav_user_sendFB}</span>
                </button>
              </li>
              <li onClick={this.collapse} className="nav-item">
                <button onClick={bug} className="anchor dropdown-item pointer">
                  <span>{strings.nav_user_sendBug}</span>
                </button>
              </li>
              <li onClick={this.collapse} className="nav-item">
                <button
                  onClick={this.logout}
                  className="anchor full-width full-height d-block pointer"
                >
                  <span>{strings.nav_user_logout}</span>
                </button>
              </li>
            </div>
          </ul>
        </div>
      </nav>
    );
  }
}

export default connect(mapState, null)(Navigation);

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";
import { FIREBASE_FUNCTION_REGION } from "../constants";

export type firebaseAuthProps = {
  firebaseAuth: firebase.auth.Auth;
};

let firebaseConfigString = process.env.REACT_APP_FIREBASE_CONFIG;

if (!firebaseConfigString) {
  console.error("No firebase config found");
  firebaseConfigString = "";
}
const firebaseConfig = JSON.parse(firebaseConfigString);

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

// Conditional for firebase functions emulator
if (window.location.hostname.includes("localhost")){
  firebase.app().functions(FIREBASE_FUNCTION_REGION).useEmulator("localhost", 5001);
}

export const getFirebaseTenantAuth = () => {

  const firebaseAuth = firebase.auth();
  const firebaseTenant = process.env.REACT_APP_FIREBASE_TENANT;

  // Conditional for firebase auth emulator
  if (window.location.hostname.includes("localhost")){
    firebaseAuth.useEmulator("http://localhost:9099");
    return firebaseAuth;
  }
  if (!!firebaseTenant) {
    firebaseAuth.tenantId = firebaseTenant;
  }
  return firebaseAuth;
};


export const firebaseFunc = firebase.app().functions(FIREBASE_FUNCTION_REGION);

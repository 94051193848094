import {
  GROUP_EDIT,
  GROUP_LIST,
  TOXPLOT_VIEW_ANALYSIS,
  TOXPLOT_VIEW_PROJECT,
  USER_ADMIN,
} from "./Permission";
import { INTERNET_ERROR_MSG, SERVER_FAILED_MSG } from "../ErrorMessages";

import { hasPermission } from "../common/Authenticate";
import Grid from "@material-ui/core/Grid";
import InviteOverlay from "./InviteOverlay";
import NotFound from "../NotFound";
import React from "react";
import ThemedBackground from "../common/ThemedBackground";
import User from "./User";
import getApiEndpoint from "../endpoint_configuration";
import { strings } from "../localization";

import { setSnackbar, clearSnackbar } from "../common/simpleSnackbarSlice";
import { connect } from "react-redux";
import { AppDispatch, RootState } from "../store";
import { set, clear } from "./userSlice";

const mapState = (store: RootState) => ({
  users: store.users,
});
function mapDispatch(dispatch: AppDispatch) {
  return {
    showErrorSnackbar: (message: string) =>
      dispatch(setSnackbar({ payload: { message, mode: "error" } })),
    hideSnackbar: () => dispatch(clearSnackbar()),
    setUsers: (users: []) => dispatch(set(users)),
    clearUsers: () => dispatch(clear()),
  };
}

class Management extends ThemedBackground {
  constructor(props) {
    super(props);

    this.state = {
      emailPool: [],
      maxUsers: null,
      initialLoad: true,
      permissions: [],
      availableRows: [],
      clientGroups: [],
      canListGroups: false,
      canAdminUsers: false,
      openInviteOverlay: false,
    };

    this.openInviteOverlay = this.openInviteOverlay.bind(this);
    this.refreshUsersAndTokens = this.refreshUsersAndTokens.bind(this);
  }

  canAddUsers() {
    return (
      this.state.maxUsers !== null &&
      (this.state.maxUsers === -1 ||
        this.props.users.length < this.state.maxUsers)
    );
  }

  refreshUsersAndTokens(callback) {
    if (!hasPermission("list-users", this.state.permissions)) {
      this.props.clearUsers();
      return callback();
    }
    

    callback = callback || (() => 1);
    getApiEndpoint("user_list")
      .getFetchPromise()
      .catch(() => this.props.showErrorSnackbar(INTERNET_ERROR_MSG))
      .then((response) => {
        if (!response.ok) return new Promise((resolve, reject) => reject());
        return response.json();
      })
      .then((data) => {
        if (data.status !== "ok")
          return new Promise((resolve, reject) => reject());
        this.props.setUsers(data.users);
        callback();
      })
      .catch(() => this.props.showErrorSnackbar(SERVER_FAILED_MSG));
  }

  componentDidMount() {
    let callback = null;
    if (hasPermission("invite-users", this.state.permissions)) {
      // Fetch the client data
      callback = () => {
        getApiEndpoint("user_client")
          .getFetchPromise()
          .catch(() => this.props.showErrorSnackbar(INTERNET_ERROR_MSG))
          .then((response) => {
            if (!response.ok) return new Promise((resolve, reject) => reject());
            return response.json();
          })
          .then((data) =>
            this.setState({
              maxUsers: data.client.max_users,
              initialLoad: false,
            })
          )
          .catch(() => showErrorSnackbar(SERVER_FAILED_MSG));
      };
    }

    this.refreshUsersAndTokens(callback);
    if (hasPermission(USER_ADMIN, this.state.permissions)) {
      this.setState({ canAdminUsers: true });

      if (hasPermission(TOXPLOT_VIEW_PROJECT, this.state.permissions)) {
        getApiEndpoint("get_projects")
          .getFetchPromise()
          .catch(() => this.props.showErrorSnackbar(INTERNET_ERROR_MSG))
          .then((response) => {
            if (!response.ok) return new Promise((resolve, reject) => reject());
            return response.json();
          })
          .then((response) =>
            this.setState({
              availableRows: this.state.availableRows.concat(
                response.data.map((analysis) => {
                  return {
                    id: analysis.id,
                    name: analysis.name,
                    resourceCategory: "annotation",
                  };
                })
              ),
            })
          );
      }
      if (hasPermission(TOXPLOT_VIEW_ANALYSIS, this.state.permissions)) {
        getApiEndpoint("fetch_analysis_all")
          .getFetchPromise()
          .catch(() => this.props.showErrorSnackbar(INTERNET_ERROR_MSG))
          .then((response) => {
            if (!response.ok) return new Promise((resolve, reject) => reject());
            return response.json();
          })
          .then((response) =>
            this.setState({
              availableRows: this.state.availableRows.concat(
                response.data.map((analysis) => {
                  return {
                    id: analysis.id,
                    name: analysis.name,
                    resourceCategory: "analysis",
                  };
                })
              ),
            })
          )
          .catch(() => this.props.showErrorSnackbar(SERVER_FAILED_MSG));
      }
    }
    if (hasPermission(GROUP_EDIT, this.state.permissions)) {
      this.setState({ canEditGroups: true });
    }

    if (hasPermission(GROUP_LIST, this.state.permissions)) {
      this.setState({ canListGroups: true });
      getApiEndpoint("user_client_groups_get_list")
        .getFetchPromise()
        .catch(() => this.props.showErrorSnackbar(INTERNET_ERROR_MSG))
        .then((response) => {
          if (!response.ok) return new Promise((resolve, reject) => reject());
          return response.json();
        })
        .then((data) => this.setState({ clientGroups: data.client_groups }))
        .catch(() => this.props.showErrorSnackbar(SERVER_FAILED_MSG));
    }
  }
  // TODO: get permissions

  openInviteOverlay() {
    if (!this.canAddUsers()) return;

    this.setState({
      openInviteOverlay: true,
      emailPool: [this.props.users.map((user) => user.email)],
    });
    const emptyCustomPermission = [];
    // this.props.nav.current.setOverlay();
  }

  renderBody(themeData) {
    if (!hasPermission("list-users", this.state.permissions)) {
      return <NotFound />;
    }

    const canAddUsers = this.canAddUsers();
    return (
      <React.Fragment>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="stretch"
          spacing={3}
        >
          {this.props.users.map((userData) => {
            return (
              <User
                data={userData}
                clientGroups={this.state.clientGroups}
                canAddUsers={this.state.canAddUsers}
                canListGroups={this.state.canListGroups}
                key={userData.uid}
                availableRows={this.state.availableRows}
                permissions={this.state.permissions}
                update={this.refreshUsersAndTokens}
                canAdminUsers={this.state.canAdminUsers}
              />
            );
          })}
        </Grid>
        <div className="col-0 col-sm-1 col-md-2 col-lg-3"></div>
        {canAddUsers && (
          <div className="col-12 col-sm-10 col-md-8 col-lg-6 mt-2">
            <InviteOverlay
              open={this.state.openInviteOverlay}
              clientGroups={this.state.clientGroups}
              update={this.refreshUsersAndTokens}
              emailPool={this.state.emailPool}
              customPermissions={[]}
              waitingMessage={strings.user_management_add_inviting}
              permissions={this.state.permissions}
              availableRows={this.state.availableRows}
            />
          </div>
        )}
        {!canAddUsers && !this.state.initialLoad && (
          <div className="col-12 col-sm-10 col-md-8 col-lg-6 mt-2">
            <span className="alert alert-info full-width d-block text-center">
              {strings.user_management_add_maxUser}
            </span>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default connect(mapState, mapDispatch)(Management);

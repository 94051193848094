import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import AnalysisView from "./toxplot_analysis/single/AnalysisView";
import AnnotationRenderer from "./annotation/AnnotationRenderer";
import Box from "@material-ui/core/Box";
import CreateAnalysis from "./toxplot_analysis/single/CreateAnalysis";
import Footer from "./Footer";
import GroupManagement from "./users/GroupManagement";
import Management from "./users/Management";
import NotFound from "./NotFound";
import ProjectView from "./annotation/single/ProjectView";
import React from "react";
import Status from "./Status";
import Support from "./Support";
import ToxPlotRenderer from "./toxplot_analysis/ToxPlotRenderer";
import ToxysWaveLight from "./resources/images/toxys-wave-light.png";
import AuthGuard from "./auth/AuthGuard";
import SimpleSnackbar from "./common/SimpleSnackbar";
import Login from "./auth/Login";
import { AuthMgmt } from "./auth/AuthMgmt";
import UpdatePassword from "./auth/UpdatePassword";
import ResetPassword from "./auth/ResetPassword";
const resizeFunctions = [];

export function bindResizeListener(func) {
  if (resizeFunctions.indexOf(func) === -1) {
    resizeFunctions.push(func);
  }
}

export function removeResizeListener(func) {
  const index = resizeFunctions.indexOf(func);
  if (index !== -1) {
    resizeFunctions.splice(index, 1);
  }
}

export default class Router extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.addEventListener("resize", () => {
      setTimeout(150, () => resizeFunctions.forEach((func) => func()));
    });
  }

  componentWillUnmount() {
    resizeFunctions.forEach(removeResizeListener);
  }

  render() {
    return (
      <BrowserRouter>
        <React.Fragment>
          <Box
            display="flex"
            flexDirection="column"
            minHeight="100vh"
            style={{
              backgroundImage: `url(${ToxysWaveLight})`,
              backgroundRepeat: "no-repeat",
              backgroundAttachment: "fixed",
              backgroundPosition: "bottom",
              backgroundSize: "cover",
            }}
          >
            <Box flexGrow={1} flexShrink={0}>
              <Switch>
                <Route
                  exact
                  path="/users"
                  component={() => <AuthGuard component={Management} />}
                />
                <Route
                  exact
                  path="/group-client"
                  component={() => <AuthGuard component={GroupManagement} />}
                />

                <Route
                  path="/project-overview"
                  component={() => <AuthGuard component={AnnotationRenderer} />}
                />
                <Route
                  path="/project/create"
                  component={() => <AuthGuard component={ProjectView} />}
                />
                <Route
                  path="/project/show/:id"
                  component={() => <AuthGuard component={ProjectView} />}
                />

                <Route
                  path="/analysis-overview"
                  component={() => <AuthGuard component={ToxPlotRenderer} />}
                />
                <Route
                  path="/analysis/create"
                  component={() => <AuthGuard component={CreateAnalysis} />}
                />
                <Route
                  path="/analysis/show/:id"
                  component={() => <AuthGuard component={AnalysisView} />}
                />

                <Redirect exact from="/" to="/project-overview" />
                <Route path="/login" component={Login} />
                <Route path="/reset-password" component={ResetPassword} />
                <Route exact path="/auth/action" component={AuthMgmt} />
                <Route
                  exact
                  path="/auth/update-password"
                  component={UpdatePassword}
                />
                <Route exact path="/support" component={Support} />
                <Route component={NotFound} />
              </Switch>
            </Box>
            <Box flexShrink={0}>
              <Footer colMode={true} />
            </Box>
          </Box>
          <SimpleSnackbar></SimpleSnackbar>
        </React.Fragment>
      </BrowserRouter>
    );
  }
}

import React, { useState } from "react";
import { Card } from "@material-ui/core";
import LoginForm from "./LoginForm";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../store";

const Login: React.FC<{}> = () => {
  const loggedIn = useSelector(
    (state: RootState) => state.auth.status.loggedIn
  );

  return (
    <>
      {!loggedIn && (
        <Card className={"mw-360 mx-auto mt-25vh"}>
          <LoginForm></LoginForm>
        </Card>
      )}
      {loggedIn && <Redirect push to="/" />}
    </>
  );
};

export default Login;

import React from "react";
import { connect } from "react-redux";

import AnnotationDataProvider from "./AnnotationDataProvider";

import { AnnotationFilterPanel } from "./AnnotationFilterPanel";
import { strings } from "../localization";

import ThemedBackground from "../common/ThemedBackground";
import { InfiniteScroll } from "../common/InfiniteScroll";
import { setSnackbar, clearSnackbar } from "../common/simpleSnackbarSlice";
import { clear } from "./annotationSlice";
import DeleteFAB from "../common/DeleteFAB";
import DownloadFAB from "../common/DownloadFAB";
import AnalyzeFAB from "./AnalyzeFAB";
import { AppDispatch, RootState } from "../store";

const mapState = (store: RootState) => ({
  annotationSelection: store.annotations.selection,
});
function mapDispatch(dispatch: AppDispatch) {
  return {
    showErrorSnackbar: (message: string) =>
      dispatch(setSnackbar({ message, mode: "error" })),

    showInfoSnackbar: (message: string) =>
      dispatch(setSnackbar({ message, mode: "info" })),

    clearSnackbar: () => dispatch(clearSnackbar()),
    clearAnnotationSelection: () => dispatch(clear()),
  };
}
class AnnotationRenderer extends ThemedBackground {
  dataProvider: AnnotationDataProvider;

  constructor(props) {
    super(props);

    this.state = {
      firstRender: false,
      preventClearingSelection: false,
    };

    this.dataProvider = new AnnotationDataProvider(
      this.props.showErrorSnackbar,
      this,
      this.setFirstRender.bind(this)
    );
    this.renderEmpty = this.renderEmpty.bind(this);
    this.refreshData = this.refreshData.bind(this);
  }

  refreshData(removedObjects: string[]) {
    const infiniteScroll = this.dataProvider.parentInfiniteScroll;
    const loadedData = infiniteScroll.getLoadedData();
    infiniteScroll.setLoadedData(
      loadedData.filter((data) => removedObjects.indexOf(data.id) === -1)
    );
    this.props.clearAnnotationSelection();
  }

  setFirstRender() {
    if (!this.state.firstRender) {
      this.setState({ firstRender: true });
    }
  }

  componentWillMount() {
    this.props.clearAnnotationSelection();
  }

  renderEmpty() {
    if (!this.dataProvider.hasFetched) {
      return <div />;
    }

    return (
      <div className="col-12 text-center">
        <div className="alert alert-primary w-100" role="alert">
          {strings.toxDB_projectOverview_unavailable}
        </div>
      </div>
    );
  }

  renderBody(themeData) {
    return (
      <React.Fragment>
        <div className="fab-container-top">
          <AnalyzeFAB selection={this.props.annotationSelection} />
          <DownloadFAB
            resourceType={"annotation"}
            selection={this.props.annotationSelection}
            refreshData={this.props.clearAnnotationSelection}
          />

          <DeleteFAB
            resourceType={"annotation"}
            selection={this.props.annotationSelection}
            refreshData={this.refreshData}
          />
        </div>

        <AnnotationFilterPanel
          registerFilters={this.dataProvider.applyFilters.bind(
            this.dataProvider
          )}
        />

        <InfiniteScroll
          dataProvider={this.dataProvider}
          renderEmpty={this.renderEmpty}
          autorefresh={false}
        />
      </React.Fragment>
    );
  }
}

export default connect(mapState, mapDispatch)(AnnotationRenderer);

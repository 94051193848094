import {
  TextField,
  Button,
  Card,
  CardContent,
  CardActions,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { getFirebaseTenantAuth } from "./firebaseConfig";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { FormWithLoadingSlider } from "../common/FormWithLoadingSlider";

const UpdatePasswordForm: React.FC<RouteComponentProps<{}>> = (props) => {
  const [password, setPassword] = React.useState("");
  const [passwordConfirm, setPasswordConfirm] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [errorAlert, setErrorAlert] = React.useState("");
  const [loading, isLoading] = React.useState(false);

  const firebaseAuth = getFirebaseTenantAuth();

  const displayError = (error: Error) => {
    setErrorMessage(error.message);
    isLoading(false);
  };

  const compareValues = () => {
    if (passwordConfirm !== "" && password !== passwordConfirm) {
      setErrorMessage("Password doesn't match");
    } else {
      setErrorMessage("");
    }
  };

  useEffect(() => compareValues(), [password, passwordConfirm]);
  const onSubmit = (e: HTMLFormEvent) => {
    e.preventDefault();
    isLoading(true);
    setErrorMessage("");
    const user = firebaseAuth.currentUser;
    if (user) {
      user
        .updatePassword(password)
        .then(() => props.history.push("/"), displayError);
    } else {
      isLoading(false);
      setErrorAlert(
        "You have to follow an invitation link to change your password"
      );
    }
  };

  const children = (
    <>
      <form onSubmit={onSubmit}>
        <CardContent>
          <TextField
            type="password"
            name="password"
            label="password"
            fullWidth
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
            label="confirm password"
            type="password"
            name="passwordConfirm"
            fullWidth
            required
            className="mt-4"
            value={passwordConfirm}
            helperText={errorMessage}
            error={!!errorMessage}
            onChange={(e) => setPasswordConfirm(e.target.value)}
          />
        </CardContent>
        <CardActions>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={"ml-auto"}
          >
            Validate
          </Button>
        </CardActions>
      </form>
    </>
  );

  return (
    <Card>
      <FormWithLoadingSlider
        loading={loading}
        children={children}
        errorMessage={errorAlert}
      />
    </Card>
  );
};

export default withRouter(UpdatePasswordForm);

import {
  GROUP_ADMIN,
  READABLE_PERMISSION,
  TOXPLOT_ADMIN,
  TOXPLOT_CREATE_ANALYSIS,
  TOXPLOT_CREATE_PROJECT,
  TOXPLOT_ESSENTIAL_USER,
  TOXPLOT_VIEW_PROJECT,
  USER_ADMIN,
  USER_DELETE,
  USER_EDIT,
  sortPermissions,
} from "./Permission";

import { getChildrenPermissions } from "../common/Authenticate";
import BaseDataModel from "./BaseDataModel";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import DeleteIcon from "@material-ui/icons/Delete";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import { INTERNET_ERROR_MSG } from "../ErrorMessages";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import MenuItem from "@material-ui/core/MenuItem";
import React from "react";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import getApiEndpoint from "../endpoint_configuration";
import CustomPermissionSelector from "./CustomPermissionSelector";
import { strings } from "../localization";
import { setSnackbar } from "../common/simpleSnackbarSlice";
import { connect } from "react-redux";
import { RootState, AppDispatch } from "../store";
import { remove } from "./userSlice";

const NO_RIGHT = "";

const mapState = (store: RootState) => {
  return {
    permissions: store.permissions,
  };
};
function mapDispatch(dispatch: AppDispatch) {
  return {
    showErrorSnackbar: (message: string) =>
      dispatch(setSnackbar({ payload: { message, mode: "error" } })),
    removeUser: (id: string) => dispatch(remove(id)),
  };
}
class User extends BaseDataModel {
  constructor(props) {
    super(props);

    this.permission = null;
    this.handleLockIcon = this.handleLockIcon.bind(this);
    this.handleDeleteIcon = this.handleDeleteIcon.bind(this);
    this.handleAddPermission = this.handleAddPermission.bind(this);
    this.handleDeletePermission = this.handleDeletePermission.bind(this);
    this.saveCustomPermissions = this.saveCustomPermissions.bind(this);
    this.handleClientGroupChange = this.handleClientGroupChange.bind(this);

    const customPerms = this.props.data.custom_permissions;

    this.state = {
      availableAnalysis: [],
      originalCustomRights: customPerms,
      customRights: customPerms,
      clientGroupId: this.props.data.client_group,
    };
  }

  handleLockIcon(evt) {
    if (!this.hasPermission("edit-users")) return;
    const endpointName = this.props.data.locked ? "user_unlock" : "user_lock";
    this.doRequest(endpointName);
  }

  handleDeleteIcon(evt) {
    if (!this.hasPermission("delete-users")) return;
    this.doRequest("user_remove")
    .then(() => this.props.update());
    this.props.removeUser(this.props.data.uid);
    
  }

  handleAddPermission(evt) {
    if (evt.target.value !== "none" && evt.target.value !== NO_RIGHT) {
      this.permission = evt.target.value;
      this.doRequest("user_add_permission");
    }
  }

  handleDeletePermission(permission) {
    return function (evt) {
      this.permission = permission;
      this.doRequest("user_remove_permission");
    }.bind(this);
  }

  modifyEndpoint(endpoint) {
    endpoint.bindUrlParameter("uid", this.props.data.uid);
    if (endpoint.getUrlParameters().indexOf("permission") !== -1) {
      endpoint.bindUrlParameter("permission", this.permission);
    }
  }

  static fillOpenPermissions(
    existingPermission,
    sourcePermission,
    openPermissions
  ) {
    getChildrenPermissions(sourcePermission)
      .filter((permission) => existingPermission.indexOf(permission) === -1)
      .forEach((permission) => openPermissions.push(permission));
  }

  getOpenPermissions(perm) {
    const openPermissions = [];
    if (this.hasPermission(TOXPLOT_ADMIN)) {
      User.fillOpenPermissions(perm, TOXPLOT_ADMIN, openPermissions);
    }
    if (this.hasPermission(GROUP_ADMIN)) {
      User.fillOpenPermissions(perm, GROUP_ADMIN, openPermissions);
    } else if (this.hasPermission(USER_ADMIN)) {
      User.fillOpenPermissions(perm, USER_ADMIN, openPermissions);
    }

    if (
      this.hasPermission(TOXPLOT_VIEW_PROJECT) &&
      this.hasPermission(TOXPLOT_CREATE_ANALYSIS)
    ) {
      User.fillOpenPermissions(perm, TOXPLOT_ESSENTIAL_USER, openPermissions);
    }

    if (this.hasPermission(TOXPLOT_CREATE_PROJECT)) {
      User.fillOpenPermissions(perm, TOXPLOT_CREATE_PROJECT, openPermissions);
    }

    return sortPermissions(openPermissions);
  }

  // Send to the server the custom permissions
  saveCustomPermissions(updatedCustomRight) {
    getApiEndpoint("user_permissions_custom_change")
      .bindUrlParameter("id", this.props.data.uid)
      .setBodyData(JSON.stringify(updatedCustomRight))
      .getFetchPromise()
      .catch(() => this.props.showErrorSnackbar(INTERNET_ERROR_MSG))
      .then((_) =>
        this.setState({
          originalCustomRights: this.state.customRights,
        })
      );
  }

  handleClientGroupChange(e) {
    const value = e.target.value ? e.target.value : null;
    getApiEndpoint("user_set_client_groups")
      .bindUrlParameter("id", this.props.data.uid)
      .setBodyData(JSON.stringify({ client_group_id: value }))
      .getFetchPromise()
      .catch(() => this.props.showErrorSnackbar(INTERNET_ERROR_MSG))
      .then((response) => response.json())
      .then((response) =>
        this.setState({
          clientGroupId: response.client_group ? response.client_group.id : "",
        })
      );
  }

  renderData(data) {
    const truePermissions = data.permissions;
    let allPerms = [];
    for (const perm of truePermissions) {
      const newPerms = getChildrenPermissions(perm);
      if (Array.isArray(newPerms)) {
        allPerms.push(...newPerms);
      } else {
        allPerms.push(newPerms);
      }
    }

    const mayHandlePermission =
      (this.hasPermission(USER_ADMIN) || this.hasPermission(TOXPLOT_ADMIN)) &&
      !data.current;
    const availablePermissions = mayHandlePermission
      ? this.getOpenPermissions(data.permissions)
      : [];
    const mayEdit = this.hasPermission(USER_EDIT) && !data.current;
    const mayDelete = this.hasPermission(USER_DELETE) && !data.current;

    const locked = data.locked
      ? strings.user_management_locked
      : strings.user_management_unlocked;
    const lockText = mayEdit
      ? data.locked
        ? strings.user_management_unlock
        : strings.user_management_lock
      : "";

    let lockButton = <LockOpenIcon></LockOpenIcon>;

    if (data.locked) {
      lockButton = <LockIcon></LockIcon>;
    }

    return (
      <Grid item xs={11}>
        <Card>
          <CardHeader
            title={
              data.email +
              " " +
              (data.current ? strings.user_management_you : "")
            }
            titleTypographyProps={{ variant: "h4" }}
            subheader={strings.user_management_id + " " + data.uid}
            action={
              <React.Fragment>
                <IconButton
                  size="small"
                  disabled={!mayEdit}
                  color="primary"
                  title={`${strings.user_management_accountStatus} ${locked}\n${lockText}`}
                  onClick={this.handleLockIcon}
                >
                  {lockButton}
                </IconButton>
                {mayDelete && (
                  <IconButton
                    size="small"
                    disabled={!mayDelete}
                    onClick={this.handleDeleteIcon}
                    style={{ color: "red" }}
                    title={strings.user_management_delete}
                  >
                    <DeleteIcon></DeleteIcon>
                  </IconButton>
                )}
              </React.Fragment>
            }
          ></CardHeader>
          <CardContent>
            <Grid container spacing={3}>
              {(data.permissions.length > 0 ||
                availablePermissions.length > 0) && (
                <Grid item xs={12} lg={4}>
                  {this.props.canListGroups && !data.current && (
                    <FormControl fullWidth variant="outlined">
                      <InputLabel shrink id="select-clientgroup-label">
                        Client
                      </InputLabel>
                      <Select
                        displayEmpty
                        labelId="select-clientgroup-label"
                        id="select-clientgroup"
                        labelWidth={50}
                        onChange={this.handleClientGroupChange}
                        value={this.state.clientGroupId}
                      >
                        <MenuItem value="" key="">
                          No group
                        </MenuItem>
                        {this.props.clientGroups.map((group) => (
                          <MenuItem value={group.id} key={group.id}>
                            {group.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  <Typography variant="h5" component="h3">
                    {strings.user_management_permissions}
                  </Typography>

                  <List>
                    {sortPermissions(allPerms).map((permission) => {
                      const inheritedPermission =
                        truePermissions.indexOf(permission) === -1;
                      const translated = READABLE_PERMISSION[permission];
                      const deleteHandler = this.handleDeletePermission(
                        permission
                      );
                      return (
                        <ListItem
                          key={permission}
                          disabled={inheritedPermission}
                        >
                          {translated}
                          {inheritedPermission && (
                            <span>{strings.user_management_inherited}</span>
                          )}
                          {!inheritedPermission && mayHandlePermission && (
                            <ListItemSecondaryAction>
                              <IconButton
                                size="small"
                                edge="end"
                                aria-label={
                                  strings.user_management_removePerm +
                                  " " +
                                  translated
                                }
                                onClick={deleteHandler}
                              >
                                <DeleteIcon></DeleteIcon>
                              </IconButton>
                            </ListItemSecondaryAction>
                          )}
                        </ListItem>
                      );
                    })}
                  </List>
                  {availablePermissions.length > 0 && (
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="add-permission-label">
                        Add permission
                      </InputLabel>
                      <Select
                        labelId="add-permission-label"
                        id="add-permission"
                        labelWidth={110}
                        onChange={this.handleAddPermission}
                        value=""
                      >
                        {availablePermissions.map((permission) => (
                          <MenuItem value={permission} key={permission}>
                            {READABLE_PERMISSION[permission]}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Grid>
              )}
              {this.props.canAdminUsers && !data.current && (
                <Grid item xs={12} lg={8}>
                  <CustomPermissionSelector
                    availableRows={this.props.availableRows}
                    customPermissions={this.props.data.custom_permissions}
                    callback={this.saveCustomPermissions}
                  ></CustomPermissionSelector>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
  }
}

export default connect(mapState, mapDispatch)(User);

import Abstract from "./Abstract";
import ConfigurationError from "./ConfigurationError";
import React from "react";
import ReactLoading from "react-loading";
import ThemedComponent from "./Theme";

/**
 * The DataProvider contains a bunch of methods for fetching data asynchronously which
 * then can be rendered by a component containing a single object in the fetched array.
 *
 * As long as the getData method does not return an empty array, the InfiniteScroll
 * will call fetchAsyncData(), calls poll() until this returns true and finally retrieves
 * the data by calling getData().
 */
export class DataProvider extends Abstract {
  constructor() {
    super("DataProvider");
    this.parentInfiniteScroll = null;
    this.applyFilters = this.applyFilters.bind(this);
    this.filters = [];
    this.offset = 1;

    this.renderComponent = this.renderComponent.bind(this);
  }
  applyFilters(filters) {
    if (JSON.stringify(filters) !== JSON.stringify(this.filters)) {
      this.offset = 1;
      this.filters = filters;
      this.parentInfiniteScroll.setLoadedData([]);
      this.parentInfiniteScroll.fetchNewData();
    }
  }
  bindInfiniteScroll(infiniteScroll) {
    this.parentInfiniteScroll = infiniteScroll;
  }

  /**
   * Start fetching the next window of data
   */
  fetchAsyncData() {
    Abstract.method();
  }

  /**
   * Checks whether the last asynchronous request has been finished.
   * @return Boolean Whether the asynchronous is done (true) or not (false)
   */
  poll() {
    Abstract.method();
  }

  /**
   * Retrieves the data which was fetched previously.
   * @return Array An array of objects which can be rendered
   */
  getData() {
    Abstract.method();
  }

  /**
   * Renders a single JSON object which was fetched earlier. Make sure that the
   * root element contains the "key" (https://reactjs.org/docs/lists-and-keys.html#keys)
   * attribute.
   *
   * @param dataObject The JSON object to render
   * @return JSX A JSX string representing the rendered item
   */
  renderComponent(dataObject) {
    Abstract.method();
  }
}

/**
 * The class which actuall handles scrolling infinitely (or until the end has
 * reached of the data source)
 *
 * Available properties:
 * -    dataProvider (required): An instance DataProvider
 * -    registerScrollCallback (optional): A function which registers a for a scroll event.
 *          This should be used when a custom parent is used. When not used this component
 *          will create a parent of it"s own which can be styled using the styleProperties
 *          property.
 * -    styleProperties (optional): styleProperties which should be used by the generated
 *          parent.
 */
export class InfiniteScroll extends ThemedComponent {
  static POLL_TIMEOUT = 150;
  static LOAD_THRESHOLD = 0.75;

  constructor(props) {
    super(props);

    this.state = {
      data: [],
      polling: false,
    };

    this.dataProvider = this.props.dataProvider;
    this.dataProvider.bindInfiniteScroll(this);

    this.stopPolling = false;
    this.pollInterval = null;
    this.refresher = null;
    this.handleScroll = this.handleScroll.bind(this);

    if (!this.dataProvider) {
      throw new ConfigurationError("dataProvider property not provided");
    }
    if (!(this.dataProvider instanceof DataProvider)) {
      throw new ConfigurationError(
        "dataProvider is not an instance of dataProvider"
      );
    }

    // Bound methods
    this.getLoadedData = this.getLoadedData.bind(this);
    this.setLoadedData = this.setLoadedData.bind(this);
    this.pollDataProvider = this.pollDataProvider.bind(this);
  }

  getLoadedData() {
    return [...this.state.data];
  }

  setLoadedData(data, callback) {
    this.stopPolling = false;
    if (this.props.sortFunction) {
      data.sort(this.props.sortFunction);
    }

    this.setState({ data: data }, callback);

    if (this.state.data.some((el) => el.analysis_status === "analyzing")) {
      this.activateAutoRefresh();
    }
  }
  activateAutoRefresh() {
    if (this.props.autorefresh) {
      this.refresher = setInterval((_) => {
        if (this.state.data.some((el) => el.analysis_status === "analyzing")) {
          window.location.reload();
        } else {
          clearInterval(this.refresher);
        }
      }, 60000);
    }
  }

  handleScroll(event) {
    if (this.state.polling) {
      return;
    }

    if (
      window.innerHeight + window.scrollY >=
      document.body.offsetHeight * InfiniteScroll.LOAD_THRESHOLD
    ) {
      this.fetchNewData();
      const percentage =
        event.target.scrollTop /
        (event.target.scrollHeight - event.target.clientHeight);
      if (percentage >= InfiniteScroll.LOAD_THRESHOLD) {
      }
    }
  }
  componentDidMount() {
    this.fetchNewData();
    window.addEventListener("scroll", this.handleScroll);
    this.activateAutoRefresh();
  }

  componentWillUnmount() {
    if (this.refresher) {
      clearInterval(this.refresher);
    }
    window.removeEventListener("scroll", this.handleScroll);
  }
  /**
   * Polls the data and updates state
   */
  pollDataProvider(update = false) {
    const stillFetching = this.dataProvider.poll();
    if (!stillFetching) {
      clearInterval(this.pollInterval);
      this.pollInterval = null;

      const data = this.dataProvider.getData();
      const dataLength = typeof data === "undefined" ? 0 : data.length;

      this.stopPolling = dataLength === 0;
      if (dataLength === 0) {
        this.setState({ polling: false });
        return false;
      }

      this.setState({
        polling: false,
        data: [...this.state.data, ...data],
      });
      if (this.state.data.some((el) => el.analysis_status === "analyzing")) {
        this.activateAutoRefresh();
      }
    } else {
      if (!this.pollInterval) {
        this.pollInterval = setInterval(
          this.pollDataProvider,
          InfiniteScroll.POLL_TIMEOUT
        );
      }
    }
  }

  /**
   * Fetches the new data from the DataProvider
   */
  fetchNewData() {
    if (!this.state.polling && !this.stopPolling && !this.dataProvider.poll()) {
      const _this = this;
      this.setState({ polling: true }, () => {
        _this.dataProvider.fetchAsyncData();
        _this.pollDataProvider();
      });
    }
  }

  themedRender(themeData) {
    let render = (
      <div className="col-12">
        <div className="row">
          {this.state.data.map(this.dataProvider.renderComponent)}
          {this.state.polling && (
            <div className="col-12 flex-h-center">
              <ReactLoading color={themeData.body.secondaryBackground} />
            </div>
          )}
        </div>
      </div>
    );

    if (this.state.data.length === 0) {
      render = (this.props.renderEmpty || function () {})();
    }

    if (this.props.wrapRender) {
      return this.props.wrapRender(render, this);
    }
    return render;
  }
}

import React from "react";

import getApiEndpoint from "../endpoint_configuration";
import Abstract from "../common/Abstract";

export default class BaseDataModel extends React.PureComponent {
  hasPermission(permission) {
    return this.props.permissions.includes(permission);
  }

  doRequest(endpointName) {

    const endpoint = getApiEndpoint(endpointName);
    this.modifyEndpoint(endpoint);
    return endpoint
      .getFetchPromise()
      .catch(() =>
        // TODO: Add error message with snackbar
        //this.props.notificationManager.current.show(
        //   INTERNET_ERROR_MSG,
        //   "danger",
        //   removeOverlay
        // )
        {}
      )
      .then((response) => {
        if (!response.ok) return new Promise((resolve, reject) => reject());
        return response.json();
      })
      .then((data) => {
        this.props.update(removeOverlay);
      })
      .catch(() =>
        // TODO: Add snackbar message
        // this.props.notificationManager.current.show(
        //   SERVER_FAILED_MSG,
        //   "danger",
        //   removeOverlay
        // )
        {}
      );
    //   }
    // );
  }

  modifyEndpoint(endpoint) {}

  renderData(data) {
    Abstract.method();
  }

  render() {
    return this.renderData(this.props.data);
  }
}

import ConfigurationError from "./ConfigurationError";


/**
 * An abstract class which checks whether the class was instantiated directly
 * or as a subclass. When instantiated as subclass, this will throw a
 * TypeError.
 */
export default class Abstract {

    /**
     * Checks whether the current class is the abstract class or not
     *
     * @param className The name of the abstract class to check for
     * @throws ReferenceError When the abstract class is instantiated
     */
    constructor(className) {
        if (!Boolean(className)) {
            throw new ConfigurationError("Classname was not defined!");
        }

        if (Object.prototype.toString.call(this).slice(8, -1) === className) {
            throw new ReferenceError("Cannot instantiate new abstract class " + className);
        }
    }

    /**
     * Method which raises an exception which marks a method as abstract
     */
    static method() {
        throw new ReferenceError("Cannot call abstract method");
    }

}

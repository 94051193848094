import React from "react";
import ReactLoading from "react-loading";

import ConfigurationError from "../../common/ConfigurationError";
import { strings } from "../../localization";

let instanceCounter = 0;


export default class ScrollableComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedOptions: {},
            all: false,
            query: "",
            queryInput: "",
            offset: null
        };

        this.onChange = this.onChange.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.toggleAll = this.toggleAll.bind(this);
        this.onScroll = this.onScroll.bind(this);
        this.container = React.createRef();
        this.appliedInitiallySelected = false;
        this.instanceId = instanceCounter++;

        this.searchTimeout = null;

        if (!this.props.addOption) throw new ConfigurationError("Need an addOption property!");
        if (!this.props.removeOption) throw new ConfigurationError("Need a removeOption property!");
    }

    toggleAll(evt) {
        const selectAll = evt.target.checked;
        const copy = Object.assign({}, this.state.selectedOptions);

        const changedEntries = [];
        (this.props.values || this.props.options).forEach(key => {
            if (copy[key] !== selectAll) {
                copy[key] = selectAll;
                changedEntries.push(key);
            }
        });

        this.setState({all: selectAll, selectedOptions: copy}, () => {
            if (selectAll) {
                this.props.addOption(changedEntries);
                if (this.props.notifySearch) {
                    this.props.notifySearch();
                }
            } else {
                this.props.removeOption(changedEntries);
            }
        });
    }

    onChange(evt) {
        this.updateSelection(evt.target.checked, evt.target.name);
    }

    updateSelection(checked, ...names) {
        const copy = Object.assign({}, this.state.selectedOptions);
        names.forEach((name) => copy[name] = checked);

        const values = Object.values(copy);
        const allSelected = (
            values.length === this.props.options.length &&
            values.every(selected => selected)) &&
            values.length > 0;

        this.setState({selectedOptions: copy, all: allSelected}, () => {
            if (checked) {
                this.props.addOption(names);
            } else {
                this.props.removeOption(names);
            }
        });
    }

    onSearch(evt) {
        const query = evt.target.value;
        clearTimeout(this.searchTimeout);

        this.setState({queryInput: query}, () => {
            this.searchTimeout = setTimeout(() => {
                this.setState({query: query});
                if (this.props.notifySearch) {
                    this.props.notifySearch();
                }
            }, 750);
        });

    }

    componentDidUpdate(prevProps) {
        if (this.state.offset && this.container.current) {
            this.container.current.scrollTop = this.state.offset;
        }

        // Remove the options upstream from the removed options
        const previousOptions = prevProps.values || prevProps.options;
        const currentOptions = this.props.values || this.props.options;
        if (currentOptions.length < previousOptions.length) {
            const removedOptions = previousOptions.filter(value => currentOptions.indexOf(value) === -1);
            this.props.removeOption(removedOptions);
            return;
        }
        // Select all newly added options
        else if (currentOptions.length > previousOptions.length && this.state.all && this.props.includeAll) {
            const newValues = currentOptions.filter(value => previousOptions.indexOf(value) === -1);
            const copy = Object.assign({}, this.state.selectedOptions);
            newValues.forEach(value => {
                copy[value] = true;
            });
            this.setState({selectedOptions: copy}, () => this.props.addOption(newValues));
            return;
        }

        // Select the intially selected options (only once)
        if (!this.appliedInitiallySelected && (this.props.initiallySelected || []).length > 0) {
            this.appliedInitiallySelected = true;
        } else {
            return;
        }
        this.updateSelection(true, ...this.props.initiallySelected);
    }

    onScroll(evt) {
        if (this.props.onScroll) {
            this.props.onScroll(evt);
        }

        this.setState({offset: evt.target.scrollTop});
    }

    render() {
        if (this.props.loading) {
            return (
                <div className={"content flex-center " + (this.props.className || "")} style={this.props.style}>
                    <div className="full-width">
                        <div className="flex-h-center">
                            <ReactLoading color={this.props.loadingColor || "green"}/>
                        </div>
                    </div>
                </div>
            );
        }

        if (this.props.options.length === 0) {
            return (
                <div className={this.props.className} style={this.props.style}>
                    <span>{this.props.emptyMessage}</span>
                </div>
            );
        }

        let options = this.props.options;
        let values = this.props.values;
        if (this.state.query !== "") {
            const validIndices = {};

            const query = this.state.query.toLowerCase();
            (values || options).forEach((value, idx) => {
                value = value.toLowerCase();
                if (value.indexOf(query) !== -1 || query.indexOf(value) !== -1) {
                    validIndices[idx] = true;
                }
            });
            options = options.filter((value, idx) => validIndices[idx]);
            if (values) {
                values = values.filter((value, idx) => validIndices[idx]);
            }
        }

        return (
            <React.Fragment>
                { this.props.searchable &&
                    <input type="text" value={this.state.queryInput} onChange={this.onSearch} className="form-control mb-1" placeholder={strings.toxDB_createAnalysis_search} />
                }
                <ul className={"list-group " + (this.props.className || "")} style={this.props.style} onScroll={this.onScroll} ref={this.container}>
                    { (this.props.includeAll && this.state.query === "") &&
                        <li className={"list-group-item" + (this.state.all ? " active" : "")}>
                            <input type="checkbox" name="all" className="mr-1" onChange={this.toggleAll} checked={this.state.all} id={`sc-li-all-${this.instanceId}`}/>
                            <label htmlFor={`sc-li-all-${this.instanceId}`}>{strings.toxDB_createAnalysis_all}</label>
                        </li>
                    }
                    {
                        options.map((option, idx) => {
                            const name = values ? values[idx] : option;
                            return (
                                <li className={"list-group-item" + (this.state.selectedOptions[name] ? " active" : "")} key={name}>
                                    <div className="form-check">
                                        <input type="checkbox" name={name} className="form-check-input" onChange={this.onChange} checked={!!this.state.selectedOptions[name]} id={`sc-li-${name}`}/>
                                        <label className="form-check-label full-width text-truncate" htmlFor={`sc-li-${name}`} title={name}>{option}</label>
                                    </div>
                                </li>
                            );
                        })
                    }
                </ul>
            </React.Fragment>

        );
    }

}

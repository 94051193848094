import React from "react";

import { INTERNET_ERROR_MSG, SERVER_FAILED_MSG } from "../ErrorMessages";

import getApiEndpoint from "../endpoint_configuration";
import ThemedComponent from "../common/ThemedBackground";
import { strings } from "../localization";

import "../resources/css/annotation/FilterPanel.css";
import { setSnackbar } from "../common/simpleSnackbarSlice";
import { connect } from "react-redux";
import { AppDispatch, RootState } from "../store";

import Filter, { getFilterDefaultValue } from "./Filter";
import { add, set } from "./filterSlice";

type ModeSelect = {
  type: "select";
  options: string[];
  values?: string[];
};
type ModeText = { type: "text" };
export type Modes = {
  [x: string]: (ModeSelect | ModeText)[];
};
export type DataFetchers = DataFetcher[];
type DataFetcher = { key: string; index: string; endpoint: string };
function getInitialData(modes: Modes) {
  const mode = Object.keys(modes)[0];
  return {
    mode,
    values: getFilterDefaultValue(mode, modes),
  };
}

const mapState = (state: RootState) => {
  return {
    filters: state.filters.data,
  };
};
function mapDispatch(dispatch: AppDispatch) {
  return {
    showErrorSnackbar: (message: string) =>
      dispatch(setSnackbar({ payload: { message, mode: "error" } })),
    pushFilter: (filter) => dispatch(add(filter)),
    setFilter: (filter) => dispatch(set(filter)),
  };
}
class FilterPanel extends ThemedComponent {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
      modes: props.modes,
    };

    this.toggleExpand = this.toggleExpand.bind(this);
    this.addFilter = this.addFilter.bind(this);
    this.props.setFilter([]);
  }

  componentDidMount() {
    const promises = this.props.fetchers?.map((item) =>
      getApiEndpoint(item.endpoint).getFetchPromise()
    );

    Promise.all(promises)
      .catch(() => this.props.showErrorSnackbar(INTERNET_ERROR_MSG))
      .then((results) => {
        if (!results.every((result) => result.ok)) {
          this.props.showErrorSnackbar(SERVER_FAILED_MSG);
          return Promise.reject(new Error("Invalid request found"));
        }
        return Promise.all(results.map((response) => response.json()));
      })
      .then((results) => {
        if (!results.every((response) => response.status === "ok")) {
          this.props.showErrorSnackbar(SERVER_FAILED_MSG);
          return Promise.reject(new Error("Invalid response found"));
        }

        this.props.fetchers.forEach((fetcher: DataFetcher, idx) => {
          this.setState((state) => {
            state.modes[fetcher.key][fetcher.index]["options"] =
              results[idx].data;
            return state;
          });
        });
      })
      .catch(console.debug);
  }

  addFilter() {
    this.props.pushFilter(getInitialData(this.state.modes));
  }

  generateFilters() {
    const filters = [];
    this.props.filters.forEach((filter) => {
      const baseObject = { type: filter.mode };

      if (filter.mode === "title") {
        const query = filter.values[1];

        if (query.length !== 0) {
          baseObject.query = query;
          filters.push(Object.assign(baseObject, JSON.parse(filter.values[0])));
        }
      } else if (filter.mode === "status") {
        const query = filter.values[0];
        if (query.length !== 0) {
          filters.push({
            ...baseObject,
            query,
            negated: false,
            word_mode: false,
          });
        }
      } else if (filter.mode === "compound" || filter.mode === "tag") {
        const values = filter.values;
        filters.push(
          Object.assign(baseObject, { value: values[0], mode: values[1] })
        );
      }
    });
    return filters;
  }

  toggleExpand() {
    let callback = null;
    if (this.state.expanded) {
      // About to be not expanded
      callback = () => this.props.registerFilters(this.generateFilters());
    }
    this.setState({ expanded: !this.state.expanded }, callback);
  }

  render() {
    return (
      <React.Fragment>
        <div
          className={
            "filter-icon pointer" + (this.state.expanded ? " expanded" : "")
          }
          title={strings.toxDB_projectOverview_filter}
          onClick={this.toggleExpand}
        >
          <i className="fa fa-filter" />
        </div>
        <div
          className={
            "filter-panel auto-y-overflow" +
            (this.state.expanded ? " expanded" : "")
          }
        >
          <div className="container-fluid">
            {this.props.filters.map((filter, idx) => (
              <div
                className="row mt-1"
                key={idx.toString() + JSON.stringify(filter)}
              >
                <Filter modes={this.state.modes} index={idx} />
              </div>
            ))}
            <div className="row mt-1">
              <div className="col-12">
                <button
                  className="btn btn-primary full-width"
                  onClick={this.addFilter}
                >
                  <i className="fa fa-add" />
                  {strings.toxDB_projectOverview_addFilter}
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect(mapState, mapDispatch)(FilterPanel);

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import { getChildrenPermissions } from "../common/Authenticate";

import { READABLE_PERMISSION, TOXPLOT_ESSENTIAL_USER } from "./Permission";
import React from "react";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import getApiEndpoint from "../endpoint_configuration";
import { strings } from "../localization";
import { PUBLIC_URL } from "../ApiEndpoint";
import CustomPermissionSelector from "./CustomPermissionSelector";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import { setSnackbar } from "../common/simpleSnackbarSlice";
import { useDispatch } from "react-redux";
import { INTERNET_ERROR_MSG, SERVER_FAILED_MSG } from "../ErrorMessages";
import { add } from "./userSlice";
import { FormWithLoadingSlider } from "../common/FormWithLoadingSlider";

// eslint-disable-next-line
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export default function FormDialog(props) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [disabled, isDisabled] = React.useState(false);
  const [loading, isLoading] = React.useState(false);
  const [errorMessage, setError] = React.useState("");
  const [clientGroup, setClientGroup] = React.useState(null);
  const [assignedPerms, setAssignedPerms] = React.useState(
    getChildrenPermissions(TOXPLOT_ESSENTIAL_USER)
  );
  const [customPermissions, setCustomPermissions] = React.useState([]);
  const showErrorSnackbar = (message: string) =>
    dispatch(setSnackbar({ message, mode: "error" }));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function changeEmail(evt) {
    isDisabled(!EMAIL_REGEX.test(evt.target.value));
    let error = "";
    if (props.emailPool.includes(evt.target.value)) {
      isDisabled(true);
      error = strings.user_management_inviteError;
    }

    setEmail(evt.target.value);
    setError(error);
  }

  function changeClientGroup(evt) {
    const value = evt.target.value;
    setError("");
    setClientGroup(value);
  }

  function inviteUser() {
    isLoading(true);
    const body = {
      email,
      redirect_uri: PUBLIC_URL,
      permissions: assignedPerms,
      custom_permissions: customPermissions,
    };
    if (clientGroup) {
      body["client_group"] = clientGroup;
    }

    getApiEndpoint("user_add")
      .setBodyData(JSON.stringify(body))
      .getFetchPromise()
      .then((response) => {
        if (response.ok) {
          setOpen(false);
        } else {
          return response.json();
        }
      })
      .then((response) => {
        if (response) {
          setError(response.error);
        } else {
          props.update();
        }
        isLoading(false);
      })
      .catch((error) => {
        console.error(error);
        showErrorSnackbar(SERVER_FAILED_MSG);
        isLoading(false);
      });
  }

  function handleToggle(value) {
    const currentIndex = assignedPerms.indexOf(value);
    let newAssignedPerms = [...assignedPerms];

    if (currentIndex === -1) {
      newAssignedPerms.push(value);
    } else {
      newAssignedPerms.splice(currentIndex, 1);
    }

    setAssignedPerms(newAssignedPerms);
  }

  function openDialog() {
    setOpen(true);
  }
  const dialogContent = (
    <>
      <DialogTitle id="form-dialog-title">
        {strings.user_management_add_invite}
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          id="invite-email"
          onChange={changeEmail}
          name={email}
          fullWidth
          required
          margin="normal"
          label={strings.user_management_add_email}
          variant="outlined"
        />
        {props.clientGroups && props.clientGroups.length >= 1 && (
          <FormControl fullWidth variant="outlined">
            <InputLabel id="client-group-select-label">Client</InputLabel>
            <Select
              labelId="client-group-select-label"
              id="client-group-select"
              value={clientGroup}
              onChange={changeClientGroup}
              labelWidth={45}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {props.clientGroups.map((clientGroup) => (
                <MenuItem value={clientGroup.id}>{clientGroup.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <List style={{ columnCount: 2 }}>
          {props.permissions.map((value) => {
            const labelId = `checkbox-list-label-${value}`;
            return (
              <ListItem
                key={value}
                dense
                button
                onClick={(_) => handleToggle(value)}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={assignedPerms.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={READABLE_PERMISSION[value]}
                />
              </ListItem>
            );
          })}
        </List>
        <CustomPermissionSelector
          availableRows={props.availableRows}
          customPermissions={props.customPermissions}
          callback={setCustomPermissions}
        ></CustomPermissionSelector>
        {errorMessage && (
          <span className="d-block alert alert-warning mt-2">
            {errorMessage}
          </span>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button disabled={disabled} onClick={inviteUser} color="primary">
          {strings.user_management_add_invite}
        </Button>
      </DialogActions>
    </>
  );

  return (
    <div>
      <button className="btn btn-primary full-width" onClick={openDialog}>
        {strings.user_management_add}
      </button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <FormWithLoadingSlider
          loading={loading}
          children={dialogContent}
          errorMessage={""}
        />
      </Dialog>
    </div>
  );
}

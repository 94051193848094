import React, { useState } from "react";
import { Card } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { ResetPasswordForm } from "./ResetPasswordForm";

const ResetPassword: React.FC<{}> = () => {
  const loggedIn = useSelector(
    (state: RootState) => state.auth.status.loggedIn
  );

  return (
    <>
      {!loggedIn && (
        <Card className={"mw-360 mx-auto mt-25vh"}>
          <ResetPasswordForm />
        </Card>
      )}
      {loggedIn && <Redirect push to="/" />}
    </>
  );
};

export default ResetPassword;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FilterValue } from "./Filter";

const filterSlice = createSlice<{ lastIndex: number; data: FilterValue[] }>({
  name: "filter",
  initialState: { lastIndex: -1, data: [] },

  reducers: {
    set: (state, action: PayloadAction<FilterValue[]>) =>
      (state = { lastIndex: state.data.length - 1, data: action.payload }),
    clear: (state) => (state = { lastIndex: -1, data: [] }),
    add: (state, action: PayloadAction<any>) => {
      state.data.push(action.payload);
      state.lastIndex = state.data.length - 1;
    },
    remove: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        data: state.data.filter((_, id) => id != action.payload),
      };
    },
    replace: (state, action: PayloadAction<{ id: number; data: any }>) => {
      const copiedData = [...state.data];
      copiedData[action.payload.id] = action.payload.data;
      return { lastIndex: action.payload.id, data: copiedData };
    },
  },
});

export const { set, add, clear, remove, replace } = filterSlice.actions;
export default filterSlice.reducer;

import "rc-slider/assets/index.css";
import "../../resources/css/toxplot_analysis/CreateAnalysis.css";

import { BatchRequest, defaultJsonResponse } from "../../ApiEndpoint";
import { MAX_CONCURRENT_ANALYSES } from "../../constants";
import { INTERNET_ERROR_MSG, SERVER_FAILED_MSG } from "../../ErrorMessages";
import Slider, { createSliderWithTooltip } from "rc-slider";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";

import DragHandle from "../../common/DragHandle";
import { InfiniteScroll } from "../../common/InfiniteScroll";
import Overlay from "../../common/Overlay";
import React from "react";
import ScrollableComponent from "./ScrollableComponent";
import ThemedBackground from "../../common/ThemedBackground";
import getApiEndpoint from "../../endpoint_configuration";
import { strings } from "../../localization";
import { setSnackbar } from "../../common/simpleSnackbarSlice";
import { connect } from "react-redux";

const MAX_ITEMS = 100;
const SLIDER_LABELS = {};
const CHECK_INTERVAL = 4500;
const DEFAULT_ORDERED_REPORTERS = [
  "Bscl2",
  "Rtkn",
  "Btg2",
  "Srxn1",
  "Blvrb",
  "Ddit3",
];

const SliderWithTooltip = createSliderWithTooltip(Slider);

for (let i = 0; i <= 1; i += 0.1) {
  SLIDER_LABELS[i] = i.toFixed(1);
}

const SortableItem = SortableElement(({ item, idx, locked }) => (
  <li className="col-12 mt-1 d-block" title={item}>
    <div className="d-inline-block">
      {!locked && <DragHandle />}
      <span className="badge badge-secondary mr-1">{idx + 1}</span>
      <span>{item}</span>
    </div>
  </li>
));

const SortableItems = SortableContainer(({ items, disabled }) => (
  <ul className="full-width pl-0 mb-0">
    {items.map((item, idx) => (
      <SortableItem
        item={item}
        index={idx}
        idx={idx}
        key={item}
        disabled={disabled}
        locked={disabled}
      />
    ))}
  </ul>
));

const mapState = (store) => {
  return {
    annotationSelection: store.annotations.selection,
  };
};
function mapDispatch(dispatch) {
  return {
    showErrorSnackbar: (message: string) =>
      dispatch(setSnackbar({ message, mode: "error" })),
  };
}
class CreateAnalysis extends ThemedBackground {
  constructor(props) {
    super(props);

    this.project = {
      offset: 1,
      saturated: false,
      fetching: false,
    };

    this.state = {
      projects: [],
      selectedProjects: [],
      initiallySelectedProjects: [],
      // Compounds
      compounds: [],
      compoundNames: [],
      selectedCompounds: [],
      // Compound order
      orderCompoundsMode: "none",
      compoundOrder: [],
      dendrogramIC: 0.5,
      compoundOrderCollapsed: true,
      // Reporters
      reporters: [],
      selectedReporters: [],
      // Reporter order
      orderReportersMode: "none",
      reporterOrder: [],
      reporterOrderCollapsed: true,
      // Loading settings
      projectLoading: true,
      metaLoading: false,
      // Misc analysis settings
      exportRawData: false,
      markNonEstimatedGFP: false,
      maxHeatmap: "10",
      // Analysis IC's
      RelCellSurv: [0.9, 0.75, 0.5],
      // Misc
      analysisName: "",
    };

    this.manuallySelectedReporters = false;

    this.compoundCollapseButton = React.createRef();
    this.reporterCollapseButton = React.createRef();

    this.addedProjects = new Set();
    this.analysisId = null;

    this.fetchProjects = this.fetchProjects.bind(this);
    this.fetchCompoundsAndReporters = this.fetchCompoundsAndReporters.bind(
      this
    );
    this.fetchAll = this.fetchAll.bind(this);

    this.addProject = this.addProject.bind(this);
    this.removeProject = this.removeProject.bind(this);
    this.addReporter = this.addReporter.bind(this);
    this.removeReporter = this.removeReporter.bind(this);
    this.onProjectScroll = this.onProjectScroll.bind(this);

    this.addAnalysis = this.addAnalysis.bind(this);
    this.runAnalyses = this.runAnalyses.bind(this);
    this.waitForAnalyses = this.waitForAnalyses.bind(this);
    this.onAnalysisNameChange = this.onAnalysisNameChange.bind(this);
    this.display_server_error_message = this.display_server_error_message.bind(
      this
    );
  }

  fetchAll() {
    const promise = this.fetchProjects(true);
    this.project.saturated = true;
    return promise;
  }

  fetchProjects(forceAll) {
    if (this.project.saturated)
      return new Promise((resolve, reject) => resolve());
    this.project.fetching = true;

    const endpoint = getApiEndpoint("get_filtered_projects")
      .bindQueryParameter("offset", this.project.offset)
      .setBodyData(
        JSON.stringify({
          keys: ["name", "id"],
        })
      );

    if (!forceAll) endpoint.bindQueryParameter("max_items", MAX_ITEMS);

    let promiseFuncs = {};
    const promise = new Promise(
      (resolve, reject) =>
        (promiseFuncs = {
          resolve: resolve,
          reject: reject,
        })
    );
    this.setState(
      {
        projectLoading: true,
      },
      () => {
        endpoint
          .getFetchPromise(this)
          .catch(() => {
            this.props.showErrorSnackbar(INTERNET_ERROR_MSG);
            if (promiseFuncs.reject) promiseFuncs.reject();
          })
          .then(defaultJsonResponse(this.props.showErrorSnackbar))
          .then((jsonData) => {
            if (jsonData.data.length === 0) {
              this.project.saturated = true;
              if (promiseFuncs.resolve) promiseFuncs.resolve();
              this.setState(
                {
                  projectLoading: false,
                },
                () => {
                  this.project.fetching = false;
                }
              );
              return;
            }

            this.project.offset += MAX_ITEMS;
            const newProjects = jsonData.data.filter((project) => {
              const alreadyHas = this.addedProjects.has(project.id);
              if (!alreadyHas) {
                this.addedProjects.add(project.id);
              }
              return !alreadyHas;
            });
            const projects = [...this.state.projects, ...newProjects];

            this.setState(
              {
                projects: projects,
                projectLoading: false,
              },
              () => {
                this.project.fetching = false;
              }
            );

            if (promiseFuncs.resolve) promiseFuncs.resolve();
          })
          .catch((data) => {
            console.debug(data);
            if (promiseFuncs.reject) promiseFuncs.reject();
          });
      }
    );
    return promise;
  }

  fetchCompoundsAndReporters() {
    const compoundMap = {};
    let reporters = new Set();
    const addReporter = reporters.add.bind(reporters);

    let resolvedInstances = 0;
    const finalize = () => {
      const compoundNames = Object.keys(compoundMap);
      compoundNames.sort();

      const compounds = compoundNames.map((compound) => (
        <React.Fragment>
          <span>{compound}</span>
          <span className="badge badge-secondary float-right">
            {compoundMap[compound]}
          </span>
        </React.Fragment>
      ));

      reporters = Array.from(reporters);
      reporters.sort();

      const newState = {
        compounds: compounds,
        compoundNames: compoundNames,
        reporters: reporters,
        metaLoading: false,
      };

      let callback = () => 1;
      if (!this.manuallySelectedReporters) {
        // Check if the default reporters are available
        const allAvailable = DEFAULT_ORDERED_REPORTERS.every(
          (reporter) => reporters.indexOf(reporter) !== -1
        );
        if (allAvailable) {
          newState.selectedReporters = DEFAULT_ORDERED_REPORTERS;
          newState.orderReportersMode = "reporter-order";

          if (this.state.reporterOrderCollapsed) {
            callback = () => this.reporterCollapseButton.current.click();
          }
        }
      }
      this.setState(newState, callback);
    };

    const batchRequest = new BatchRequest().requiresAuthentication();
    this.state.selectedProjects.forEach((projectId) => {
      const endpoint = getApiEndpoint("get_meta")
        .bindUrlParameter("annotation_hash", projectId)
        .bindQueryParameter("analysis", true);
      batchRequest
        .addEndpoint(endpoint)
        .catch((jsonData) => this.display_server_error_message(jsonData))
        .then((jsonData) => {
          if (jsonData.status !== "ok") {
            this.display_server_error_message(jsonData);
            return;
          }
          jsonData.compounds.forEach((compound) => {
            if (typeof compoundMap[compound] === "undefined") {
              compoundMap[compound] = 1;
            } else {
              compoundMap[compound] += 1;
            }
          });
          jsonData.reporters.forEach(addReporter);
          resolvedInstances += 1;
          if (resolvedInstances === this.state.selectedProjects.length) {
            finalize();
          }
        });
    });

    if (this.state.selectedProjects.length === 0) {
      finalize();
      return;
    }

    batchRequest.fetch((success, response) => {
      if (!success) {
        this.props.showErrorSnackbar(INTERNET_ERROR_MSG);
      }
    });
  }

  createGenericToggle(fieldName) {
    return function (evt) {
      this.setState({
        [fieldName]: !this.state[fieldName],
      });
    }.bind(this);
  }

  createGenericSetValue(fieldName) {
    return function (evt) {
      this.setState({
        [fieldName]: evt.target ? evt.target.value : evt,
      });
    }.bind(this);
  }

  icChangeHandler(idx) {
    return function (value) {
      const copy = [...this.state.RelCellSurv];
      copy[idx] = value;
      this.setState({ RelCellSurv: copy });
    }.bind(this);
  }

  addProject(project) {
    if (!Array.isArray(project)) {
      project = [project];
    }

    const filtered = this.state.projects.filter(
      (obj) => project.indexOf(obj.name) !== -1
    );
    if (filtered.length === 0) {
      return;
    }
    const projects = [
      ...this.state.selectedProjects,
      ...filtered.map((project) => project.id),
    ];
    this.setState(
      {
        selectedProjects: projects,
        metaLoading: true,
      },
      this.fetchCompoundsAndReporters
    );
  }

  removeProject(projects) {
    if (!Array.isArray(projects)) {
      projects = [projects];
    }

    const filtered = this.state.projects.filter(
      (obj) => projects.indexOf(obj.name) !== -1
    );
    if (filtered.length === 0) {
      return;
    }

    const selectedProjects = [...this.state.selectedProjects];
    for (let project of filtered) {
      const idx = selectedProjects.indexOf(project.id);
      if (idx !== -1) selectedProjects.splice(idx, 1);
    }

    this.setState(
      {
        selectedProjects: selectedProjects,
        metaLoading: true,
      },
      this.fetchCompoundsAndReporters
    );
  }

  addReporter() {
    const addMethod = this.createGenericAdd(
      "selectedReporters",
      "reporterOrder"
    );
    return function (...varargs) {
      this.manuallySelectedReporters = true;
      return addMethod(...varargs);
    }.bind(this);
  }

  removeReporter() {
    const removeMethod = this.createGenericRemove(
      "selectedReporters",
      "reporterOrder"
    );
    return function (...varargs) {
      this.manuallySelectedReporters = true;
      return removeMethod(...varargs);
    }.bind(this);
  }

  createGenericAdd(...names) {
    return function (items) {
      const newState = {};
      names.forEach((name) => {
        if (!Array.isArray(items)) {
          items = [items];
        }

        const copy = [...this.state[name]];
        items
          .filter((item) => copy.indexOf(item) === -1)
          .forEach((item) => copy.push(item));
        newState[name] = copy;
      });

      this.setState(newState);
    }.bind(this);
  }

  createGenericRemove(...names) {
    return function (item) {
      const newState = {};
      names.forEach((name) => {
        const copy = [...this.state[name]];
        const removeItem = (value) => {
          const idx = copy.indexOf(value);
          if (idx !== -1) {
            copy.splice(idx, 1);
          }
        };

        if (Array.isArray(item)) {
          item.forEach(removeItem);
        } else {
          removeItem(item);
        }
        newState[name] = copy;
      });

      this.setState(newState);
    }.bind(this);
  }

  createGenericSortHandler(name) {
    return function ({ oldIndex, newIndex }) {
      let sortableItems = arrayMove(this.state[name], oldIndex, newIndex);
      this.setState({ [name]: sortableItems });
    }.bind(this);
  }

  onProjectScroll(event) {
    if (this.project.fetching) return;

    if (event.target.scrollHeight > event.target.clientHeight) {
      const percentage =
        event.target.scrollTop /
        (event.target.scrollHeight - event.target.clientHeight);
      if (percentage >= InfiniteScroll.LOAD_THRESHOLD) {
        this.fetchProjects();
      }
    }
  }

  addAnalysis() {
    const copy = [...this.state.RelCellSurv];
    copy.push(0);
    this.setState({ RelCellSurv: copy });
  }

  removeAnalysis(idx) {
    return function () {
      const copy = [...this.state.RelCellSurv];
      copy.splice(idx, 1);
      this.setState({ RelCellSurv: copy });
    }.bind(this);
  }

  runAnalyses() {
    const allCompounds =
      this.state.selectedCompounds.length === this.state.compoundNames.length;
    const allReporters =
      this.state.selectedReporters.length === this.state.reporters.length;

    const basicAnalysisParameters = {
      projects: this.state.selectedProjects,
      mark_non_estimated: this.state.markNonEstimatedGFP,
      export_raw_data: this.state.exportRawData,
      max_heatmap: this.state.maxHeatmap
        ? parseInt(this.state.maxHeatmap, 10)
        : null,
      compounds: allCompounds ? null : this.state.selectedCompounds,
      reporters: allReporters ? null : this.state.selectedReporters,
      analysis_name: this.state.analysisName,
      relative_cell_survival: this.state.RelCellSurv,
    };

    switch (this.state.orderCompoundsMode) {
      case "compound-order":
        basicAnalysisParameters.compound_order = this.state.compoundOrder;
        break;
      case "dendrogram":
        basicAnalysisParameters.dendrogram_rcs = this.state.dendrogramIC;
        break;
    }

    if (this.state.orderReportersMode === "reporter-order") {
      basicAnalysisParameters.reporter_order = this.state.reporterOrder;
    }

    this.createAnalysis(basicAnalysisParameters);
  }

  setWaiting() {
    // TODO: add waiting backdrop
  }

  display_server_error_message(data) {
    if (data !== undefined && "error" in data) {
      this.props.showErrorSnackbar(data.error);
    } else if (data !== undefined) {
      this.props.showErrorSnackbar(SERVER_FAILED_MSG);
    }
  }
  createAnalysis(basicParameters) {
    this.setWaiting();
    getApiEndpoint("create_analysis")
      .bindQueryParameter("run_analysis", 1)
      .setBodyData(JSON.stringify(basicParameters))
      .getFetchPromise(this)
      .catch((response) => {
        this.display_server_error_message(response);
      })
      .then((response) => response.json())
      .then((data) => {
        if (!data || data.status !== "ok") {
          this.display_server_error_message(data);
          return;
        }

        if (Object.keys(data).length === 2) {
          this.props.showErrorSnackbar("Analysis server is offline");
          return;
        }
        this.analysisId = data.id;
        setTimeout(this.waitForAnalyses, CHECK_INTERVAL);
      })
      .catch(console.debug);
  }

  waitForAnalyses() {
    const batch = new BatchRequest().requiresAuthentication();
    const results = [];

    const endpoint = getApiEndpoint("fetch_analysis").bindUrlParameter(
      "analysis_hash",
      this.analysisId
    );
    batch
      .addEndpoint(endpoint)
      .catch((jsonData) => {
        this.display_server_error_message(jsonData);
      })
      .then((jsonData) => {
        if (jsonData.status !== "ok") {
          this.display_server_error_message(jsonData);
          return;
        }

        if (results.every((value) => value)) {
          this.props.history.push(`/analysis/show/${this.analysisId}`);
        }
      })
      .catch(console.debug);

    batch.fetch((success, response) => {
      if (!success) {
        this.props.showErrorSnackbar(INTERNET_ERROR_MSG);
      }
    });
  }

  validate() {
    return [
      this.state.selectedProjects.length > 0,
      this.state.selectedCompounds.length > 0,
      this.state.selectedReporters.length > 0,
      this.state.RelCellSurv.length > 0,
      this.state.analysisName !== "",
    ].every((value) => value);
  }

  componentDidMount() {
    this.fetchProjects().then(() => {
      if (this.props.annotationSelection) {
        const selectedProjects = this.props.annotationSelection;
        if (!Array.isArray(selectedProjects) || selectedProjects.length === 0)
          return;

        const retrieveProjects = selectedProjects.filter(
          (projectId) => this.state.projects.indexOf(projectId) === -1
        );
        const addedProjects = [];
        const finalize = () => {
          // Map the selected projects to names
          const projects = [...this.state.projects, ...addedProjects];
          const mappedProjects = projects
            .filter((project) => selectedProjects.indexOf(project.id) !== -1)
            .map((project) => project.name);

          this.setState({
            projects: projects,
            initiallySelectedProjects: mappedProjects,
          });
        };

        if (retrieveProjects.length > 0) {
          const batchRequest = new BatchRequest().requiresAuthentication();
          let expectedProjects = retrieveProjects.length;
          for (const projectId of retrieveProjects) {
            const metaEndpoint = getApiEndpoint("get_meta").bindUrlParameter(
              "annotation_hash",
              projectId.slice()
            );
            batchRequest
              .addEndpoint(metaEndpoint)
              .catch((jsonData) => this.display_server_error_message(jsonData))
              .then((jsonData) => {
                if (!this.addedProjects.has(jsonData.id)) {
                  this.addedProjects.add(jsonData.id);
                  addedProjects.push({ id: jsonData.id, name: jsonData.name });
                } else {
                  expectedProjects -= 1;
                }

                if (addedProjects.length === expectedProjects) {
                  finalize();
                }
              });
          }
          batchRequest.fetch((success, response) => {
            if (!success) {
              this.props.showErrorSnackbar(INTERNET_ERROR_MSG);
            }
          });
        } else {
          finalize();
        }
      }
    });
  }
  onAnalysisNameChange(event) {
    this.setState({ analysisName: event.target.value });
  }

  renderBody(themeData) {
    const genericOnRadioButtonChange = (name, button) => {
      return (evt) => {
        this.createGenericSetValue(name)(evt);
        const collapsedDiv = document.querySelector(
          button.current.getAttribute("data-target")
        );
        const shouldShow = /^.+-order$/.test(evt.target.value);
        if (!collapsedDiv.classList.contains("show") === shouldShow) {
          button.current.click();
        }
      };
    };

    const metaLoading = this.state.metaLoading || this.state.projectLoading;
    return (
      <React.Fragment>
        <div className="offset-0 offset-sm-1 offset-md-2 background-item create-analysis col-12 col-sm-10 col-md-8 mt-2 py-1">
          <h3>{strings.toxDB_createAnalysis_name}</h3>
          <div className="form-group">
            <input
              id="prefix-name-input"
              type="text"
              className="form-control"
              value={this.state.analysisName}
              onChange={this.onAnalysisNameChange}
              aria-describedby={"prefix-name-help"}
            />
          </div>
        </div>

        <div className="offset-0 offset-sm-1 offset-md-2 background-item create-analysis col-12 col-sm-10 col-md-8 mt-2 py-1">
          <h3>{strings.toxDB_createAnalysis_projects}</h3>
          <ScrollableComponent
            includeAll={true}
            addOption={this.addProject}
            removeOption={this.removeProject}
            options={this.state.projects.map((obj) => obj.name)}
            notifySearch={this.fetchAll}
            className="max-scrollable-component"
            onScroll={this.onProjectScroll}
            initiallySelected={this.state.initiallySelectedProjects}
            searchable={true}
            loading={metaLoading}
            emptyMessage={strings.toxDB_projectOverview_unavailable}
          />
        </div>
        <div className="background-item create-analysis collapsed-md-left offset-sm-1 offset-md-2 col-12 col-sm-10 col-md-4 mt-2 py-1">
          <h3>{strings.toxDB_createAnalysis_selectCompounds}</h3>
          <ScrollableComponent
            includeAll={true}
            addOption={this.createGenericAdd(
              "selectedCompounds",
              "compoundOrder"
            )}
            removeOption={this.createGenericRemove(
              "selectedCompounds",
              "compoundOrder"
            )}
            options={this.state.compounds}
            values={this.state.compoundNames}
            className="max-scrollable-component"
            searchable={true}
            loading={metaLoading}
            emptyMessage={strings.toxDB_createAnalysis_selectCompounds_project}
          />
        </div>
        <div className="background-item create-analysis collapsed-md-right col-12 col-sm-10 col-md-4 mt-2 py-1">
          <h3>{strings.toxDB_createAnalysis_selectReporters}</h3>
          <ScrollableComponent
            includeAll={true}
            addOption={this.addReporter()}
            removeOption={this.removeReporter()}
            options={this.state.reporters}
            className="max-scrollable-component"
            initiallySelected={this.state.selectedReporters}
            searchable={true}
            loading={metaLoading}
            emptyMessage={strings.toxDB_createAnalysis_selectReporters_project}
          />
        </div>
        <div className="background-item create-analysis offset-sm-1 offset-md-2 col-12 col-sm-10 col-md-8 mt-2 py-1 pb-2">
          <h3>{strings.toxDB_createAnalysis_compOrder}</h3>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              id="compound-order-none"
              value="none"
              checked={this.state.orderCompoundsMode === "none"}
              onChange={genericOnRadioButtonChange(
                "orderCompoundsMode",
                this.compoundCollapseButton
              )}
            />
            <label className="form-check-label" htmlFor="compound-order-none">
              {strings.toxDB_createAnalysis_none}
            </label>
            <small id="compound-order-none" className="form-text text-muted">
              {strings.toxDB_createAnalysis_compOrder_noneSub}
            </small>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              id="dendrogram"
              value="dendrogram"
              checked={this.state.orderCompoundsMode === "dendrogram"}
              onChange={genericOnRadioButtonChange(
                "orderCompoundsMode",
                this.compoundCollapseButton
              )}
            />
            <label className="form-check-label" htmlFor="dendrogram">
              {strings.toxDB_createAnalysis_compOrder_DendoBasedOrdering}
            </label>
            <small id="dendrogram" className="form-text text-muted">
              {strings.toxDB_createAnalysis_compOrder_DendoBasedOrderingSub}
            </small>
          </div>
          <div className="pb-4 mt-2">
            <h5>
              {strings.toxDB_createAnalysis_settings_inhibConc}
              {this.state.dendrogramIC}
            </h5>
            <SliderWithTooltip
              min={0}
              max={1}
              step={0.01}
              value={this.state.dendrogramIC}
              tipFormatter={(value) => value.toFixed(2)}
              marks={SLIDER_LABELS}
              onChange={this.createGenericSetValue("dendrogramIC")}
              disabled={this.state.orderCompoundsMode !== "dendrogram"}
            />
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              id="custom-compound-order"
              value="compound-order"
              checked={this.state.orderCompoundsMode === "compound-order"}
              onChange={genericOnRadioButtonChange(
                "orderCompoundsMode",
                this.compoundCollapseButton
              )}
            />
            <label className="form-check-label" htmlFor="custom-compound-order">
              {strings.toxDB_createAnalysis_customOrder}
            </label>
            <button
              className="btn btn-secondary float-right"
              type="button"
              data-toggle="collapse"
              ref={this.compoundCollapseButton}
              data-target="#compound-order"
              aria-expanded="true"
              onClick={() =>
                this.setState({
                  compoundOrderCollapsed: !this.state.compoundOrderCollapsed,
                })
              }
            >
              <i
                className={
                  "fa fa-caret-" +
                  (this.state.compoundOrderCollapsed ? "down" : "up")
                }
              />
            </button>
          </div>
          <div className="pb-1 mt-2">
            <div className="collapse" id="compound-order">
              <SortableItems
                items={this.state.compoundOrder}
                lockAxis="y"
                useDragHandle={true}
                lockToContainerEdges={true}
                disabled={this.state.orderCompoundsMode !== "compound-order"}
                onSortEnd={this.createGenericSortHandler("compoundOrder")}
              />
            </div>
          </div>
        </div>
        <div className="background-item create-analysis offset-sm-1 offset-md-2 col-12 col-sm-10 col-md-8 mt-2 py-1 pb-2">
          <h3>{strings.toxDB_createAnalysis_reportOrder}</h3>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              id="reporter-order-none"
              value="none"
              checked={this.state.orderReportersMode === "none"}
              onChange={genericOnRadioButtonChange(
                "orderReportersMode",
                this.reporterCollapseButton
              )}
            />
            <label className="form-check-label" htmlFor="reporter-order-none">
              {strings.toxDB_createAnalysis_none}
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              id="custom-reporter-order"
              value="reporter-order"
              checked={this.state.orderReportersMode === "reporter-order"}
              onChange={genericOnRadioButtonChange(
                "orderReportersMode",
                this.reporterCollapseButton
              )}
            />
            <label className="form-check-label" htmlFor="custom-reporter-order">
              {strings.toxDB_createAnalysis_customOrder}
            </label>
            <button
              className="btn btn-secondary float-right"
              type="button"
              data-toggle="collapse"
              ref={this.reporterCollapseButton}
              data-target="#reporter-order"
              aria-expanded="true"
              onClick={() =>
                this.setState({
                  reporterOrderCollapsed: !this.state.reporterOrderCollapsed,
                })
              }
            >
              <i
                className={
                  "fa fa-caret-" +
                  (this.state.reporterOrderCollapsed ? "down" : "up")
                }
              />
            </button>
          </div>
          <div className="pb-1 mt-2">
            <div className="collapse" id="reporter-order">
              <SortableItems
                items={this.state.reporterOrder}
                lockAxis="y"
                useDragHandle={true}
                lockToContainerEdges={true}
                disabled={this.state.orderReportersMode !== "reporter-order"}
                onSortEnd={this.createGenericSortHandler("reporterOrder")}
              />
            </div>
          </div>
        </div>
        <div className="background-item create-analysis offset-sm-1 offset-md-2 col-12 col-sm-10 col-md-8 my-2 py-1 pb-2">
          <h3>{strings.toxDB_createAnalysis_settings}</h3>
          <div className="form-group">
            <label htmlFor="max-heatmap">
              {strings.toxDB_createAnalysis_settings_maxHeat}
            </label>
            <input
              type="number"
              min={1}
              className="form-control"
              id="max-heatmap"
              value={this.state.maxHeatmap}
              onChange={this.createGenericSetValue("maxHeatmap")}
            />
          </div>
          {!!process.env.REACT_APP_ANALYSIS_ADVANCED_OPTIONS && (
            <React.Fragment>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="export_raw"
                  checked={this.state.exportRawData}
                  onChange={this.createGenericToggle("exportRawData")}
                />
                <label className="form-check-label" htmlFor="export_raw">
                  {strings.toxDB_createAnalysis_settings_exportRaw}
                </label>
              </div>
              <div className="form-check mb-4">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="mark_non_estimated"
                  checked={this.state.markNonEstimatedGFP}
                  onChange={this.createGenericToggle("markNonEstimatedGFP")}
                />
                <label
                  className="form-check-label"
                  htmlFor="mark_non_estimated"
                >
                  {strings.toxDB_createAnalysis_settings_markNonEstimated}
                </label>
              </div>
            </React.Fragment>
          )}
          {this.state.RelCellSurv.map((ic, idx) => (
            <div className="pb-4" key={idx}>
              <h5 className="mb-0">
                {strings.toxDB_createAnalysis_settings_analysis}
                {idx + 1}
                <i
                  className="pointer float-right fa fa-trash icon-circle"
                  onClick={this.removeAnalysis(idx)}
                  title={`${
                    strings.toxDB_createAnalysis_settings_deleteAnalysis
                  } ${idx + 1}`}
                />
              </h5>
              <small className="mb-1">
                {strings.toxDB_createAnalysis_settings_inhibConc}
                {this.state.RelCellSurv[idx]}
              </small>
              <SliderWithTooltip
                min={0}
                max={1}
                step={0.01}
                value={this.state.RelCellSurv[idx]}
                tipFormatter={(value) => value.toFixed(2)}
                marks={SLIDER_LABELS}
                onChange={this.icChangeHandler(idx)}
              />
            </div>
          ))}
          <div className="row">
            <div className="offset-sm-8 col-12 col-sm-4 p-0 pr-sm-1 mb-1 mt-1">
              <button
                className="btn btn-outline-primary w-100"
                disabled={
                  this.state.RelCellSurv.length >= MAX_CONCURRENT_ANALYSES
                }
                onClick={this.addAnalysis}
              >
                {strings.toxDB_createAnalysis_add}
              </button>
            </div>
            <div className="offset-sm-8 col-12 col-sm-4 p-0 pr-sm-1 mb-1 mb-sm-0">
              <button
                className="btn btn-success w-100"
                onClick={this.runAnalyses}
                disabled={!this.validate()}
              >
                {strings.toxDB_createAnalysis_run}
              </button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect(mapState, mapDispatch)(CreateAnalysis);

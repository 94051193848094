import React from "react";

import ToxplotLogo from "../resources/images/toxplot-logo.png";
import Abstract from "./Abstract";


const THEME = {
    toxys: {
        navigation: {
            logo: ToxplotLogo
        },
        body: {
            primaryBackground: "#B9C9D6",
            secondaryBackground: "#066939",
            cardBackground: "white",
            darkCardBackground: "#ABE5C9",
        },
    }
};


export const SELECTED_THEME = THEME.toxys;
export const ThemeContext = React.createContext(THEME.toxys);


export default class ThemedComponent extends React.Component {

    themedRender(themeData) {
        Abstract.method();
    }

    render() {
        return (
            <ThemeContext.Consumer>
                { themeData => this.themedRender(themeData) }
            </ThemeContext.Consumer>
        );
    }


}

import { Theme, makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";

import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import amber from "@material-ui/core/colors/amber";
import blue from "@material-ui/core/colors/blue";
import { clearSnackbar } from "./simpleSnackbarSlice";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import { RootState } from "../rootReducer";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
const alertModes = ["info", "success", "error", "warning"];

type Color = "success" | "info" | "warning" | "error";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles1 = makeStyles((theme: Theme) => ({
  success: {
    backgroundColor: green[600],
    autoHide: 4000,
  },
  error: {
    backgroundColor: red[600],
    autoHide: 4000,
  },
  info: {
    backgroundColor: blue[600],
    autoHide: false,
  },
  warning: {
    backgroundColor: amber[700],
    autoHide: 4000,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
}));

export default function SimpleSnackbar() {
  const dispatch = useDispatch();
  const classes = useStyles1();

  const { message, open, mode } = useSelector(
    (state: RootState) => state.snackbar
  );

  function handleClose() {
    dispatch(clearSnackbar());
  }

  var castedMode: Color = "info";

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={open}
      autoHideDuration={4000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={mode}>
        {message}
      </Alert>
    </Snackbar>
  );
}

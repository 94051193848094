import React from "react";

import Overlay from "../../common/Overlay";
import { BatchRequest } from "../../ApiEndpoint";
import { readBlobAsText } from "../../utils";

import "../../resources/css/annotation/single/PlateChecker.css";
import { SERVER_FAILED_MSG, INTERNET_ERROR_MSG } from "../../ErrorMessages";
import { converToCsv } from "./CsvViewer";
import { setSnackbar } from "../../common/simpleSnackbarSlice";
import { AppDispatch } from "../../store";
import { connect } from "react-redux";

function mapDispatch(dispatch: AppDispatch) {
  return {
    showErrorSnackbar: (message: string) =>
      dispatch(setSnackbar({ payload: { message, mode: "error" } })),
  };
}
class PlateChecker extends Overlay {
  state = { canClose: false, close: false };

  constructor(props) {
    super(props);

    this.convertedFiles = [];
    this.addValidPlates = this.addValidPlates.bind(this);
  }

  componentWillMount() {
    const batchRequest = new BatchRequest(true).requiresAuthentication();
    const fileStatus = {};
    const endpointAdded = {};

    this.props.files.forEach((file) => {
      const handleFileObject = (fileObject) => {
        this.convertedFiles.push(fileObject);
        fileStatus[fileObject.name] = null;
        endpointAdded[fileObject.name] = false;

        readBlobAsText(fileObject).then((text) => {
          endpointAdded[fileObject.name] = true;
          const endpoint = this.props
            .getEndpoint()
            .bindUrlParameter("well_config", this.props.wellConfig)
            .setBodyData(text);
          batchRequest
            .addEndpoint(endpoint)
            .catch(() => this.props.showErrorSnackbar(SERVER_FAILED_MSG))
            .then((jsonData) => {
              if (jsonData.status === "ok") {
                fileStatus[fileObject.name] = true;
              } else {
                fileStatus[fileObject.name] = jsonData.error;
              }

              if (Object.values(fileStatus).every((value) => value !== null)) {
                this.setState({
                  content: fileStatus,
                  close: Object.values(fileStatus).every(
                    (value) => value === true
                  ),
                });
              }
            });

          if (Object.values(endpointAdded).every((value) => value)) {
            batchRequest.fetch((success, response) => {
              if (!success) {
                this.props.showErrorSnackbar(INTERNET_ERROR_MSG);
              }
            });
          }
        });
      };

      const fileComponents = file.name.split(".");
      if (
        fileComponents.length > 1 &&
        ["xls", "xlsx"].indexOf(fileComponents[fileComponents.length - 1]) !==
          -1
      ) {
        converToCsv(file, handleFileObject);
      } else {
        handleFileObject(file);
      }
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.close) {
      this.props.addPlates(this.convertedFiles);
      this.props.close();
    }
  }

  addValidPlates() {
    const validNames = Object.entries(this.state.content)
      .filter((entry) => entry[1] === true)
      .map((entry) => entry[0]);
    const validFiles = this.convertedFiles.filter(
      (file) => validNames.indexOf(file.name) !== -1
    );
    this.props.addPlates(validFiles, () => this.props.close());
  }

  renderContent(themeData, content) {
    const addValidButton = Object.values(content).some(
      (value) => value === true
    );
    return (
      <div className="plate-checker">
        <ul>
          {Object.keys(content).map((key) => (
            <li key={key}>
              {key}: {content[key] === true ? "valid" : content[key]}
            </li>
          ))}
        </ul>
        <div className="button-container">
          <button className="btn btn-secondary" onClick={this.props.close}>
            Cancel
          </button>
          {addValidButton && (
            <button
              className="ml-1 btn btn-primary"
              onClick={this.addValidPlates}
            >
              Add the valid plates
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default connect(null, mapDispatch)(PlateChecker);

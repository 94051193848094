import { MenuItem, Select as MuiSelect } from "@material-ui/core";
import { useEffect, useState } from "react";

export function Select<T = any>({
  options,
  onChange,
  values,
  value,
}: {
  values?: string[];
  options: string[];
  onChange: (newValue: string) => void;
  value: string;
}) {
  return (
    <MuiSelect
      value={value}
      onChange={(event) => onChange(event.target.value)}
      variant="outlined"
    >
      {options.map((option, idx) => (
        <MenuItem value={values?.length > idx ? values[idx] : option} key={idx}>
          {option}
        </MenuItem>
      ))}
    </MuiSelect>
  );
}

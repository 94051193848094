import "promise-polyfill/src/polyfill";
import "whatwg-fetch";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import {
  ProgressBar,
  progressBarFetch,
  setOriginalFetch,
} from "react-fetch-progressbar";

import Router from "./Router";
import { ThemeContext, SELECTED_THEME } from "./common/Theme";
import store from "./store";
import AuthListener from "./auth/AuthListener";
import SimpleSnackbar from "./common/SimpleSnackbar";

// Make the progressbar work
setOriginalFetch(window.fetch);
window.fetch = progressBarFetch;

ReactDOM.render(
  <Provider store={store}>
    <AuthListener>
      <ThemeContext.Provider value={SELECTED_THEME}>
        <ProgressBar style={{ left: 0 }} />
        <Router />
      </ThemeContext.Provider>
    </AuthListener>
    <SimpleSnackbar></SimpleSnackbar>
  </Provider>,
  document.getElementById("root")
);

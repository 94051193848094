import { createSlice } from "@reduxjs/toolkit";

const annotationSlice = createSlice({
  name: "annotation",
  initialState: { selection: [] },

  reducers: {
    add: (state, action) => {
      state.selection.push(action.payload);
      return state;
    },
    clear: (state) => {
      return { ...state, selection: [] };
    },
    remove: (state, action) => {
      state.selection = state.selection.filter(
        (item) => item != action.payload
      );
      return state;
    },
  },
});

export const { add, remove, clear } = annotationSlice.actions;
export default annotationSlice.reducer;

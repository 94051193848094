import React from "react";
import ReactLoading from "react-loading";

import Abstract from "./Abstract";
import ThemedComponent from "./Theme";

import "../resources/css/common/Overlay.css";


export default class Overlay extends ThemedComponent {

    state = {content: null, canClose: true};

    constructor(props) {
        super(props);
        if (typeof this.props.canClose !== "undefined") {
            this.state.canClose = this.props.canClose;
        }
    }

    renderContent(themeData, content) {
        Abstract.method();
    }

    themedRender(themeData) {
        return (
            <div className="overlay">
                <div className={"content " + (this.state.content ? "" : "flex-center")}>
                    { this.state.content &&
                        this.renderContent(themeData, this.state.content)
                    }
                    { !this.state.content &&
                        <div className="full-width">
                            { this.props.waitingMessage &&
                                <div className="text-center">
                                    <span>{this.props.waitingMessage}</span>
                                </div>
                            }
                            <div className="flex-h-center">
                                <ReactLoading color={themeData.body.secondaryBackground}/>
                            </div>
                        </div>
                    }
                    { this.state.canClose &&
                        <div className="close-icon">
                            <i className="fa fa-times-circle pointer" onClick={this.props.close}/>
                        </div>
                    }
                </div>
            </div>
        );
    }
}
import React from "react";

import "../../resources/css/annotation/single/EditableHeader.css";
import { strings } from "../../localization"


export default class EditableHeader extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            editingShowing: typeof this.props.showEditor === "undefined" ? true : this.props.showEditor,
            editing: !Boolean(this.props.value),
            value: this.props.value || ""
        };

        this.onChange = this.onChange.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        const state = {value: nextProps.value || ""};
        if (!this.props.value && state.value) {
            state.editing = false;
        }
        if (typeof nextProps.showEditor !== "undefined")
            state.editingShowing = nextProps.showEditor;

        this.setState(state);
    }

    onChange(event) {
        if (/^[\w\- ]+$/.test(event.target.value) || event.target.value.length === 0) {
            this.setState({value: event.target.value});
        }
    }

    onKeyPress(event) {
        if (event.key.toLowerCase() === "enter") {
            this.onBlur();
        }
    }

    onBlur() {
        this.setState({editing: (!this.state.value || this.state.value.length === 0)}, () => this.props.valueChanged(this.state.value));
    }

    render() {
        if (this.state.editing) {
            return (
              <div className="form-group row">
                    <input required type="text" autoFocus={true} className={"form-control" + (
                        this.state.value.trim().length === 0
                        ? " is-invalid"
                        : "")} onChange={this.onChange} onKeyPress={this.onKeyPress} onBlur={this.onBlur} value={this.state.value} placeholder={strings.toxDB_create_projectName}/>
                    </div>
            );
        } else {
            const spacer = !this.props.value || this.props.value.length === 0;
            let style = {};
            if (spacer) style = {color: "white"};
            return (
                <div className="position-relative">
                    <h2 className="text-truncate">
                        <span style={style}>{spacer ? "|" : this.props.value}</span>
                        { this.state.editingShowing &&
                            <i className="fa fa-edit pointer edit-button transform-center-v" onClick={() => this.setState({editing: true})}/>
                        }
                    </h2>
                </div>);
        }
    }

}

import React from "react";

import ThemedComponent from "./Theme";
import Abstract from "./Abstract";

import "../resources/css/common/CardView.css";

export default class CardView extends ThemedComponent {
  constructor(props) {
    super(props);

    this.state = {
      hijackComponent: null,
      checked: false,
      showDropdown: false,
    };

    this.props.checkboxCollection[this.props.data.id] = false;

    this.onToggleCheckbox = this.onToggleCheckbox.bind(this);
  }

  calcEstimatedHeight() {
    Abstract.method();
  }

  renderCardHeader(themeData) {
    Abstract.method();
  }

  renderCardBody(themeData) {
    Abstract.method();
  }

  renderDropdownMenuItems() {
    return null;
  }

  componentWillUnmount() {
    delete this.props.checkboxCollection[this.props.data.id];
  }

  onToggleCheckbox() {
    const value = !this.state.checked;
    this.setState({ checked: value, showDropdown: false });

    this.props.checkboxCollection[this.props.data.id] = value;
  }

  themedRender(themeData) {
    const dropdownMenuItems = this.renderDropdownMenuItems();
    const cardBackgroundColor = {
      backgroundColor: !this.state.checked
        ? themeData.body.cardBackground
        : themeData.body.darkCardBackground,
    };

    return (
      <React.Fragment>
        <div className="col-2 col-lg-1" />
        <div
          className="col-8 col-lg-4 my-2 card-view"
          style={cardBackgroundColor}
        >
          <div
            className="hover-checkbox transform-center-v"
            style={cardBackgroundColor}
          >
            <label>
              <input
                type="checkbox"
                name={this.props.data.id}
                checked={this.state.checked}
                onChange={this.onToggleCheckbox}
              />
            </label>
          </div>
          <div className="full-height full-width inner-wrapper">
            <div className="row card-view-header">
              {this.renderCardHeader(themeData)}
              {dropdownMenuItems && (
                <React.Fragment>
                  <i
                    className={
                      "fa fa-ellipsis-v position-absolute hamburger" +
                      (this.state.showDropdown ? " active" : "")
                    }
                    onClick={() =>
                      this.setState({ showDropdown: !this.state.showDropdown })
                    }
                  />
                  <div
                    className={
                      "position-absolute hamburger-dropdown" +
                      (this.state.showDropdown ? " show" : "")
                    }
                  >
                    <div className="dropdown-menu d-block">
                      {dropdownMenuItems}
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
            <div className="row item-container">
              {this.renderCardBody(themeData)}
            </div>
          </div>
        </div>
        <div className="col-2 col-lg-1" />
        {this.state.hijackComponent}
      </React.Fragment>
    );
  }
}



export default class NullPointerError extends Error {

    constructor(message) {
        super();
        this.message = message || "Null or undefined received";
        this.stack = (new Error()).stack;
        this.name = this.constructor.name;
    }

}

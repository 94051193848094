import { Route, Redirect, RouteProps } from "react-router-dom";
import React, { Component } from "react";
import { useSelector, connect } from "react-redux";
import Navigation from "../navigation/Navigation";

import { RootState } from "../store";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const AuthGuard = ({ component: Component, path }: RouteProps) => {
  const authStatus = useSelector((state: RootState) => state.auth.status);
  const classes = useStyles();

  const navigation = React.createRef();

  if (!authStatus.waiting && !authStatus.loggedIn) {
    return <Redirect push to="/login" />;
  }

  return (
    <React.Fragment>
      <Navigation />
      <Backdrop className={classes.backdrop} open={authStatus.waiting}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {!authStatus.waiting && authStatus.loggedIn && (
        <Route component={Component} path={path} nav={navigation} />
      )}
    </React.Fragment>
  );
};

export default AuthGuard;

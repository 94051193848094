import ApiEndpoint from "./ApiEndpoint";
import ConfigurationError from "./common/ConfigurationError";

const CONFIGURATION = {
  // Projects

  // Project creation / editing
  create_project: {
    url: "/api/create/project/{name}/",
    requireAuthentication: true,
    method: "PUT",
    queryParameters: {
      plate_config: "string",
      s9: "boolean",
      af_correction_row: "string",
      af_correction_compounds: "string",
    },
    bodyRequired: true,
  },
  validate_annotation: {
    url: "/api/validate/annotation/",
    requireAuthentication: true,
    method: "POST",
    queryParameters: {
      well_config: "string;required",
    },
    bodyRequired: true,
  },
  validate_project_name: {
    url: "/api/validate/project/{project_name}/",
    requireAuthentication: true,
    method: "GET",
    bodyRequired: false,
  },
  create_experiment: {
    url: "/api/create/{annotation_hash}/experiment/",
    requireAuthentication: true,
    method: "PUT",
    bodyRequired: false,
  },
  create_plate: {
    url: "/api/create/{annotation_hash}/{experiment}/plate/{plate_number}/",
    requireAuthentication: true,
    method: "PUT",
    bodyRequired: true,
  },
  create_plate_parameterized: {
    url: "/api/create/{annotation_hash}/{experiment}/plate/{plate_number}/",
    requireAuthentication: true,
    method: "PUT",
    bodyRequired: true,
    queryParameters: {
      well_header: "string",
      sample_id_header: "string",
      date_header: "string",
      gfp_mean_header: "string",
      cell_conc_header: "string",
    },
  },
  validate_plate: {
    url: "/api/validate/plate/{well_config}/",
    requireAuthentication: true,
    method: "POST",
    bodyRequired: true,
  },
  validate_plate_parameterized: {
    url: "/api/validate/plate/{well_config}/",
    requireAuthentication: true,
    method: "POST",
    bodyRequired: true,
    queryParameters: {
      well_header: "string",
      sample_id_header: "string",
      date_header: "string",
      gfp_mean_header: "string",
      cell_conc_header: "string",
    },
  },
  edit_project: {
    url: "/api/edit/{annotation_hash}/",
    requireAuthentication: true,
    method: "POST",
    bodyRequired: true,
  },
  edit_project_content: {
    url: "/api/edit/{annotation_hash}/content/",
    requireAuthentication: true,
    method: "POST",
    bodyRequired: true,
  },
  delete_annotation: {
    url: "/api/delete/project/{annotation_hash}/",
    requireAuthentication: true,
    method: "DELETE",
    bodyRequired: false,
  },
  delete_experiment: {
    url: "/api/delete/project/{annotation_hash}/{experiment}/",
    requireAuthentication: true,
    method: "DELETE",
    bodyRequired: false,
  },
  delete_plate: {
    url: "/api/delete/project/{annotation_hash}/{experiment}/plate/{plate_number}/",
    requireAuthentication: true,
    method: "DELETE",
    bodyRequired: false,
    queryParameters: {
      cascade: "boolean",
    },
  },
  lock_project: {
    url: "/api/lock/{annotation_hash}/",
    requireAuthentication: true,
    method: "POST",
    bodyRequired: false,
  },
  unlock_project: {
    url: "/api/unlock/{annotation_hash}/",
    requireAuthentication: true,
    method: "POST",
    bodyRequired: false,
  },
  is_project_locked: {
    url: "/api/get/project/{annotation_hash}/meta/locked/",
    requireAuthentication: true,
    method: "GET",
    bodyRequired: false,
  },

  // Fetching project data
  get_compounds: {
    url: "/api/fetch/compounds/",
    requireAuthentication: true,
    method: "GET",
    bodyRequired: false,
  },
  get_tags: {
    url: "/api/fetch/tags/",
    requireAuthentication: true,
    method: "GET",
    bodyRequired: false,
  },
  get_projects: {
    url: "/api/fetch/projects/",
    requireAuthentication: true,
    method: "GET",
    bodyRequired: false,
    queryParameters: {
      offset: "int;min=1",
      max_items: "int;min=0",
    },
  },
  get_filtered_projects: {
    url: "/api/fetch/projects/",
    requireAuthentication: true,
    method: "POST",
    bodyRequired: true,
    queryParameters: {
      offset: "int;min=1",
      max_items: "int;min=0",
    },
  },
  get_meta: {
    url: "/api/fetch/{annotation_hash}/meta/",
    requireAuthentication: true,
    method: "GET",
    bodyRequired: false,
    queryParameters: {
      analysis: "boolean",
    },
  },
  // Exporting project data
  // - Project component
  get_annotation: {
    url: "/api/fetch/{annotation_hash}/annotation/",
    requireAuthentication: true,
    method: "GET",
    bodyRequired: false,
  },
  get_annotation_head: {
    url: "/api/fetch/{annotation_hash}/annotation/",
    requireAuthentication: true,
    method: "HEAD",
    bodyRequired: false,
  },
  get_experiment: {
    url: "/api/fetch/{annotation_hash}/{experiment}/",
    requireAuthentication: true,
    method: "GET",
    bodyRequired: false,
  },
  get_experiment_head: {
    url: "/api/fetch/{annotation_hash}/{experiment}/",
    requireAuthentication: true,
    method: "HEAD",
    bodyRequired: false,
  },
  get_plate: {
    url: "/api/fetch/{annotation_hash}/{experiment}/plate/{plate}/",
    requireAuthentication: true,
    method: "GET",
    bodyRequired: false,
  },
  get_plate_head: {
    url: "/api/fetch/{annotation_hash}/{experiment}/plate/{plate}/",
    requireAuthentication: true,
    method: "HEAD",
    bodyRequired: false,
  },
  // - Full exports
  export_annotation: {
    url: "/api/fetch/{annotation_hash}/",
    requireAuthentication: true,
    method: "GET",
    bodyRequired: false,
  },
  get_project_head: {
    url: "/api/fetch/{annotation_hash}/",
    requireAuthentication: true,
    method: "HEAD",
    bodyRequired: false,
  },
  export_multiple_annotation: {
    url: "/api/fetch/combined/projects/",
    requireAuthentication: true,
    method: "POST",
    bodyRequired: true,
  },
  get_combined_head: {
    url: "/api/fetch/combined/projects/",
    requireAuthentication: true,
    method: "HEAD",
    bodyRequired: true,
  },
  get_json_project: {
    url: "/api/fetch/{annotation_hash}/json/",
    requireAuthentication: true,
    method: "GET",
    bodyRequired: false,
  },
  get_json_project_head: {
    url: "/api/fetch/{annotation_hash}/json/",
    requireAuthentication: true,
    method: "HEAD",
    bodyRequired: false,
  },
  get_proast_project: {
    url: "/api/fetch/{annotation_hash}/proast/",
    requireAuthentication: true,
    method: "GET",
    bodyRequired: false,
  },
  get_proast_project_head: {
    url: "/api/fetch/{annotation_hash}/proast/",
    requireAuthentication: true,
    method: "GET",
    bodyRequired: false,
  },

  // Analyses

  create_analysis: {
    url: "/api/analysis/create/",
    requireAuthentication: true,
    method: "PUT",
    bodyRequired: true,
    queryParameters: {
      run_analysis: "boolean",
    },
  },
  fetch_analysis_finished: {
    url: "/api/analysis/fetch/finished/",
    requireAuthentication: true,
    method: "GET",
    bodyRequired: false,
    queryParameters: {
      offset: "int;min=1",
      max_items: "int;min=0",
    },
  },
  fetch_analysis_all: {
    url: "/api/analysis/fetch/",
    requireAuthentication: true,
    method: "GET",
    bodyRequired: false,
    queryParameters: {
      offset: "int;min=1",
      max_items: "int;min=0",
    },
  },
  fetch_filtered_analysis_all: {
    url: "/api/analysis/fetch/",
    requireAuthentication: true,
    method: "POST",
    bodyRequired: true,
    queryParameters: {
      offset: "int;min=1",
      max_items: "int;min=0",
    },
  },
  fetch_analysis_queue: {
    url: "/api/analysis/fetch/queue/",
    requireAuthentication: true,
    method: "GET",
    bodyRequired: false,
    queryParameters: {
      offset: "int;min=1",
      max_items: "int;min=0",
    },
  },
  fetch_analysis: {
    url: "/api/analysis/fetch/{analysis_hash}/",
    requireAuthentication: true,
    method: "GET",
    bodyRequired: false,
  },
  fetch_analysis_images: {
    url: "/api/analysis/fetch/{analysis_hash}/images/",
    requireAuthentication: true,
    method: "GET",
    bodyRequired: false,
    queryParameters: {
      image_data: "boolean",
      item: "int;min=0",
    },
  },
  persist_analysis: {
    url: "/api/analysis/persist/{analysis_hash}/",
    requireAuthentication: true,
    method: "POST",
    queryParameters: {
      name: "string",
    },
    bodyRequired: false,
  },
  delete_analysis: {
    url: "/api/analysis/delete/{analysis_hash}/",
    requireAuthentication: true,
    method: "DELETE",
    bodyRequired: false,
  },
  export_analysis: {
    url: "/api/analysis/export/{analysis_hash}/",
    requireAuthentication: true,
    method: "GET",
    bodyRequired: false,
  },
  export_analysis_report: {
    url: "/api/analysis/export/{analysis_hash}/report/",
    requireAuthentication: true,
    method: "GET",
    bodyRequired: false,
  },
  export_multiple_analysis: {
    url: "/api/analysis/export/combined/",
    requireAuthentication: true,
    method: "POST",
    bodyRequired: true,
  },

  // User management
  user_signup_with_token:{
    requireAuthentication: true,
    url: "/users/signup/",
    bodyRequired:true,
    method: "PUT",
  },
  user_permissions: {
    url: "/users/permissions/",
    requireAuthentication: true,
    method: "GET",
  },
  user_permissions_custom: {
    url: "/users/custom-permissions/",
    requireAuthentication: true,
    method: "GET",
  },
  user_permissions_custom_change: {
    url: "/users/{id}/custom-permissions/",
    requireAuthentication: true,
    method: "POST",
    bodyRequired: true,
  },
  user_client: {
    url: "/users/client/",
    requireAuthentication: true,
    method: "GET",
  },
  user_list: {
    url: "/users/list/",
    requireAuthentication: true,
    method: "GET",
  },
  user_add: {
    url: "/users/add/",
    requireAuthentication: true,
    method: "PUT",
    bodyRequired: true,
  },
  user_remove: {
    url: "/users/remove/{uid}/",
    requireAuthentication: true,
    method: "DELETE",
  },
  user_lock: {
    url: "/users/lock/{uid}/",
    requireAuthentication: true,
    method: "POST",
  },
  user_unlock: {
    url: "/users/unlock/{uid}/",
    requireAuthentication: true,
    method: "POST",
  },
  user_add_permission: {
    url: "/users/permission/add/{uid}/{permission}/",
    requireAuthentication: true,
    method: "POST",
  },
  user_remove_permission: {
    url: "/users/permission/remove/{uid}/{permission}/",
    requireAuthentication: true,
    method: "POST",
  },
  user_create_permission: {
    url: "/users/permission/create/",
    requireAuthentication: true,
    method: "POST",
    bodyRequired: true,
  },
  // Client groups
  user_client_groups_get_list: {
    url: "/users/client-group/",
    requireAuthentication: true,
    method: "GET",
  },
  user_client_groups_post: {
    url: "/users/client-group/",
    requireAuthentication: true,
    method: "POST",
    bodyRequired: true,
  },
  user_set_client_groups: {
    url: "/users/{id}/client-group/",
    requireAuthentication: true,
    method: "PUT",
    bodyRequired: true,
  },
  user_client_groups_delete: {
    url: "/users/client-group/{id}/",
    requireAuthentication: true,
    method: "DELETE",
    bodyRequired: false,
  },
  user_client_groups_put: {
    url: "/users/client-group/{id}/",
    requireAuthentication: true,
    method: "PUT",
    bodyRequired: true,
  },
  user_licence_list: {
    url: "/users/licence/",
    requireAuthentication: true,
    method: "GET",
  },

  // Feedback
  send_user_feedback: {
    url: "/api/feedback/",
    requireAuthentication: true,
    method: "POST",
  },
};

export default function getApiEndpoint(apiEndpointName) {
  if (!(apiEndpointName in CONFIGURATION)) {
    throw new ConfigurationError(
      `API endpoint ${apiEndpointName} does not exist`
    );
  } else {
    const config = CONFIGURATION[apiEndpointName];
    return new ApiEndpoint(
      config.url,
      config.method,
      config.queryParameters || [],
      config.bodyRequired,
      config.requireAuthentication
    );
  }
}

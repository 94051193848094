import { createSlice } from "@reduxjs/toolkit";
import { Color } from "@material-ui/lab/Alert";
interface SnackbarProps {
  open: boolean;
  message: string;
  mode: Color;
}
const SimpleSnackbarSlice = createSlice({
  name: "snackbar",
  initialState: {
    open: false,
    mode: "info",
    message: "",
  },

  reducers: {
    setSnackbar: (state, action) => {
      const { mode, message } = action.payload;
      state.open = true;
      state.mode = mode;
      state.message = message;
    },
    clearSnackbar: (state) => {
      state.open = false;
    },
  },
});

export const { setSnackbar, clearSnackbar } = SimpleSnackbarSlice.actions;
export default SimpleSnackbarSlice.reducer;

import { BatchRequest } from "../ApiEndpoint";
import React from "react";
import { Fab, Icon, Grow } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { setSnackbar } from "./simpleSnackbarSlice";
import { strings } from "../localization";
import getApiEndpoint from "../endpoint_configuration";
import { SERVER_FAILED_MSG } from "../ErrorMessages";
import { DeleteFABProperties } from "./types";

const DeleteFAB: React.FC<DeleteFABProperties> = (props) => {
  const { resourceType, selection, refreshData } = props;
  const dispatch = useDispatch();
  const showErrorSnackbar = (message: string) =>
    dispatch(setSnackbar({ message, mode: "error" }));

  function onClick() {
    const batchRequest = new BatchRequest().requiresAuthentication();

    selection.forEach((hash: string) => {
      const endpoint = getApiEndpoint(
        `delete_${resourceType}`
      ).bindUrlParameter(`${resourceType}_hash`, hash);
      batchRequest
        .addEndpoint(endpoint)
        .catch(() => showErrorSnackbar(SERVER_FAILED_MSG));
    });

    batchRequest.fetch((success, response) => {
      if (success) {
        refreshData(selection);
      } else {
        showErrorSnackbar(SERVER_FAILED_MSG);
      }
    });

    // TODO: Add confirm modal
  }

  return (
    <Grow in={selection.length > 0}>
      <Fab
        color="primary"
        aria-label={strings.toxDB_projectOverview_Delete}
        onClick={onClick}
      >
        <Icon className="fa fa-trash" />
      </Fab>
    </Grow>
  );
};

export default DeleteFAB;

import React from "react";

import "../resources/css/common/ThemedBackground.css";

import ThemedComponent from "./Theme";
import Abstract from "./Abstract";
import Loading from "./Loading";
import { bindComponent } from "../ApiEndpoint";
import { strings } from "../localization";

export default class ThemedBackground extends ThemedComponent {
  constructor(props) {
    super(props);

    this.state = {
      onScroll: null,
    };
  }

  componentWillMount() {
    bindComponent(this);
  }

  renderBody(themeData) {
    Abstract.method();
  }

  themedRender(themeData) {
    return (
      <main onScroll={this.state.onScroll} className="row pt-2">
        {this.renderBody(themeData)}
      </main>
    );
  }
}

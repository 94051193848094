import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const permissionSlice = createSlice({
  name: "permissions",
  initialState: [],

  reducers: {
    set: (state, action) => (state = action.payload),
    clear: (state) => (state = []),
    add: (state, action: PayloadAction<string>) => {
      state.push(action.payload);
    },
  },
});

export const { set, add, clear } = permissionSlice.actions;
export default permissionSlice.reducer;

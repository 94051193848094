import "../../resources/css/annotation/single/FileEntry.css";

import ConfigurationError from "../../common/ConfigurationError";
import CsvViewer from "./CsvViewer";
import DragHandle from "../../common/DragHandle";
import FileSaver from "file-saver";
import React from "react";

const byteRepresentations = ["bytes", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];


export function getBytesRepresentation(bytes) {
    if (bytes === null) return '0 bytes';
    if (typeof bytes !== "number") bytes = parseInt(bytes, 10);

    let currentIndex = 0;
    while (currentIndex < byteRepresentations.length - 1 && bytes >= 1000) {
        bytes = bytes / 1000;
        currentIndex += 1;
    }
    bytes = Math.round(bytes * 100) / 100;
    return `${bytes.toString()} ${byteRepresentations[currentIndex]}`;
}

export default class FileEntry extends React.Component {

    constructor(props) {
        super(props);

        if (!this.props.fileEntryHandler) {
            throw new ConfigurationError("fileEntryHandler property not defined");
        }

        this.state = {
            fileSize: null,
        };

        this.viewFile = this.viewFile.bind(this);
        this.downloadFile = this.downloadFile.bind(this);
        this.__unmounted = false;
    }

    updateFileSize() {
        const _this = this;
        if (this.props.fileEntryHandler) {
            this.props.fileEntryHandler.requestFileSize((size) => {
                if (!this.__unmounted && size !== this.state.fileSize) {
                    _this.setState({ fileSize: size });
                }
            });
        }
    }
    componentDidMount() {
        this.updateFileSize();
    }

    componentDidUpdate() {
        this.updateFileSize();
    }

    componentWillUnmount() {
        this.__unmounted = true;
    }

    viewFile() {
        this.props.fileEntryHandler.viewFile((blob, close) => {
            return <CsvViewer blob={blob} close={close} />;
        });
    }

    downloadFile() {
        this.props.fileEntryHandler.requestFileContents((blob) => FileSaver.saveAs(blob, this.props.fileName));
    }

    renderEntry() {
        return (
            <React.Fragment>
                <div className="file-entry-margin text-truncate col-5 col-sm-5">
                    <span>
                        {(this.props.editing && this.props.draggable) &&
                            <DragHandle />
                        }
                        {this.props.fileName}
                    </span>
                </div>
                <div className="file-entry-margin text-truncate col-3 col-sm-3">
                    <span>{getBytesRepresentation(this.state.fileSize)}</span>
                </div>
                <div className="col-4 col-sm-4 icon-container">
                    <div className="float-right">
                        {this.props.fileEntryHandler.iconConfig.view &&
                            <i className="fa fa-eye icon-circle pointer" onClick={this.viewFile} />
                        }
                        {(this.props.fileEntryHandler.iconConfig.download && !this.props.editing) &&
                            <i className="fa fa-download icon-circle pointer" onClick={this.downloadFile} />
                        }
                        {this.props.editing &&
                            <i className="fa fa-trash icon-circle pointer" onClick={this.props.removePlate} />
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

    render() {
        if (this.props.renderAsCols) {
            return this.renderEntry();
        } else {
            return (
                <div className="row">
                    {this.renderEntry()}
                </div>
            );
        }
    }

}

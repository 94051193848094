import "../../../resources/css/annotation/Autocomplete.css";

import PlateConfiguration, {ROW_REGEX} from "./PlateConfiguration";
import {hashCode, masterRawHashCode} from "../../../utils";

import {ALPHABETS} from "../../Alphabet";
import Autocomplete from "react-autocomplete";
import CsvViewer from "../CsvViewer";
import PlateExample from "../../../resources/images/plate-example.png";
import React from "react";
import {strings} from "../../../localization"
import swal from 'sweetalert2'

const reporterAutocomplete = [
  "Bscl2",
  "Srxn1",
  "Btg2",
  "Rtkn",
  "Blvrb",
  "Ddit3"
];

function showPlateExample() {
  swal.fire({'type': 'info', 'title': strings.toxDB_plate_example_title, 'imageUrl': PlateExample, 'imageAlt': strings.toxDB_plate_example_title, 'confirmButtonText': strings.toxDB_plate_example_validate})
}
class Plate extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      treatments: [],
      concentrations: [],
      units: []
    };

    this._wellInputs = [];
    this._boundWellConfig = null;
    this._treatmentReferences = [];
    this._buttonReference = React.createRef();
    this._treatmentGroups = null;
    this._manualInput = [];
    this._treatmentTypingDelay = null;

    this.setTreatmentHandler = this.setTreatmentHandler.bind(this);
    this.setConcentrationHandler = this.setConcentrationHandler.bind(this);
    this.setUnitHandler = this.setUnitHandler.bind(this);
  }

  click() {
    this._buttonReference.current.click();
  }

  getAlphabet() {
    return ALPHABETS[this.props.wellConfig];
  }

  getWellCount() {
    return this.getAlphabet().getWellCount();
  }

  getPlateConfig(skipValidation) {
    const plateConfig = new PlateConfiguration(this.getAlphabet());
    plateConfig.setConcentrations(this.state.concentrations);
    const treatments = [...this.state.treatments];

    if (this._treatmentTypingDelay && (((new Date()) - this._treatmentTypingDelay.datetime) < 100)) {
      treatments[this._treatmentTypingDelay.index] = "";
    }

    plateConfig.setTreatments(treatments);
    plateConfig.setUnits(this.state.units)
    if (!skipValidation) {
      try {
        plateConfig.validate();
      } catch (e) {
        throw new Error(`${strings.toxDB_create_annotation_platenr} ${this.props.plateNumber}: ${e.message}`);
      }
    }

    return plateConfig;
  }

  genericSetState(name, index, value, callback) {
    const copy = [...this.state[name]].slice(0, this.getWellCount());
    copy[index] = value;
    this.setState({
      [name]: copy
    }, callback);
    return copy;
  }

  setTreatmentHandler(index) {
    return (function(value) {
      const isEvent = Boolean(value.target);

      const treatments = this.genericSetState(
        "treatments", index, isEvent
        ? value.target.value
        : value);
      // If this is not an event focus to next element (clicked on an item)
      if (!isEvent && index + 1 < this._treatmentReferences.length) {
        this._treatmentReferences[index + 1].current.focus();
        this._treatmentTypingDelay = null;
        this.forceUpdate();
      } else if (isEvent) {
        // change event -> timeout of 50ms
        this._treatmentTypingDelay = {
          index: index,
          datetime: new Date()
        };
      }

      const allFilled = treatments.every(Boolean);
      if ((this._treatmentGroups === null || index === treatments.length - 1) && allFilled) {
        this._treatmentGroups = new Map();
        // Find the treatment groups (consecutively)
        let lastTreatment = null;
        for (let i = 0; i < treatments.length; i++) {
          const treatment = treatments[i];
          if (lastTreatment !== treatment) {
            if (treatment in this._treatmentGroups) {
              // Not a consecutive treatment group
              this._treatmentGroups = false;
              break;
            }
            this._treatmentGroups.set(treatment, [i]);
          } else {
            this._treatmentGroups.get(treatment).push(i);
          }
          lastTreatment = treatment;
        }
      } else if (this._treatmentGroups !== null) {
        this._treatmentGroups = false;
      }
    }).bind(this);
  }

  setConcentrationHandler(index) {
    return (function(event) {
      const value = event.target.value;
      this._manualInput[index] = true;
      this.genericSetState("concentrations", index, value);

    }).bind(this);
  }

  setUnitHandler(index) {
    return (function(event) {
      const value = event.target.value;
      this.genericSetState("units", index, value);

    }).bind(this);
  }



  updateWellInputs() {
    const wellCount = this.getWellCount();
    const maxCharacters = wellCount.toString().length;

    for (let i = this._wellInputs.length; i < wellCount; i++) {
      const remainingCharacters = maxCharacters - (i + 1).toString().length;
      this.state.treatments.push("");
      this.state.concentrations.push("");
      this.state.units.push("uM");

      const treatmentReference = React.createRef();
      this._treatmentReferences.push(treatmentReference);
      this._manualInput.push(false);

      this._wellInputs.push(() => {
        const items = this.props.getTreatments();
        return (<div className="row" key={`well-${i + 1}`}>
          <div className="col-12 col-sm-3 text-center">
            <span>{strings.toxDB_create_annotation_column}
              {"0".repeat(remainingCharacters)}{i + 1}</span>
          </div>
          <div className="col-12 col-sm-4">
            <Autocomplete items={items} value={this.state.treatments[i]} getItemValue={value => value} wrapperStyle={{}} onChange={this.setTreatmentHandler(i)} onSelect={this.setTreatmentHandler(i)} inputProps={{
                className: "form-control full-width",
                placeholder: strings.toxDB_create_annotation_treatment,
                name: "treatment",
                type: "text",
                tabIndex: i + 1
              }} shouldItemRender={(item, value) => {
                if (value.length === 0)
                  return true;
                return item.toLowerCase().startsWith(value.toLowerCase());
              }} renderMenu={(items, value, style) => <div className="autocomplete-menu">{items}</div>} renderItem={(item, highLighted) => <div key={item} className="pointer">{item}</div>} renderInput={(props) => <input {...props} onKeyDown={(e) => {
                  const autocomplete = treatmentReference.current;
                  if (e.keyCode === 9) {
                    const items = autocomplete.props.items.filter(item => autocomplete.props.shouldItemRender(item, e.target.value));
                    if (items.length > 0) {
                      autocomplete.props.onChange({
                        target: {
                          value: items[0]
                        }
                      });
                    }
                  } else if (e.keyCode === 13) {
                    autocomplete.props.onChange(e.target.value);
                  }
                }}/>} ref={treatmentReference}/>
          </div>
          <div className="col-12 col-sm-3">
            <input type="number" step="any" min="0" name="conc" className="form-control full-width" placeholder={strings.toxDB_create_annotation_conc} value={this.state.concentrations[i]} onChange={this.setConcentrationHandler(i)} tabIndex={i + wellCount + 1}/>
          </div>
          <div className="col-12 col-sm-2">
            <input type="text" name="concUnit" className="form-control full-width" value={this.state.units[i]} onChange={this.setUnitHandler(i)} tabIndex={i + wellCount + 1}/>
          </div>
        </div>);
      });
    }

    const popLength = this._wellInputs.length;
    for (let i = wellCount; i < popLength; i++) {
      this._wellInputs.pop();
      this._manualInput.pop();
      this._treatmentReferences.pop();
    }
  }

  componentDidUpdate() {
    if (this.props.update) {
      this.props.update();
    }
  }

  render() {
    if (this._boundWellConfig !== this.props.wellConfig) {
      this.updateWellInputs();
      this._boundWellConfig = this.props.wellConfig;
    }

    const plateHeader = `plate-${this.props.plateNumber}-header`;
    const plateBody = `plate-${this.props.plateNumber}-body`;

    return (<div className="card">
      <div className="card-header p-0" id={`plate-${this.props.plateNumber}-header`}>
        <h5>
          <button className="btn btn-link btn-link-override full-width full-height" data-toggle="collapse" data-target={`#${plateBody}`} aria-expanded="false" aria-controls={plateBody} ref={this._buttonReference}>
            {strings.toxDB_create_annotation_platenr}
            {this.props.plateNumber}

            {this.props.removeable && <i className="fa fa-times-circle pl-2 pointer float-right" onClick={this.props.removeCallback}/>}
            <i alt="Plate example" className="fa fa-question-circle pl-2 pointer float-right" aria-hidden="true" onClick={showPlateExample}></i>
          </button>
        </h5>
      </div>
      <div id={plateBody} className="collapse" aria-labelledby={plateHeader} data-parent="#annot-generation-accordion">
        <div className="card-body p-1">
          {this._wellInputs.map(func => func())}
        </div>
      </div>
    </div>);
  }

}

export default class AnnotationGenerator extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      reporters: [...reporterAutocomplete],
      plates: [],
      error: null
    };

    this._cachedGenerate = {
      hashCode: null,
      content: null
    };

    this._reporterInputs = [];
    this._plateReferences = [];
    this._boundWellConfig = null;
    this._buttonReference = React.createRef();
    this._justAddedPlate = false;

    this.update = this.update.bind(this);
    this.addPlate = this.addPlate.bind(this);
    this.removePlate = this.removePlate.bind(this);
    this.useCurrentFile = this.useCurrentFile.bind(this);
    this.previewCurrentFile = this.previewCurrentFile.bind(this);
    this.bindChangeToReporterIndex = this.bindChangeToReporterIndex.bind(this);
  }

  generate() {
    if (this.state.reporters.length === 0) {
      throw new Error("Requires at least 1 reporter!");
    } else if (this.state.plates.length === 0) {
      throw new Error("Requires at least 1 plate!");
    }

    const plates = this._plateReferences.map(ref => ref.current).filter(plate => plate).map(plate => plate.getPlateConfig());

    // Calculate the hashcode based on the input variables
    const completeHashCode = masterRawHashCode(53, hashCode(this.props.wellConfig), ...this.state.reporters.map(hashCode), ...plates.map(plate => plate.hashCode()));
    if (this._cachedGenerate.hashCode === completeHashCode) {
      return this._cachedGenerate.content;
    }

    // Generate the plate header
    let plateHeader = ",";
    let header = "Well,Reporter";

    plates.forEach((plate, idx) => {
      plateHeader += `,plate${idx + 1},plate${idx + 1},plate${idx + 1}`;
      header += ",Treatment,Conc.,Unit";
    });
    // Combine the headers
    let content = `${plateHeader}\n${header}\n`;
    // Generate the actual content
    const alphabet = ALPHABETS[this.props.wellConfig];
    const reporters = this.getReporterValues();
    for (let well of alphabet) {
      const wellRow = ROW_REGEX.exec(well)[1];
      const reporterIndex = alphabet.rowToRowIndex(wellRow);
      content += `${well},${reporters[reporterIndex]}`;

      for (let plate of plates) {
        if (reporters[reporterIndex].length !== 0) {
          content += `,${plate.buildForWell(well)}`;
        } else {
          content += ',,,';
        }
      }
      content += "\n";
    }

    this._cachedGenerate.hashCode = completeHashCode;
    this._cachedGenerate.content = content;
    return content;
  }

  getDistinctTreatments() {
    let treatmentPool = new Set();
    this._plateReferences.map(plate => plate.current).filter(plate => plate).forEach(plate => {
      const configuration = plate.getPlateConfig(true);
      configuration.getDistinctTreatments().forEach(treatment => {
        treatmentPool.add(treatment);
      });
    });
    treatmentPool = Array.from(treatmentPool);
    treatmentPool.sort();
    return treatmentPool;
  }

  addPlate() {
    const copyPlates = [...this.state.plates];
    const index = copyPlates.length + 1;

    const reference = React.createRef();
    this._plateReferences.push(reference);
    this._justAddedPlate = true;
    copyPlates.push(() => {
      return <Plate plateNumber={index} wellConfig={this.props.wellConfig} key={`plate-${index}`} ref={reference} removeCallback={this.removePlate} removeable={this.state.plates.length === index} update={this.update} getTreatments={() => this.getDistinctTreatments()}/>
    });
    this.setState({plates: copyPlates, error: null});
  }

  removePlate() {
    // When this is called, the last plate is removed
    const copyPlates = [...this.state.plates];
    copyPlates.pop();
    this._plateReferences.pop();
    this.setState({
      plates: copyPlates,
      error: null
    }, () => {
      if (this._plateReferences.length === 0) {
        this._buttonReference.current.click();
      } else {
        this._plateReferences[this._plateReferences.length - 1].current.click();
      }
    });
  }

  getReporterValues() {
    const limit = ALPHABETS[this.props.wellConfig].getTotalRows();
    return this.state.reporters.slice(0, limit);
  }

  getReporterValuesFiltered() {
    return this.getReporterValues().filter(el => el)
  }

  bindChangeToReporterIndex(reporterIndex) {
    return (function(event) {
      const reportersCopy = [...this.state.reporters];
      reportersCopy[reporterIndex] = event.target.value;
      this.setState({reporters: reportersCopy});
    }).bind(this);
  }
  convertKey(index) {
    const A = 'A'.charCodeAt(0);
    return String.fromCharCode(A + + index)
  }

  updateReporterInputs() {
    const rows = [...ALPHABETS[this.props.wellConfig].iterAlphabet()];

    for (let i = this._reporterInputs.length; i < rows.length; i++) {
      const actualCharacter = rows[i];
      const reporterIdentifier = `reporter-input-${actualCharacter}`;
      if (this.state.reporters.length <= i)
        this.state.reporters.push("");
      this._reporterInputs.push(() => <div className="form-group row" key={reporterIdentifier}>
        <label htmlFor={reporterIdentifier} className="col-form-label col-sm-3">{strings.toxDB_create_annotation_reporter}
          {actualCharacter}</label>
        <input className="col-sm-8 form-control" type="text" id={reporterIdentifier} value={this.state.reporters[i]} onChange={this.bindChangeToReporterIndex(i)}/>
      </div>);
    }

    if (this._reporterInputs.length > rows.length) {
      const amount = this._reporterInputs.length - rows.length;
      for (let i = 0; i < amount; i++)
        this._reporterInputs.pop();
      }
    }

  getBlob() {
    return new Blob([this.generate()]);
  }

  previewCurrentFile() {
    this.props.hijackComponent(<CsvViewer blob={this.getBlob()} close={() => this.props.hijackComponent(null)}/>);
  }

  useCurrentFile() {
    // const file = new File([this.getBlob()], "annot.csv");
    this.props.setAnnotationFile(this.getBlob());
  }

  update() {
    try {
      this.generate();
      if (this.state.error !== null)
        this.setState({error: null});
      }
    catch (e) {
      if (e.message !== this.state.error)
        this.setState({error: e.message});
      }

    if (this._justAddedPlate) {
      setTimeout(() => {
        this._plateReferences[this._plateReferences.length - 1].current.click();
        this._justAddedPlate = false;
      }, 50);
    }
  }

  componentDidUpdate() {
    this.update();
  }

  render() {
    const disableButton = this._boundWellConfig === null || Boolean(this.state.error);
    if (this._boundWellConfig !== this.props.wellConfig) {
      this.updateReporterInputs();
      this._boundWellConfig = this.props.wellConfig;
    }

    return (<React.Fragment>
      <div id="annot-generation-accordion">
        <div className="card">
          <div className="card-header p-0" id="reporters-header">
            <h5>
              <button className="btn btn-link btn-link-override full-width full-height" data-toggle="collapse" data-target="#reporters-body" aria-expanded="true" aria-controls="reporters-body" ref={this._buttonReference}>
                {strings.toxDB_create_annotation_reporters}
              </button>
            </h5>
          </div>
          <div id="reporters-body" className="collapse show" aria-labelledby="reporters-header" data-parent="#annot-generation-accordion">
            <div className="card-body py-0 pt-1">
              {this._reporterInputs.map(func => func())}
              <div className="form-group row" key="afCorrectionRow">
              <label className="col-form-label col-sm-3" htmlFor="af-correction-row">{strings.toxDB_create_annotation_autoFluor}</label>
              <select className="col-sm-8 form-control" aria-describedby="afCorrectionHelp" name="af-correction-row" id="af-correction-row" onChange={this.props.setAfCorrectionRow} value={this.state.afCorrectionRow}>
                <option value="none">{strings.toxDB_create_annotation_autoFluorChooser}</option>
                {Object.entries(this.getReporterValuesFiltered()).map(row => <option key={this.convertKey(row[0])} value={this.convertKey(row[0])}>{row[1]}</option>)}
              </select>
              <small id="afCorrectionHelp" className="offset-sm-3 form-text text-muted">{strings.toxDB_create_annotation_autoFluorInfo}</small>

              </div>
            </div>
          </div>

        </div>
        {this.state.plates.map(func => func())}
      </div>
      {
        this.state.error && <div className="alert alert-warning mt-2">
            <span>{this.state.error}</span>
          </div>
      }
      <div className="col-12 mt-2">
        <div className="row">

          <div className="offset-0 offset-sm-2 offset-lg-8 offset-xl-8 col-12 col-sm-3 col-lg-2 col-xl-2 p-0 pr-sm-1 mb-1 mb-sm-0">
            <button className="btn btn-secondary full-width" onClick={this.previewCurrentFile} disabled={disableButton}>{strings.toxDB_create_annotation_generatePreviewButton}</button>
          </div>
          <div className="col-12 col-sm-3 col-lg-2 col-xl-2 p-0 pr-sm-1 mb-1 mb-sm-0">
            <button className="btn btn-primary full-width" onClick={this.addPlate}>{strings.toxDB_create_annotation_generatePreviewAddplate}</button>
          </div>
        </div>
        <div className="row">
          <div className="offset-0 offset-sm-2 offset-lg-8 offset-xl-8 col-12 col-sm-6 col-lg-4 p-0 pr-sm-1 mt-2 mb-1 mb-sm-0">
            <button className="btn btn-success full-width" onClick={this.useCurrentFile} disabled={disableButton}>{strings.toxDB_create_annotation_generateUseButton}</button>

          </div>

        </div>
      </div>
    </React.Fragment>);
  }

}

import { firebaseFunc, getFirebaseTenantAuth } from "./firebaseConfig";
import React from "react";
import { FormWithLoadingSlider } from "../common/FormWithLoadingSlider";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  TextField,
} from "@material-ui/core";

export const ResetPasswordForm = () => {
  const firebaseAuth = getFirebaseTenantAuth();
  const [loading, isLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const [email, setEmail] = React.useState("");

  const displayError = (error: Error) => {
    setErrorMessage(error.message);
    isLoading(false);
  };

  const onSubmit = (e: HTMLFormEvent) => {
    e.preventDefault();
    isLoading(true);
    setErrorMessage("");
    setSuccessMessage("");
    firebaseAuth
      .signInAnonymously()
      .then(() =>
        firebaseFunc.httpsCallable("sendPasswordResetMail")({ email })
      )
      .then(() => {
        isLoading(false);
        setSuccessMessage(
          "You will receive an email with a link to reset your email."
        );
      }, displayError);
  };

  const children = (
    <>
      <CardHeader title={"Forgot password"}></CardHeader>
      <form onSubmit={onSubmit}>
        <CardContent>
          <TextField
            type="email"
            name="email"
            label="email"
            fullWidth
            required
            value={email}
            error={!!errorMessage}
            onChange={(e) => setEmail(e.target.value)}
          />
        </CardContent>
        <CardActions>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={"ml-auto"}
          >
            Send password reset mail
          </Button>
        </CardActions>
      </form>
    </>
  );

  return (
    <Card>
      <FormWithLoadingSlider
        loading={loading}
        children={children}
        errorMessage={errorMessage}
        successMessage={successMessage}
      />
    </Card>
  );
};

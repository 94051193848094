import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { INTERNET_ERROR_MSG, SERVER_FAILED_MSG } from "../ErrorMessages";

import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Checkbox from "@material-ui/core/Checkbox";
import Container from "@material-ui/core/Container";
import DateFnsUtils from "@date-io/date-fns";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import EditIcon from "@material-ui/icons/Edit";
import Fab from "@material-ui/core/Fab";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import React from "react";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import getApiEndpoint from "../endpoint_configuration";
import { strings } from "../localization";
import { connect } from "react-redux";
import { setSnackbar } from "../common/simpleSnackbarSlice";
import { AppDispatch } from "../store";

function mapDispatch(dispatch: AppDispatch) {
  return {
    showErrorSnackbar: (message: string) =>
      dispatch(setSnackbar({ payload: { message, mode: "error" } })),
  };
}
class GroupManagement extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      clientGroups: [],
      licences: [],
      dialogOpen: false,
      newName: "",
      newLicenceId: "",
      errorText: "",
      newId: "",
      willExpire: false,
      expirationDate: new Date(),
      editMode: false,
    };

    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleOpenEdit = this.handleOpenEdit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleNewLicence = this.handleNewLicence.bind(this);
    this.handleNameFieldChange = this.handleNameFieldChange.bind(this);
    this.createGroup = this.createGroup.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }
  handleClickOpen() {
    this.setState({ dialogOpen: true });
  }

  handleOpenEdit(editedGroup) {
    this.setState({
      ...this.state,
      dialogOpen: true,
      editMode: true,
      ...editedGroup,
    });
  }

  handleClose() {
    this.setState({
      dialogOpen: false,
      newId: "",
      newName: "",
      newLicenceId: "",
      errorText: "",
    });
  }

  handleNewLicence(e) {
    this.setState({ newLicenceId: e.target.value });
  }

  handleToggle(e) {
    this.setState({ willExpire: e.target.checked });
  }

  handleNameFieldChange(e) {
    if (e.target.value.length === 0) {
      this.setState({ errorText: "This field cannot be empty" });
    } else {
      this.setState({ errorText: "", newName: e.target.value });
    }
  }

  handleDateChange(date) {
    this.setState({ expirationDate: date });
  }

  handleDelete(id) {
    getApiEndpoint("user_client_groups_delete")
      .bindUrlParameter("id", id)
      .getFetchPromise()
      .then((response) => response.json())
      .then((body) =>
        this.setState({
          clientGroups: this.state.clientGroups.filter(
            (el) => el.id !== body.id
          ),
        })
      );
  }

  createJsonPayload() {
    let body = { name: this.state.newName };

    const licenceId = this.state.newLicenceId;
    if (licenceId !== "") {
      body["licence"] = licenceId;
    }

    if (this.state.willExpire) {
      body["expiration"] = this.state.expirationDate;
    }
    return JSON.stringify(body);
  }

  createGroup() {
    if (this.state.newName.length === 0) {
      this.setState({ errorText: "This field cannot be empty" });
    } else {
      let apiEndpoint = getApiEndpoint("user_client_groups_post");
      if (this.state.newId !== "") {
        apiEndpoint = getApiEndpoint("user_client_groups_put").bindUrlParameter(
          "id",
          this.state.newId
        );
      }

      apiEndpoint
        .setBodyData(this.createJsonPayload())
        .getFetchPromise()
        .then((response) => {
          // Shouldn't be here ... see getApiEndpoint TODO
          if (response.status === 409) {
            throw 409;
          }
          if (response.status === 404) {
            throw 404;
          }
          return response.json();
        })
        .catch((errorCode) => {
          if (errorCode === 409) {
            this.setState({ errorText: "This name is already taken" });
          }
        })
        .then((newClient) => {
          // newClient check shouldn't be here. If error 409, the flow continues with `undefined`
          if (newClient) {
            const newClientGroups = this.updateClientGroupArray(
              newClient,
              this.state.newId
            );
            newClientGroups.sort((a, b) => ("" + a.name).localeCompare(b.name));
            this.setState({ clientGroups: newClientGroups }, (_) =>
              this.handleClose()
            );
          }
        });
    }
  }

  updateClientGroupArray(newClient, newId = null) {
    if (newId) {
      let clientGroupsNewRef = this.state.clientGroups.slice();

      newClient["expiration"] = this.state.willExpire
        ? this.state.expirationDate
        : null;

      for (let i = 0; i < clientGroupsNewRef.length; i++) {
        const id = clientGroupsNewRef[i].id;
        if (id === newId) {
          clientGroupsNewRef[i] = newClient;
        }
      }
      return clientGroupsNewRef;
    }

    return [...this.state.clientGroups, newClient];
  }

  componentDidMount() {
    getApiEndpoint("user_client_groups_get_list")
      .getFetchPromise()
      .catch(() => this.props.showErrorSnackbar(INTERNET_ERROR_MSG))
      .then((response) => {
        if (!response.ok) return new Promise((resolve, reject) => reject());
        return response.json();
      })
      .then((data) => {
        let clientGroups = data.client_groups;
        clientGroups.sort((a, b) => ("" + a.name).localeCompare(b.name));
        this.setState({ clientGroups: clientGroups });
      })
      .catch(() => this.props.showErrorSnackbar(SERVER_FAILED_MSG));

    getApiEndpoint("user_licence_list")
      .getFetchPromise()
      .catch(() => this.props.showErrorSnackbar(INTERNET_ERROR_MSG))
      .then((response) => {
        if (!response.ok) return new Promise((resolve, reject) => reject());
        return response.json();
      })
      .then((data) => this.setState({ licences: data.licences }))
      .catch(() => this.props.showErrorSnackbar(SERVER_FAILED_MSG));
  }

  render() {
    return (
      <React.Fragment>
        <Container>
          <Card>
            <CardHeader title={strings.group_management_title}></CardHeader>
            <CardContent>
              <List>
                {this.state.clientGroups.map((clientGroup) => (
                  <GroupClientItem
                    key={clientGroup.id}
                    deleteHandler={this.handleDelete}
                    dialogOpen={this.handleOpenEdit}
                    item={clientGroup}
                  />
                ))}
              </List>
            </CardContent>
          </Card>
        </Container>
        <div className="fab-container">
          <Fab color="primary" aria-label="add" onClick={this.handleClickOpen}>
            <AddIcon />
          </Fab>
        </div>
        <Dialog
          open={this.state.dialogOpen}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            {this.state.newId
              ? strings.group_management_edit_client_title
              : strings.group_management_add_client_title}
          </DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              autoFocus
              required
              error={this.state.errorText.length === 0 ? false : true}
              id="name"
              label="Name"
              type="text"
              value={this.state.newName}
              helperText={this.state.errorText}
              onChange={this.handleNameFieldChange}
            />
            {this.state.licences && this.state.licences.length >= 1 && (
              <FormControl fullWidth>
                <InputLabel shrink id="add-client-group-select-licence-label">
                  Licence
                </InputLabel>
                <Select
                  autoWidth
                  required
                  displayEmpty
                  labelId="add-client-group-select-licence-label"
                  id="add-client-group-select-licence"
                  value={this.state.newLicenceId}
                  onChange={this.handleNewLicence}
                >
                  <MenuItem key="" value="">
                    None
                  </MenuItem>
                  {this.state.licences.map((licence) => (
                    <MenuItem key={licence.id} value={licence.id}>
                      {licence.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.willExpire}
                    onChange={this.handleToggle}
                    color="primary"
                  />
                }
                label={strings.group_management_add_expiration_toggle}
              />
              {this.state.willExpire && (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    autoOk
                    variant="static"
                    value={this.state.expirationDate}
                    onChange={this.handleDateChange}
                  />
                </MuiPickersUtilsProvider>
              )}
            </FormGroup>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.createGroup} color="primary">
              {this.state.newId
                ? strings.group_management_edit_confirm
                : strings.group_management_add_confirm}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

function GroupClientItem(props) {
  function editHandler() {
    const oldClient = props.item;
    let newGroupClient = {
      newId: oldClient.id,
      newName: oldClient.name,
      newLicenceId: oldClient.licence,
      willExpire: !!oldClient.expiration,
      expirationDate: oldClient.expiration,
    };
    props.dialogOpen(newGroupClient);
  }

  function deleteHandler() {
    props.deleteHandler(props.item.id);
  }

  function dateStringToDate(dateString) {
    if (!dateString) {
      return "";
    }
    const date = new Date(dateString);
    return date.toLocaleDateString();
  }

  return (
    <ListItem key={props.item.id}>
      <ListItemText
        primary={props.item.name}
        secondary={dateStringToDate(props.item.expiration)}
      />
      <ListItemSecondaryAction>
        <IconButton
          aria-label={strings.user_management_removePerm}
          onClick={editHandler}
        >
          <EditIcon />
        </IconButton>

        <IconButton
          aria-label={strings.user_management_removePerm}
          onClick={deleteHandler}
        >
          <DeleteIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

export default connect(null, mapDispatch)(GroupManagement);

import React from "react";
import ConfigurationError from "./ConfigurationError";

import "../resources/css/common/ToggleButton.css";

export default class ToggleButton extends React.Component {

    static defaultProps = {
        selected: 0
    };

    constructor(props) {
        super(props);
        if (!this.props.options) {
            throw new ConfigurationError("Required options property!");
        }

        this.getChangeHandler = this.getChangeHandler.bind(this);
    }

    getChangeHandler(index) {
        if (this.props.disabled) return function() {};
        const _this = this;
        return () => {
            _this.setState({selection: index}, () => {
                if (_this.props.valueChange) {

                    _this.props.valueChange((_this.props.values || _this.props.values)[index]);
                }
            })
        };
    }

    render() {
        return (
            <div data-toggle="buttons" className={"btn-group btn-group-toggle  float-xl-right" + (this.props.className || "")}   title={this.props.title}>
                {

                    this.props.options.map((value, idx) =>
                    <label key={idx} className={"btn " + (this.props.selected === idx ? "btn-success" : "btn-secondary") + " " + (!!this.props.disabled ? "disabled" :"")} onClick={this.getChangeHandler(idx)}>
                      <input type="radio" name="options" id={idx} defaultChecked ={this.props.selected === idx } />{value}
                    </label>
                    )
                }
            </div>
        );
    }

}

import React from "react";
import ThemedBackground from "./common/ThemedBackground";
import { strings } from "./localization"

export default class Support extends ThemedBackground {

    renderBody() {
        return (
            <React.Fragment>
                <div className="col-12 offset-md-2 col-md-8 background-item mt-3">
                    <h3>{strings.support_toxysDB}</h3>
                    <p>{strings.support_toxysDB_par1}</p>


                    <h6><strong>{strings.support_contact_header}</strong></h6>
                    <p>{strings.support_contact_content}<a href='mailto:toxplot@toxys.com'>toxplot@toxys.com</a></p>

                </div>


            </React.Fragment>
        );
    }

}

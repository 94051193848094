import LocalizedStrings from 'react-localization';

export function setLanguage(lang) {
    strings.setLanguage(lang);
}

export let strings = new LocalizedStrings({
    en: {
        nav_home: "Home",
        nav_projectOverview: "Project overview",
        nav_createProject: "Create project",
        nav_analysisOverview: "Analysis overview",
        nav_createAnalysis: "Create analysis",
        nav_support: "Support",
        nav_user: "Log in",
        nav_user_profile: "Profile",
        nav_user_management: "User management",
        nav_group_management: "Client management",
        nav_user_sendFB: "Send feedback",
        nav_user_sendBug: "Report a problem",
        nav_user_logout: "Log out",
        nav_user_password: "Update password",
        nav_lang: "Set language",
        nav_lang_en: "English",
        nav_lang_nl: "Dutch",
        home_1: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac purus dolor. Cras mauris sem, rutrum in arcu et, faucibus sagittis magna. Ut luctus mattis augue, at ultricies urna malesuada vel. Quisque vel lacus nunc. Aenean ut nulla mi. Aliquam a diam quis arcu rhoncus congue. Fusce non vehicula turpis. Duis a hendrerit mi, ac finibus augue. Quisque cursus lacinia mauris, vitae tempor arcu fermentum vel. Duis semper dui vitae metus tincidunt, et ultrices diam volutpat. Loc: EN",
        home_2: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac purus dolor. Cras mauris sem, rutrum in arcu et, faucibus sagittis magna. Ut luctus mattis augue, at ultricies urna malesuada vel. Quisque vel lacus nunc. Aenean ut nulla mi. Aliquam a diam quis arcu rhoncus congue. Fusce non vehicula turpis. Duis a hendrerit mi, ac finibus augue. Quisque cursus lacinia mauris, vitae tempor arcu fermentum vel. Duis semper dui vitae metus tincidunt, et ultrices diam volutpat. LOC: EN",
        home_button: "Get a quote",
        footer: "Made possible by NextNovate and Toxys",
        toxDB_deleteWarning: "Are you sure?",
        toxDB_deleteWarningSub: "Are you sure you want to delete the selected objects?",
        toxDB_projectOverview_unavailable: "No projects available",
        toxDB_projectOverview_filter: "Filters",
        toxDB_projectOverview_addFilter: "Add filter",
        toxDB_projectOverview_download: "Download annotation file and input data",
        toxDB_projectOverview_analyze: "Analyze",
        toxDB_projectOverview_Delete: "Delete",
        toxDB_projectOverview_goTo: "Go to project page",
        toxDB_projectOverview_PROAST: "Export as PROAST",
        toxDB_show: "Show",
        toxDB_showTags: "tags",
        toxDB_showCompounds: "compounds",
        toxDB_create_projectName: "Project name",
        toxDB_create_visibility: "Visibility",
        toxDB_create_wellConfig: "Well configuration",
        toxDB_create_wells96: "96 wells",
        toxDB_create_wells384: "384 wells",
        toxDB_create_s9: "S9",
        toxDB_create_s9p: "S9+",
        toxDB_create_s9m: "S9-",
        toxDB_create_tags: "Tags",
        toxDB_create_taginput: "Type a tag & press enter",
        // TODO: Add translation
        toxDB_create_custom_permission: "Custom permission",
        toxDB_custom_permission_unused: "Not using custom permission",
        toxDB_custom_permission_used: "Use custom permission",
        // END TODO
        toxDB_create_annotation: "Annotation",
        toxDB_create_annotation_upload: "Upload file",
        toxDB_create_annotation_uploadButton: "Upload the annotation file",
        toxDB_create_annotation_downloadTemplateButton: "Download template",
        toxDB_create_annotation_autoFluor: "Auto fluorescence correction",
        toxDB_create_annotation_autoFluorChooser: "Choose...",
        toxDB_create_annotation_autoFluorInfo: "This row is used as baseline to apply normalization in other rows",
        toxDB_create_annotation_generate: "Generate file",
        toxDB_create_annotation_reporters: "Reporters on rows",
        toxDB_create_annotation_reporter: "Reporter",
        toxDB_create_annotation_platenr: "Plate",
        toxDB_create_annotation_emptyPlate: "Empty plate",
        toxDB_create_annotation_emptyPlateFields: "Treatment and concentration both have to be filled in or empty",
        toxDB_create_annotation_column: "Column",
        toxDB_create_annotation_treatment: "Treatment",
        toxDB_create_annotation_conc: "Conc",
        toxDB_create_annotation_conc_unit: "uM",
        toxDB_create_annotation_generateUseButton: "Use annotation",
        toxDB_create_annotation_generatePreviewButton: "Preview",
        toxDB_create_annotation_generatePreviewAddplate: "Add plate",
        toxDB_create_annotation_plate: "Plate header configuration",
        toxDB_create_annotation_plate_auto: "Automatic mode",
        toxDB_create_annotation_plate_custom: "Custom headers",
        toxDB_create_annotation_plate_well: "Well",
        toxDB_create_annotation_plate_sampleID: "Sample ID",
        toxDB_create_annotation_plate_date: "Date",
        toxDB_create_annotation_plate_GFPMean: "GFP Mean",
        toxDB_create_annotation_plate_cellConc: "Cell concentration",
        toxDB_create_annotation_experiment_add: "Add data",
        toxDB_create_annotation_experiment: "Experiment",
        toxDB_create_annotation_experiment_upload: "Click to upload or drop your file on this button",
        toxDB_create_annotation_experiment_upload_deleteFirst: "Maximum amount of plates reached, please first delete a plate before uploading",
        toxDB_create_annotation_save: "Save",
        toxDB_create_warning: "You have unsaved changes. Are you sure you want to leave?",
        toxDB_create_cancel: "Cancel",
        toxDB_analysisOverview_unavailable: "No analyses available",
        toxDB_analysisOverview_enqueued: "Enqueued:",
        toxDB_analysisOverview_received: "Received:",
        toxDB_analysisOverview_show: "Show",
        toxDB_analysisOverview_showParameters: "parameters",
        toxDB_analysisOverview_showProjects: "projects",
        toxDB_analysisOverview_download: "Download",
        toxDB_analysisOverview_delete: "Delete",
        toxDB_analysisOverview_viewAnalysis: "View analysis",
        toxDB_analysis_create: "Create analysis",
        toxDB_analysis_notfound: "Not found",
        toxDB_analysis_enqueued: "Enqueued",
        toxDB_analysis_received: "Received",
        toxDB_analysis_deleteWarning: "This analysis will be deleted at",
        toxDB_analysis_deleteWarningAnalyzing: "When the analysis finalizes and you choose not to save this analysis, this analysis will be deleted after 20 minutes",
        toxDB_analysis_deleteWarning_setName: "Optionally you can also set a name",
        toxDB_analysis_deleteWarning_field: "Analysis name",
        toxDB_analysis_deleteWarning_save: "Save",
        toxDB_analysis_usedProjects: "Used projects",
        toxDB_analysis_usedProjects_select: "Click a project to view that project",
        toxDB_analysis_parameters: "Analysis parameters",
        toxDB_analysis_parameter: "Parameter",
        toxDB_analysis_parameters_value: "Value",
        toxDB_analysis_status: "Status:",
        toxDB_analysis_download: "Download",
        toxDB_analysis_image_dropdown: "Use dropdown menu to look at graphs",
        toxDB_analysis_image_download_help: "download graph",
        toxDB_report_download: "Download PDF report",
        toxDB_createAnalysis_projects: "Projects",
        toxDB_createAnalysis_search: "Search...",
        toxDB_createAnalysis_all: "All",
        toxDB_createAnalysis_selectCompounds: "Select compounds",
        toxDB_createAnalysis_selectCompounds_project: "Please select a project to select compounds",
        toxDB_createAnalysis_selectReporters: "Select reporters",
        toxDB_createAnalysis_selectReporters_project: "Please select a project to select reporters",
        toxDB_createAnalysis_none: "Standard",
        toxDB_createAnalysis_compOrder: "Compound order in heatmap",
        toxDB_createAnalysis_compOrder_noneSub: "‘order compound by similarity’. Compound order in heatmap may vary at different surviving fractions.",
        toxDB_createAnalysis_compOrder_DendoBasedOrdering: "Dendrogram-based compound ordering",
        toxDB_createAnalysis_compOrder_DendoBasedOrderingSub: "'Fix compound order at selected cell survival’. All heat maps will have the same compound order based on the clustering at the selected relative cell survival.",
        toxDB_createAnalysis_customOrder: "Custom order",
        toxDB_createAnalysis_reportOrder: "Reporter order",
        toxDB_createAnalysis_settings: "Analysis settings",
        toxDB_createAnalysis_settings_maxHeat: "Max heatmap",
        toxDB_createAnalysis_settings_exportRaw: "Export raw data",
        toxDB_createAnalysis_settings_markNonEstimated: "Mark non estimated GFP induction at IC level",
        toxDB_createAnalysis_settings_analysis: "Analysis",
        toxDB_createAnalysis_settings_inhibConc: "Relative cell survival:",
        toxDB_createAnalysis_settings_deleteAnalysis: "Delete analysis",
        toxDB_createAnalysis_run: "Run analyses",
        toxDB_createAnalysis_add: "Add analysis",
        toxDB_createAnalysis_creating: "Waiting until all analyses are created",
        toxDB_createAnalysis_name: "Analysis name",
        support_toxysDB: "Description software:",
        support_toxysDB_par1: "In order to allow comparison of induction levels of the ToxTracker reporter cell lines for large number of compounds we developed Toxplot, a dedicated data analysis software package. Toxplot imports raw GFP reporter data from the flow cytometer, calculates GFP induction levels and cytotoxicity, performs statistical analysis of the data and hierarchical clustering of the tested compounds, and visualises the data in a heatmap allowing convenient interpretation of obtained test results. ToxPlot software uses agglomerative hierarchical clustering to visualize the ToxTracker data. Agglomerative clustering uses the ‘bottom-up’ approach, which puts each observation in its own cluster and pairs of clusters are merged as one moves up the hierarchy. To compare the induction of the six GFP reporters for a collection of compounds, each with different biological reactivities, dose-response relationships and kinetics, Toxplot calculates for each compound the level of GFP induction for every individual reporter at a specified level of cytotoxicity (typically 10%, 25%, 50% and 75%). GFP induction levels are calculated by linear regression between two data points around the specified cytotoxicity level. In case the specified level of cytotoxicity can not be reached at the highest tested compound concentration, Toxplot displays the GFP induction level at this top concentration. In the heatmap, Toxplot clearly marks the compounds that do not induce the selected level of cytotoxicity. Because the cytotoxicity for a compound can vary between the ToxTracker cell lines, calculations of the GFP induction levels of the individual reporters by Toxplot can slightly deviate from the GFP induction and cytotoxicity figures.",
        support_contact_header: "Contact information",
        support_contact_content: "For more information or support, please send a mail to ",
        user_management_you: "(You)",
        user_management_id: "UID:",
        user_management_permissions: "General permissions",
        user_management_clientgroup: "Client",
        user_management_custom_permissions: "Custom permissions",
        user_management_save_custom_permissions: "Save",
        user_management_inherited: "(inherited permission)",
        user_management_unlocked: "unlocked",
        user_management_unlock: "Unlock account",
        user_management_accountStatus: "Account is",
        user_management_locked: "locked",
        user_management_lock: "Lock account",
        user_management_delete: "Delete account",
        user_management_removePerm: "Remove",
        user_management_add: "Add user",
        user_management_add_email: "Enter email",
        user_management_add_invite: "Invite user",
        user_management_inviteError: "User with this e-mail address already has been signed up or invited",
        user_management_add_inviting: "Inviting user...",
        user_management_add_valid: "Invite valid until",
        user_management_add_maxUser: "Maximum amount of users reached",
        user_feedback: "Send feedback",
        user_feedback_sub: "Please let us know how we can improve the application!",
        user_bug: "Report a problem",
        user_bug_sub: "Please describe the problem.",
        toxDB_plate_example_title: "An example of plate",
        toxDB_plate_example_validate: "Got it!",
        user_feedback_send: "Send",
        refresh_list: "Refreshing list",
        waiting_authentication: "Waiting for authentication",
        group_management_title: "Clients",
        group_management_add_client_title: "Add client",
        group_management_edit_client_title: "Edit client",
        group_management_add_confirm: "Add",
        group_management_edit_confirm: "Edit",
        group_management_add_cancel: "Cancel",
        group_management_add_expiration_toggle: "Will expire",

    },
    devmode: {
        nav_home: "LOC_STRING",
        nav_projectOverview: "LOC_STRING",
        nav_createProject: "LOC_STRING",
        nav_analysisOverview: "LOC_STRING",
        nav_createAnalysis: "LOC_STRING",
        nav_support: "LOC_STRING",
        nav_user: "LOC_STRING",
        nav_user_profile: "LOC_STRING",
        nav_user_management: "LOC_STRING",
        nav_user_sendFB: "LOC_STRING",
        nav_user_sendBug: "LOC_STRING",
        nav_user_password: "LOC_STRING",
        nav_user_logout: "LOC_STRING",
        nav_lang: "LOC_STRING",
        nav_lang_en: "LOC_STRING",
        nav_lang_nl: "LOC_STRING",
        home_1: "LOC_STRING",
        home_2: "LOC_STRING",
        home_button: "LOC_STRING",
        footer: "LOC_STRING",
        toxDB_deleteWarning: "LOC_STRING",
        toxDB_deleteWarningSub: "LOC_STRING",
        toxDB_projectOverview_unavailable: "LOC_STRING",
        toxDB_projectOverview_filter: "LOC_STRING",
        toxDB_projectOverview_addFilter: "LOC_STRING",
        toxDB_projectOverview_download: "LOC_STRING",
        toxDB_projectOverview_analyze: "LOC_STRING",
        toxDB_projectOverview_Delete: "LOC_STRING",
        toxDB_projectOverview_goTo: "LOC_STRING",
        toxDB_projectOverview_PROAST: "LOC_STRING",
        toxDB_show: "LOC_STRING",
        toxDB_showTags: "LOC_STRING",
        toxDB_showCompounds: "LOC_STRING",
        toxDB_create_projectName: "LOC_STRING",
        toxDB_create_visibility: "LOC_STRING",
        toxDB_create_wellConfig: "LOC_STRING",
        toxDB_create_wells96: "LOC_STRING",
        toxDB_create_wells384: "LOC_STRING",
        toxDB_create_s9: "LOC_STRING",
        toxDB_create_s9p: "LOC_STRING",
        toxDB_create_s9m: "LOC_STRING",
        toxDB_create_tags: "LOC_STRING",
        toxDB_create_taginput: "LOC_STRING",
        toxDB_create_annotation: "LOC_STRING",
        toxDB_create_annotation_upload: "LOC_STRING",
        toxDB_create_annotation_uploadButton: "LOC_STRING",
        toxDB_create_annotation_downloadTemplateButton: "LOC_STRING",
        toxDB_create_annotation_autoFluor: "LOC_STRING",
        toxDB_create_annotation_autoFluorChooser: "LOC_STRING",
        toxDB_create_annotation_autoFluorInfo: "LOC_STRING",
        toxDB_create_annotation_generate: "LOC_STRING",
        toxDB_create_annotation_reporters: "LOC_STRING",
        toxDB_create_annotation_reporter: "LOC_STRING",
        toxDB_create_annotation_platenr: "LOC_STRING",
        toxDB_create_annotation_emptyPlate: "LOC_STRING",
        toxDB_create_annotation_emptyPlateFields: "LOC_STRING",
        toxDB_create_annotation_column: "LOC_STRING",
        toxDB_create_annotation_treatment: "LOC_STRING",
        toxDB_create_annotation_conc: "LOC_STRING",
        toxDB_create_annotation_generateUseButton: "LOC_STRING",
        toxDB_create_annotation_generatePreviewButton: "LOC_STRING",
        toxDB_create_annotation_generatePreviewAddplate: "LOC_STRING",
        toxDB_create_annotation_plate: "LOC_STRING",
        toxDB_create_annotation_plate_auto: "LOC_STRING",
        toxDB_create_annotation_plate_custom: "LOC_STRING",
        toxDB_create_annotation_plate_well: "LOC_STRING",
        toxDB_create_annotation_plate_sampleID: "LOC_STRING",
        toxDB_create_annotation_plate_date: "LOC_STRING",
        toxDB_create_annotation_plate_GFPMean: "LOC_STRING",
        toxDB_create_annotation_plate_cellConc: "LOC_STRING",
        toxDB_create_annotation_experiment_add: "LOC_STRING",
        toxDB_create_annotation_experiment: "LOC_STRING",
        toxDB_create_annotation_experiment_upload: "LOC_STRING",
        toxDB_create_annotation_experiment_upload_deleteFirst: "LOC_STRING",
        toxDB_create_annotation_save: "LOC_STRING",
        toxDB_create_warning: "LOC_STRING",
        toxDB_create_cancel: "LOC_STRING",
        toxDB_analysisOverview_unavailable: "LOC_STRING",
        toxDB_analysisOverview_enqueued: "LOC_STRING",
        toxDB_analysisOverview_received: "LOC_STRING",
        toxDB_analysisOverview_show: "LOC_STRING",
        toxDB_analysisOverview_showParameters: "LOC_STRING",
        toxDB_analysisOverview_showProjects: "LOC_STRING",
        toxDB_analysisOverview_download: "LOC_STRING",
        toxDB_analysisOverview_delete: "LOC_STRING",
        toxDB_analysisOverview_viewAnalysis: "LOC_STRING",
        toxDB_analysis_notfound: "LOC_STRING",
        toxDB_analysis_enqueued: "LOC_STRING",
        toxDB_analysis_received: "LOC_STRING",
        toxDB_analysis_deleteWarning: "LOC_STRING",
        toxDB_analysis_deleteWarningAnalyzing: "LOC_STRING",
        toxDB_analysis_deleteWarning_setName: "LOC_STRING",
        toxDB_analysis_deleteWarning_field: "LOC_STRING",
        toxDB_analysis_deleteWarning_save: "LOC_STRING",
        toxDB_analysis_usedProjects: "LOC_STRING",
        toxDB_analysis_usedProjects_select: "LOC_STRING",
        toxDB_analysis_image_dropdown: "LOC_STRING",
        toxDB_analysis_image_download_help: "LOC_STRING",
        toxDB_analysis_parameters: "LOC_STRING",
        toxDB_analysis_parameter: "LOC_STRING",
        toxDB_analysis_parameters_value: "LOC_STRING",
        toxDB_analysis_parameters_exportRaw: "LOC_STRING",
        toxDB_analysis_parameters_inhibConc: "LOC_STRING",
        toxDB_analysis_parameters_markNonEstimatedGFP: "LOC_STRING",
        toxDB_analysis_parameters_maxHeatmap: "LOC_STRING",
        toxDB_analysis_parameters_selectedCompounds: "LOC_STRING",
        toxDB_analysis_parameters_selectedReporters: "LOC_STRING",
        toxDB_analysis_status: "LOC_STRING",
        toxDB_report_download: "LOC_STRING",
        toxDB_analysis_download: "LOC_STRING",
        toxDB_createAnalysis_projects: "LOC_STRING",
        toxDB_createAnalysis_search: "LOC_STRING",
        toxDB_createAnalysis_all: "LOC_STRING",
        toxDB_createAnalysis_selectCompounds: "LOC_STRING",
        toxDB_createAnalysis_selectCompounds_project: "LOC_STRING",
        toxDB_createAnalysis_selectReporters: "LOC_STRING",
        toxDB_createAnalysis_selectReporters_project: "LOC_STRING",
        toxDB_createAnalysis_none: "LOC_STRING",
        toxDB_createAnalysis_compOrder: "LOC_STRING",
        toxDB_createAnalysis_compOrder_noneSub: "LOC_STRING",
        toxDB_createAnalysis_compOrder_DendoBasedOrdering: "LOC_STRING",
        toxDB_createAnalysis_compOrder_DendoBasedOrderingSub: "LOC_STRING",
        toxDB_createAnalysis_customOrder: "LOC_STRING",
        toxDB_createAnalysis_reportOrder: "LOC_STRING",
        toxDB_createAnalysis_settings: "LOC_STRING",
        toxDB_createAnalysis_settings_maxHeat: "LOC_STRING",
        toxDB_createAnalysis_settings_exportRaw: "LOC_STRING",
        toxDB_createAnalysis_settings_markNonEstimated: "LOC_STRING",
        toxDB_createAnalysis_settings_analysis: "LOC_STRING",
        toxDB_createAnalysis_settings_inhibConc: "LOC_STRING",
        toxDB_createAnalysis_settings_deleteAnalysis: "LOC_STRING",
        toxDB_createAnalysis_run: "LOC_STRING",
        toxDB_createAnalysis_add: "LOC_STRING",
        toxDB_createAnalysis_creating: "LOC_STRING",
        toxDB_plate_example_title: "LOC_STRING",
        toxDB_plate_example_validate: "LOC_STRING",
        about_toxysDB: "LOC_STRING",
        about_toxysDB_par1: "LOC_STRING",
        about_toxysDB_par2: "LOC_STRING",
        about_crossColab: "LOC_STRING",
        about_crossLab_par1: "LOC_STRING",
        about: "LOC_STRING",
        about_toxys: "LOC_STRING",
        about_nextnovate: "LOC_STRING",
        user_management_you: "LOC_STRING",
        user_management_id: "LOC_STRING",
        user_management_permissions: "LOC_STRING",
        user_management_inherited: "LOC_STRING",
        user_management_unlocked: "LOC_STRING",
        user_management_unlock: "LOC_STRING",
        user_management_accountStatus: "LOC_STRING",
        user_management_locked: "LOC_STRING",
        user_management_lock: "LOC_STRING",
        user_management_delete: "LOC_STRING",
        user_management_removePerm: "LOC_STRING",
        user_management_add: "LOC_STRING",
        user_management_add_email: "LOC_STRING",
        user_management_add_invite: "LOC_STRING",
        user_management_inviteError: "LOC_STRING",
        user_management_add_inviting: "LOC_STRING",
        user_management_add_valid: "LOC_STRING",
        user_management_add_maxUser: "LOC_STRING",
        user_feedback: "LOC_STRING",
        user_feedback_sub: "LOC_STRING",
        user_bug: "LOC_STRING",
        user_bug_sub: "LOC_STRING",
        user_feedback_send: "LOC_STRING",
        refresh_list: "LOC_STRING",
        waiting_authentication: "LOC_STRING"
    }
})

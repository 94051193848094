import {
  TextField,
  Button,
  CardHeader,
  CardContent,
  CardActions,
  Card,
  Link,
} from "@material-ui/core";
import React from "react";
import { firebaseFunc, getFirebaseTenantAuth } from "./firebaseConfig";
import { FormWithLoadingSlider } from "../common/FormWithLoadingSlider";

const LoginForm: React.FC<{}> = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [errorAlert, setErrorAlert] = React.useState("");
  const [loading, isLoading] = React.useState(false);
  const firebaseAuth = getFirebaseTenantAuth();

  const getUser = () => {

    if (firebaseAuth.currentUser) {
      return Promise.resolve();
    } else {
      return firebaseAuth.signInAnonymously();
    }
  };
  const displayError = (error: Error) => {
    setErrorMessage(error.message);
    isLoading(false);
  };

  const getLoginProperties = () => {
    return firebaseFunc
      .httpsCallable("loginProperties")({ username: email })
      .then((response) => {
        const data = response["data"];
        if (!data.registered) {
          throw Error("Cannot login, please check your email and password");
        }
        return data;
      });
  };

  const onSubmit = (e: HTMLFormEvent) => {
    e.preventDefault();
    isLoading(true);
    setErrorMessage("");

    getUser()
      .then(() => getLoginProperties())
      .then((loginProps: { migrate: boolean; registered: boolean }) => {
        if (loginProps.migrate) {
          firebaseFunc
            .httpsCallable("migrateToken")({
              username: email,
              password: password,
            })
            .then((response) =>
              firebaseAuth.signInWithCustomToken(response.data.token)
            );
        } else if (loginProps.registered) {
          firebaseAuth
            .signInWithEmailAndPassword(email, password.toString())
            .catch((error) => {
              setErrorAlert(error.message);
              isLoading(false);
            });
        }
      }, displayError);
  };

  const children = (
    <>
      <CardHeader title={"Login"}></CardHeader>
      <form onSubmit={onSubmit}>
        <CardContent>
          <TextField
            type="email"
            name="email"
            label="email"
            fullWidth
            required
            value={email}
            error={!!errorMessage}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="password"
            type="password"
            name="password"
            fullWidth
            required
            value={password}
            helperText={errorMessage}
            error={!!errorMessage}
            onChange={(e) => setPassword(e.target.value)}
          />
        </CardContent>
        <CardActions>
          <Link href="/reset-password">Forgot password?</Link>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={"ml-auto"}
          >
            Login
          </Button>
        </CardActions>
      </form>
    </>
  );

  return (
    <Card>
      <FormWithLoadingSlider
        loading={loading}
        children={children}
        errorMessage={errorAlert}
      />
    </Card>
  );
};

export default LoginForm;

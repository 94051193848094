export const ADMIN = "admin";
export const TOXPLOT_ADMIN = "toxplot-admin";
export const TOXPLOT_CREATE_ANALYSIS = "toxplot-create-analysis";
export const TOXPLOT_VIEW_ANALYSIS = "toxplot-view-analysis";
export const TOXPLOT_CREATE_PROJECT = "toxplot-create-project";
export const TOXPLOT_VIEW_PROJECT = "toxplot-view-project";
export const TOXPLOT_ESSENTIAL_USER = "toxplot-essential-user";
export const USER_ADMIN = "user-admin";
export const USER_EDIT = "edit-users";
export const USER_INVITE = "invite-users";
export const USER_LIST = "list-users";
export const USER_DELETE = "delete-users";
export const GROUP_ADMIN = "group-admin";
export const GROUP_EDIT = "edit-group";
export const GROUP_INVITE = "invite-group";
export const GROUP_LIST = "list-group";
export const GROUP_DELETE = "delete-group";



export const PERMISSION_HIERARCHY = {
  [ADMIN]: [TOXPLOT_ADMIN, GROUP_ADMIN],
  // Toxplot hierarchy
  [TOXPLOT_ADMIN]: [TOXPLOT_CREATE_ANALYSIS, TOXPLOT_CREATE_PROJECT],
  [TOXPLOT_CREATE_ANALYSIS]: [
    TOXPLOT_VIEW_ANALYSIS
  ],
  [TOXPLOT_VIEW_ANALYSIS]: [TOXPLOT_VIEW_PROJECT],
  [TOXPLOT_CREATE_PROJECT]: [TOXPLOT_VIEW_PROJECT],
  [TOXPLOT_VIEW_PROJECT]: [],
  // User hierarchy
  [USER_ADMIN]: [USER_EDIT],
  [USER_EDIT]: [USER_LIST, USER_INVITE, USER_DELETE],
  [USER_DELETE]: [USER_LIST],
  [USER_INVITE]: [],
  [USER_LIST]: [],
  // Group heirarchy
  [GROUP_ADMIN]: [GROUP_EDIT],
  [GROUP_EDIT]: [USER_ADMIN, GROUP_LIST, GROUP_DELETE, GROUP_INVITE],
  [GROUP_DELETE]: [GROUP_LIST],
  [GROUP_INVITE]: [],
  [GROUP_LIST]: [],
  // Roles
  [TOXPLOT_ESSENTIAL_USER]: [
          TOXPLOT_VIEW_PROJECT,
          TOXPLOT_CREATE_ANALYSIS,
          TOXPLOT_VIEW_ANALYSIS,
      ],
};

export const READABLE_PERMISSION = {
  [ADMIN]: "Administrator",
  [TOXPLOT_ADMIN]: "Toxplot Administrator",
  [TOXPLOT_CREATE_ANALYSIS]: "Toxplot: Create analysis",
  [TOXPLOT_CREATE_PROJECT]: "Toxplot: Create project",
  [TOXPLOT_VIEW_ANALYSIS]: "Toxplot: View analysis",
  [TOXPLOT_VIEW_PROJECT]: "Toxplot: View project",
  [USER_ADMIN]: "User Administrator",
  [USER_INVITE]: "User management: invite users",
  [USER_LIST]: "User management: view users",
  [USER_EDIT]: "User management: edit users",
  [USER_DELETE]: "User management: delete users",
  [GROUP_ADMIN]: "Group Administrator",
  [GROUP_EDIT]: "Group management: edit groups",
  [GROUP_DELETE]: "Group management: delete groups",
  [GROUP_INVITE]: "Group management: invite groups",
  [GROUP_LIST]: "Group management: view groups",
  [TOXPLOT_ESSENTIAL_USER]: "Toxplot essential user"
};

export function sortPermissions(permissionArray, translate) {
  const sortedArray = [];
  [
    ADMIN, TOXPLOT_ADMIN, TOXPLOT_ESSENTIAL_USER, TOXPLOT_CREATE_ANALYSIS, TOXPLOT_VIEW_ANALYSIS, TOXPLOT_CREATE_PROJECT,
    TOXPLOT_VIEW_PROJECT, USER_ADMIN, USER_EDIT, USER_DELETE, USER_LIST, USER_INVITE,
    GROUP_ADMIN, GROUP_EDIT, GROUP_DELETE, GROUP_LIST, GROUP_INVITE,
  ].forEach(permission => {
    if (permissionArray.indexOf(permission) !== -1) {
      sortedArray.push(translate ? READABLE_PERMISSION[permission] : permission);
    }
  });
  return sortedArray;
}
